import { gql } from '@apollo/client';

const runDelete = gql`
  mutation ($id: uuid!) {
    delete_oe_flow_run_by_pk(id: $id) {
      id
    }
  }
`;

export default runDelete;
