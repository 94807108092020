import { useEffect, useState } from 'react';
import { Button, Modal, message } from 'antd';

import cdmReferenceCreate from 'common/graphql/mutations/cdmReferenceCreate';
import cdmReferenceEdit from 'common/graphql/mutations/cdmReferenceEdit';

import { useApolloClient } from '@apollo/client';
import AdvancedForm from '../../shared/AdvancedForm/AdvancedForm';

const ReferenceEditModal = (props) => {
  const { data, onDataChange, ...modalProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ key: null, value: null });

  const initData = () => {
    return {};
  };

  useEffect(() => {
    if (data) return setFormData(data);
    return setFormData(initData());
  }, [data]);

  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      identifier: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      type: {
        type: 'string',
      },
      timezone: {
        type: 'string',
        subtype: 'timezone',
      },
      default_active: {
        type: 'boolean',
      },
    },
    required: ['identifier'],
  };

  const handleDataChange = (e) => {
    setFormData(e.formData);
  };

  const handleFormReset = () => {
    if (data) setFormData(data);
    else setFormData(initData());
  };

  const onSubmit = (e) => {
    setLoading(true);
    if (!data) {
      client
        .mutate({
          mutation: cdmReferenceCreate,
          variables: {
            reference: {
              identifier: formData.identifier,
              description: formData.description,
              type: formData.type,
              timezone: formData.timezone,
              default_active: formData.default_active,
            },
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully created Reference.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to create Reference: ' + error,
          });
          setLoading(false);
        });
    } else {
      client
        .mutate({
          mutation: cdmReferenceEdit,
          variables: {
            identifier: data.identifier,
            reference: {
              identifier: formData.identifier,
              description: formData.description,
              type: formData.type,
              timezone: formData.timezone,
              default_active: formData.default_active,
            },
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully updated Reference.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to update Reference: ' + error,
          });
          setLoading(false);
        });
    }
  };

  const formChanged = () => {
    if (!data) {
      if (formData) return true;
    } else return data.key !== formData.key || data.value !== formData.value;
  };

  const onClose = () => {
    handleFormReset();
    if (modalProps.onClose) modalProps.onClose();
  };
  const onCancel = () => {
    handleFormReset();
    if (modalProps.onCancel) modalProps.onCancel();
  };

  const title = () => {
    if (!data) return 'Create Reference: ';
    else return 'Edit Reference';
  };

  return (
    <Modal
      {...modalProps}
      title={title()}
      width={800}
      onCancel={onCancel}
      onClose={onClose}
      destroyOnClose
      footer={[
        <Button
          key="resetButton"
          onClick={handleFormReset}
          hidden={!formChanged}
        >
          Reset
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {!data ? 'Create' : 'Update'} Reference
        </Button>,
      ]}
    >
      <AdvancedForm
        schema={schema}
        formData={formData}
        onChange={handleDataChange}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default ReferenceEditModal;
