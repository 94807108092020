import { useState } from 'react';
import { Breadcrumb, Button, Col, Row, Space, Spin } from 'antd';

import processAll from 'common/graphql/querys/processAll';

import ProcessPanel from './ProcessPanel';
import ProcessEditModal from './ProcessEditModal';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

export const ProcessesOverview = () => {
  const [showModal, setShowModal] = useState(false);
  const { data, error, loading, refetch } = useQuery(processAll);

  if (error) console.log(error);
  const processes = data?.process;

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChange = () => {
    refetch();
  };

  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: 'Executions',
    },
  ];

  return (
    <div id="execution-page">
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Row gutter={[16, 16]}>
        <Col key="actions" span={24}>
          <Space>
            <Button type="primary" onClick={() => setShowModal(true)}>
              Create
            </Button>
          </Space>
        </Col>
        <ProcessEditModal
          open={showModal}
          create={true}
          onClose={closeModal}
          onCancel={closeModal}
          onChange={handleChange}
        />

        {loading && <Spin size="large" />}

        {processes &&
          processes.map((process) => (
            <Col key={process.id} span={24}>
              <ProcessPanel process={process} onChange={handleChange} />
            </Col>
          ))}
      </Row>
    </div>
  );
};
