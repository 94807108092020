import {gql} from '@apollo/client';

export const actionExecutionCreate = gql`
mutation ExecutionCreate (
    $flow_id: uuid!, 
    $parameters: jsonb!, 
    $model_name: String!, 
    $model_id: Int!, 
    $execution_name: String!,
    $project_name: String!, 
    $schedule: jsonb!, 
    $process_id: uuid!, 
    $description: String, 
    $trigger_type: String!, 
    $deployment_id_initial: uuid!
  ) {
    execution_create(
      flow_id: $flow_id, 
      parameters: $parameters, 
      model_name: $model_name, 
      model_id: $model_id, 
      execution_name: $execution_name, 
      project_name: $project_name, 
      schedule: $schedule, 
      process_id: $process_id, 
      description: $description, 
      trigger_type: $trigger_type, 
      deployment_id_initial: $deployment_id_initial
    ) {
      deployment_id
      success
      status
      details
    }
}
`;

