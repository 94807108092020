import { gql } from '@apollo/client';

const packageInsert = gql`
  mutation insertPackage(
    $name: String!
    $type: String!
    $version: String!
    $gitlink: String!
    $gittoken: String!
  ) {
    insert_package_one(
      object: {
        name: $name
        version: $version
        type: $type
        gitlink: $gitlink
        gittoken: $gittoken
        state: "SHOULD_INSTALL"
      }
    ) {
      id
    }
  }
`;

export default packageInsert;
