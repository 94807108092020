import {memo} from 'react';
import {generatePath} from 'react-router';
import {useParams, Link} from 'react-router-dom';
import {Modal, Row, Col, Space} from 'antd';

import StatisticsRunsPanel from './StatisticsRunsPanel/StatisticsRunsPanel';

import RunLogsView from 'components/shared/Run/RunLogsView';

import {isEqual} from 'lodash';
import {useUserSession} from '../../../store/useUserSession';
import {useRunsQuery} from './hooks/useRunsQuery';
import {RunsOverviewHeader} from './components/RunsOverviewHeader';
import {RunsContainer} from './components/RunsContainer';

const onRunClick = (path, history) => (runId) => {
    const generatedPath = generatePath(path, {id: runId});
    history.replace(generatedPath);
};

const closeModal = (path, history) => () => {
    const generatedPath = generatePath(path, {});
    history.replace(generatedPath);
};

//! the props object altough contains the same end values as the previous render, it has a different reference
// eslint-disable-next-line react/display-name
export const RunsOverview = memo(
    (props) => {
        const {id} = useParams();
        const {runsDateStrings, updateConfig} = useUserSession((state) => ({
            runsDateStrings: state.config.runsDateStrings,
            updateConfig: state.updateConfig,
        }));

        const {data, loading, error} = useRunsQuery(runsDateStrings);

        const flowRuns = data?.oe_flow_run;

        if (error) return <div>Error</div>;

        const items = [
            {
                title: <Link to="/">Home</Link>,
            },
            {
                title: 'Runs',
            },
        ];

        return (
            <div id="runs-overview" style={{backgroundColor: '#F1F0EB'}}>
                <RunsOverviewHeader
                    {...{items, updateConfig, runsDateStrings, flowRuns}}
                />
                <Space
                    className="runs-overview-space-header"
                    style={{
                        marginBottom: '10px',
                        width: '100%',
                        flexDirection: 'row-reverse',
                    }}
                />
                <Row gutter={[16, 16]}>
                    <RunsContainer
                        {...{
                            flowRuns,
                            loading,
                            onRunClick,
                            path: props.match.path,
                            history: props.history,
                        }}
                    />
                    <Col xxl={12} xl={24}>
                        <StatisticsRunsPanel
                            flowRuns={flowRuns}
                            onRunClick={onRunClick(props.match.path, props.history)}
                        />
                    </Col>
                </Row>
                {id && (
                    <Modal
                        title="Logs"
                        open={true}
                        onOk={closeModal(props.match.path, props.history)}
                        onCancel={closeModal(props.match.path, props.history)}
                        width={1200}
                        destroyOnClose
                    >
                        <RunLogsView key={id} flowRunId={id}/>
                    </Modal>
                )}
            </div>
        );
    },
    (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

export default RunsOverview;
