import { Button, Input, Space, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';

// FilterDropdownProps
// https://github.com/ant-design/ant-design/blob/ecc54dda839619e921c0ace530408871f0281c2a/components/table/interface.tsx#L79

export const FilterSearchDropDown = (props) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters, visible } =
    props;
  const inputRef = useRef(null);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        inputRef.current.focus({ cursor: 'end' });
      }, 10);
    }
  }, [visible]);

  const showReset = selectedKeys[0] !== undefined;

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={inputRef}
        placeholder={'Search'}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => confirm()}
        style={{ display: 'block' }}
      />

      {showReset && (
        <Row justify="end" style={{ marginTop: '8px' }}>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Row>
      )}
    </div>
  );
};

export default FilterSearchDropDown;
