import { DatabaseOutlined } from '@ant-design/icons';

export const TaskInterfaceDatabaseClient = ({ interfaceName }) => {
  return (
    <div
      className="interfaceDatabase"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <p style={{ fontWeight: 'bold' }}>{interfaceName}</p>
      <DatabaseOutlined style={{ fontSize: '5rem' }} />
    </div>
  );
};
