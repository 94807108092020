import { Link } from 'react-router-dom';

export const NotFound = () => {
    return (
        <div>
            <h2>404 Not Found</h2>
            <Link to="/">Back to Home</Link>
        </div>
    );
};
