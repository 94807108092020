import { useState, React } from 'react';
import { Modal, Upload, Button, Row, Form, DatePicker, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { BlockBlobClient } from '@azure/storage-blob';

import { useApolloClient } from '@apollo/client';

import udmDataInsert from 'common/graphql/mutations/udmDataInsert';
import actionUDMUploadBlobData from 'common/graphql/mutations/actionUDMUploadBlobData';
import JsonEdit from '../../shared/AdvancedForm/InputComponents/JsonEdit';
import dayjs from "dayjs";

const { Dragger } = Upload;

export const UDMBlobUploadButton = (props) => {
  const client = useApolloClient();
  const { identifier, dataType, ...buttonProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();

  const openModal = (e) => {
    setIsVisible(true);
  };

  const closeModal = (e) => {
    setIsVisible(false);
    setIsLoading(false);
    setFileList([]);
  };

  const uploadBlob = (url, data) => {
    const blockBlobClient = new BlockBlobClient(url);
    return blockBlobClient.uploadData(data);
  };

  const _dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleUpload = (values) => {
    if (dataType === 'JSON') {
      setIsLoading(true);
      client
        .mutate({
          mutation: udmDataInsert,
          variables: {
            data: {
              identifier: identifier,
              timestamp: values.timestamp,
              json: JSON.stringify(values.jsonData),
            },
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully Uploaded Json Data' });
          console.log(result);
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to upload Json Data: ' + error,
          });
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (dataType === 'BLOB') {
      if (fileList.length === 1) {
        setIsLoading(true);
        client
          .mutate({
            mutation: actionUDMUploadBlobData,
            variables: {
              filename: fileList[0].name,
              identifier: identifier,
              timestamp: values.timestamp,
            },
          })
          .then((result) => {
            console.log(result);
            const blobUploadUrlSas =
              result.data?.udm_upload_blob_data?.udm_sas_data?.blob_sas_url;
            console.log(blobUploadUrlSas);
            return uploadBlob(blobUploadUrlSas, fileList[0].originFileObj);
          })
          .then((result) => {
            message.success({ content: 'Succesfully Uploaded Blob Data' });
          })
          .catch((error) => {
            message.error({
              content: 'Error while trying to upload Blob Data: ' + error,
            });
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else console.log("Shouldn't happen.");
    }
  };

  const onChange = (info) => {
    console.log('status: ' + info.file.status);
    switch (info.file.status) {
      case 'uploading':
        setFileList([info.file]);
        break;
      case 'done':
        setFileList([info.file]);
        break;
      default:
        setFileList([]);
        break;
    }
  };

  return (<>
    <Button
      type="primary"
      {...buttonProps}
      loading={isLoading}
      onClick={openModal}
    >
      {props.children}
    </Button>
    <Modal
      title={'Upload Data: ' + identifier}
      open={isVisible}
      footer={null}
      width={640}
      onCancel={closeModal}
      destroyOnClose
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={handleUpload}
        initialValues={{ timestamp: dayjs() }}
      >
        <Form.Item
          label="Timestamp"
          name="timestamp"
          rules={[{ required: true, message: 'Please select a Timestamp!' }]}
        >
          <DatePicker showTime />
        </Form.Item>

        {dataType === 'JSON' && (
          <Form.Item
            label="JSON"
            name="jsonData"
            rules={[{ required: true, message: 'Please add Json!' }]}
          >
            <JsonEdit />
          </Form.Item>
        )}

        {dataType === 'BLOB' && (
          <Dragger
            multiple={false}
            fileList={fileList}
            customRequest={_dummyRequest}
            onChange={onChange}
            onDrop={(e) => {
              console.log('Dropped files', e.dataTransfer.files);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area for upload.
            </p>
          </Dragger>
        )}

        <Row justify="end" style={{ marginTop: '10px' }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={dataType === 'BLOB' && fileList.length === 0}
            loading={isLoading}
          >
            Upload
          </Button>
        </Row>
      </Form>
    </Modal>
  </>);
};

export default UDMBlobUploadButton;
