import { gql } from '@apollo/client';

const permissionCreate = gql`
  mutation (
    $name: String!
    $description: String
    $permission_type: String!
    $process_ids: [auth_m2m_permission__process_insert_input!]! = []
    $project_ids: [auth_m2m_permission__project_insert_input!]! = []
  ) {
    insert_auth_permission_one(
      object: {
        name: $name
        description: $description
        permission_type: $permission_type
        processes: { data: $process_ids }
        projects: { data: $project_ids }
      }
    ) {
      id
    }
  }
`;

export default permissionCreate;
