import { gql } from '@apollo/client';

const cdmReferenceCreate = gql`
  mutation cdmReferenceCreate($reference: cdm_reference_insert_input!) {
    insert_cdm_reference_one(object: $reference) {
      identifier
    }
  }
`;

export default cdmReferenceCreate;
