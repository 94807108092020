import { memo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Menu, Spin, Tooltip } from 'antd';
import { useQuery as useReactQuery } from '@tanstack/react-query';

import { useQuery } from '@apollo/client';

import logo from 'assets/FORRS_Gryt_EXE_QT_RGB.svg';
import sideNavigationQueries from 'common/graphql/querys/sideNavigationQueries';
import {setMenuItemSpinner, setNavigationItems} from './utils';
import {
  useBaseUrl,
  useSideNavigationDocumentationItems,
} from '@forrs-ems/doc-tool-components';
import { useAuthStore } from '../../../store/useAuthStore';

const { Sider } = Layout;

// TODO check and remove if not neccessary also the store
const onItemClick = (setSelectedKey) => (key) => () => setSelectedKey(key);
const onOpenChange = (setOpenKeys) => (e) => setOpenKeys(e);

// TODO fix this eslint
export const SideNavigation = memo((props) => {
  const [collapsed, setCollapsed] = useState(false);
  const userId = useAuthStore((state) => state.getUserId(state));

  const { data, error, loading } = useQuery(sideNavigationQueries, {
    variables: { user_id: userId },
  });
  const history = useHistory();
  const baseUrl = useBaseUrl();

  const documentationItems = useSideNavigationDocumentationItems({
    useQuery: useReactQuery,
    SubMenu: Menu.SubMenu,
    Link: Link,
    baseUrl: baseUrl,
    hasDocumentationLevel: true,
    history: history,
    Tooltip: Tooltip,
    collapsed
  });

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      width={275}
    >
      <Link
        to="/"
        style={{
          background: '#fff',
          display: 'flex',
          height: '64px',
          padding: '0 24px',
          width: '260px',
        }}
      >
        <img
          alt="Logo"
          src={logo}
          style={{
            alignSelf: 'center',
            height: '40px',
            width: 'auto',
          }}
        />
      </Link>
      <Menu
        className="main-menu__wrapper"
        theme="dark"
        mode="inline"
        items={[
          ...setNavigationItems(
            data?.dashboard,
            data?.project,
            data?.auth_permissions,
          ),
          ...(loading ? [setMenuItemSpinner()] : documentationItems),
        ]}
      />
    </Sider>
  );
});

SideNavigation.displayName = 'SideNavigation';

export default SideNavigation;

