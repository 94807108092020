import { Breadcrumb, Space } from 'antd';
import { Link } from 'react-router-dom';
import TriggerDashboardPipelineButton from './TriggerDashboardPipelineButton';

export const GeneralAdminPage = () => {
  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: 'Administration',
    },
  ];
  return (
    <div id="general-admin-page" style={{ backgroundColor: '#F1F0EB' }}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <p>You have no access to administration!</p>
      {/* <Space style={{marginBottom: "10px", width: "100%"}}> */}
      {/*    <TriggerDashboardPipelineButton pipelineRef={window._env_.REACT_APP_ENVIRONMENT}/> */}
      {/* </Space> */}

      {/* <p style={{marginTop: "200px"}}>Info Data: {window.location.host}</p> */}
      {/* <p>API_URL: {window._env_.REACT_APP_API_HOST}</p> */}
    </div>
  );
};

export default GeneralAdminPage;
