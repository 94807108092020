import { Breadcrumb, Typography } from 'antd';
import { Link } from 'react-router-dom';
import PermissionTable from 'components/pages/UserManagementPage/permissionTable';
import UserGroupsTable from 'components/pages/UserManagementPage/userGroupsTable';

const { Title } = Typography;

export const UserManagementPage = (props) => {
  // const {loadingGroupAssignments, errorGroupAssignments, dataGroupAssignments, refetchGroupAssignments} = useQuery(userGroupAssignments);
  // const {loadingUserGroupsAll, errorUserGroupsAll, dataUserGroupsAll, refetchUserGroupsAll} = useQuery(userGroupsAll);
  // const {loadingPermissionAll, errorPermissionAll, dataPermissionAll, refetchPermissionAll} = useQuery(permissionAll);

  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: 'User management',
    },
  ];

  return (
    <div id="user-admin-page">
      <Breadcrumb style={{ marginBottom: '20px' }} items={ items } />
      <Title level={4}>Permissions</Title>
      <PermissionTable />
      <Title level={4}>User Groups</Title>
      <UserGroupsTable />
    </div>
  );
};

export default UserManagementPage;
