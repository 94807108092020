import { DataInterfaceUsageLeft } from './DataInterfaceUsageLeft';
import { DataInterfaceUsageRight } from './DataInterfaceUsageRight';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { taskRunInterface } from '../../../../common/graphql/subscriptions/taskRunInterface';
import { Card, Col, Row, Spin } from 'antd';
import { getCurrentAndPastDates, transformData } from '../utils/utils';

export const DataInterfaceUsage = ({ interfaceName }) => {
  const [dates, setDates] = useState({ from: '', to: '' });

  useEffect(() => {
    const { from, to } = getCurrentAndPastDates(4);
    setDates({ from, to });
  }, []);

  const { data, error, loading } = useQuery(taskRunInterface, {
    variables: {
      _gte: dates.from,
      _lte: dates.to,
      _includes_interface_name: `%${interfaceName}%`,
    },
    skip: !dates?.from || !dates?.to,
  });

  if (error) return <h2>Error: {error?.message}</h2>;

  if (loading)
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Card style={{ height: '100%' }}>
          <Row style={{ justifyContent: 'center' }}>
            <Spin />
          </Row>
        </Card>
      </Col>
    );

  const { tableData, timeTakenPlotData } = (data && transformData(data)) || [];

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <Card style={{ height: '100%' }}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12} style={{paddingRight: '8px'}}>
            <DataInterfaceUsageLeft dataSource={tableData} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12} style={{paddingLeft: '8px'}}>
            <DataInterfaceUsageRight
              timeTakenPlotData={timeTakenPlotData}
              setDates={setDates}
              dates={dates}
              data={data}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
