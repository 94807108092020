import { Button } from 'antd';
import { MinusSquareOutlined } from '@ant-design/icons';

const ButtonRemove = (props) => {
  return (
    <Button {...props} icon={<MinusSquareOutlined />} type="link"></Button>
  );
};

export default ButtonRemove;
