import {useMemo, useState} from "react";
import {Select} from "antd";
import {ResponsivePie} from "@nivo/pie";

const stateTemplates = {
    Failed: {
        id: 'Failed',
        label: 'Failed',
        value: 0,
        color: 'hsl(346,78%,58%)',
    },
    Crashed: {
        id: 'Crashed',
        label: 'Crashed',
        value: 0,
        color: 'hsl(315,78%,58%)',
    },
    Completed: {
        id: 'Completed',
        label: 'Completed',
        value: 0,
        color: 'hsl(108,55%,75%)',
    },
    Running: {
        id: 'Running',
        label: 'Running',
        value: 0,
        color: 'hsl(191,66%,67%)',
    },
    'User Terminated': {
        id: 'User Terminated',
        label: 'User Terminated',
        value: 0,
        color: 'hsl(191,66%,67%)',
    },
    Scheduled: {
        id: 'Scheduled',
        label: 'Scheduled',
        value: 0,
        color: 'hsl(180,2%,79%)',
    },
    Aborted: {
        id: 'Aborted',
        label: 'Aborted',
        value: 0,
        color: 'hsl(180,2%,79%)',
    },
    Outdated: {
        id: 'Outdated',
        label: 'Outdated',
        value: 0,
        color: 'hsl(60,86%,81%)',
    },
    Rerun: {
        // delete later
        id: 'Rerun',
        label: 'Rerun',
        value: 0,
        color: 'hsl(259,94%,79%)',
    },
    OUTDATED: {
        // delete later
        id: 'OUTDATED',
        label: 'OUTDATED',
        value: 0,
        color: 'hsl(60,86%,81%)',
    },
};

export const RunStateStatisticSlide = ({flowRuns}) => {

    const models = useMemo(() => {
        const modelNames = new Set(flowRuns.map((run) => run?.flow?.model?.name).filter((modelName) => modelName !== undefined));
        return [...modelNames].sort();
    }, [flowRuns]);

    const [filteredModel, setFilteredModel] = useState(null);

    const statistics = useMemo(() => {
        const _states = {};
        const unknownStates = new Set();

        flowRuns
            .filter(run => !filteredModel || (run?.flow?.model?.name && filteredModel === run?.flow?.model?.name))
            .forEach(({state_name}) => {
                if (!(state_name in _states)) {
                    if (!stateTemplates[state_name]) {
                        if (!unknownStates.has(state_name)) {
                            unknownStates.add(state_name);
                        }
                    } else {
                        _states[state_name] = {...stateTemplates[state_name], value: 0};
                    }
                }

                if (_states[state_name]) {
                    _states[state_name].value += 1;
                }
            });

        return Object.values(_states);
    }, [flowRuns, filteredModel]);


    const onChange = (value) => {
        if (value === '__all__') setFilteredModel(null);
        else setFilteredModel(value);
    };

    const modelOptions = [];
    modelOptions.push({value: '__all__', label: <span>All Models</span>});
    models.forEach((model) => {
        modelOptions.push({value: model, label: <span>{model}</span>});
    });


    return (
        <>
            <h1>Run States per Model</h1>
            <span style={{marginRight: 10}}>Choose Models: </span>

            <Select
                showSearch
                style={{width: 200}}
                placeholder="Search to Select"
                optionFilterProp="children"
                onChange={onChange}
                options={
                    modelOptions
                }
                filterOption={(input, option) =>
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }

                filterSort={(optionA, optionB) => {
                    optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase());
                }
                }
            />

            <div style={{height: 550}}>
                <ResponsivePie
                    data={statistics}
                    colors={{datum: 'data.color'}}
                    margin={{top: 40, right: 80, bottom: 80, left: 80}}
                    innerRadius={0.1}
                    padAngle={1}
                    motionConfig="stiff"
                    legends={[
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: 56,
                            itemsSpacing: 0,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemTextColor: '#888',
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000',
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        </>
    );
};