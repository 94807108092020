import { gql } from '@apollo/client';

export const permissionAll = gql`
  query {
    auth_permission {
      description
      name
      permission_type
      id
      processes {
        process {
          id
          name
        }
      }
      projects {
        project {
          id
          name
        }
      }
    }
  }
`;

export default permissionAll;
