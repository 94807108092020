import {useEffect, useState} from "react";
import {shortenString, toTimeZone} from "../../../../../utils/utils";
import {DateTime} from "luxon";
import {ResponsiveScatterPlot} from "@nivo/scatterplot";

export const RunTimeElapsedSlide = ({flowRuns, onRunClick}) => {
    const [runTimeElapsedStatistics, setRunTimeElapsedStatistics] = useState([]);

    useEffect(() => {
        if (!flowRuns) {
            setRunTimeElapsedStatistics(null);
        } else {
            const _stats = {};
            flowRuns.forEach(({deployment,start_time, end_time, id, state_name}) => {
                if (deployment) {
                    if (!_stats.hasOwnProperty(deployment.name)) {
                        _stats[deployment.name] = {
                            id: shortenString(deployment.name, 32),
                            data: [],
                        };
                    }
                    if (end_time && start_time) {
                        // console.log(run )
                        const elapsedTime = Math.floor(
                            (DateTime.fromISO(end_time) -
                                DateTime.fromISO(start_time)) /
                            1000,
                        );
                        if (elapsedTime >= 1)
                            _stats[deployment.name].data.push({
                                x: toTimeZone(start_time, 'UTC'),
                                y: elapsedTime,
                                modelName: deployment.name,
                                // TODO To be discussed
                                // executionName: run.execution?.name,
                                runState: state_name,
                                runID: id,
                            }); // To Time String
                    }
                }
            });
            setRunTimeElapsedStatistics(Object.values(_stats));
        }
    }, [flowRuns]);

    const renderTooltip = (node) => {
        return (
            <div style={{background: 'whitesmoke', padding: '5px'}}>
                <em>Run ID</em>: <strong>{node.node.data.runID}</strong>
                <br/>
                <em>Execution</em>:{' '}
                {/*// TODO To be discussed*/}
                {/*<strong>{node.node.data.executionName || 'Ad-hoc run'}</strong>*/}
                {/*<br/>*/}
                <em>Model</em>: <strong>{node.node.data.modelName}</strong>
                <br/>
                <em>Start</em>: <strong>{node.node.data.formattedX}</strong>
                <br/>
                <em>Duration</em>: <strong>{node.node.data.formattedY}</strong> sec
                <br/>
                <em>State</em>: <strong>{node.node.data.runState}</strong>
            </div>
        );
    };
    const onClick = (node) => {
        if (onRunClick && node.data?.runID) onRunClick(node.data.runID);
    };

    return (
        <div style={{height: 650}}>
            <h1>Elapsed Runtime per Model</h1>
            <ResponsiveScatterPlot
                data={runTimeElapsedStatistics}
                colors={{scheme: 'category10'}}
                margin={{top: 50, right: 210, bottom: 110, left: 60}}
                tooltip={renderTooltip}
                animate={false}
                onClick={onClick}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d %H:%M:%S',
                    precision: 'second',
                }}
                yScale={{
                    type: 'log',
                    min: 1,
                }}
                xFormat="time:%Y-%m-%d %H:%M:%S"
                axisLeft={{
                    orient: 'left',
                    legend: 'Elapsed time',
                    format: (y) => `${y} s`,
                    legendPosition: 'middle',
                    legendOffset: -50,
                }}
                axisBottom={{
                    orient: 'bottom',
                    format: '%H:%M',
                    tickValues: 'every 60 minutes',
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                }}
                legends={[
                    {
                        anchor: 'top-right',
                        direction: 'column',
                        justify: false,
                        translateX: 170,
                        translateY: 0,
                        itemWidth: 156,
                        itemHeight: 20,
                        itemsSpacing: 5,
                        itemDirection: 'left-to-right',
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};