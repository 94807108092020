import { createContext, useContext, useReducer } from 'react';

const initialState = {
  alerts: [],
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'DELETE_USER_ALERT':
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload.id),
      };

    case 'CREATE_USER_ALERT':
      console.log(action.payload);
      // legacy code
      // eslint-disable-next-line no-case-declarations
      const new_alerts = state.alerts.filter(
        (alert) => alert.id !== action.payload.id,
      );
      new_alerts.push(action.payload);
      return { ...state, alerts: new_alerts };

    case 'UPDATE_USER_ALERTS':
      return { ...state, alerts: action.payload };

    default:
      return { ...state };
  }
};

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserStore = () => useContext(UserContext);
