import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {BrowserRouter as Router} from 'react-router-dom';
import {UserProvider} from 'contexts/User';
import MsalAuthProvider from 'services/auth/MsalAuthProvider';
import XApolloProvider from 'services/apollo';
import XAuthProvider from 'services/auth';

import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig} from 'services/auth/authConfig';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import '@forrs-ems/doc-tool-components/dist/styles.css';
import {
    ModelParameterDefinitionsProvider
} from "./components/shared/ExecutionEditModal/provider/ModelParameterDefinitionsProvider";

const queryClient = new QueryClient();

const msalInstance = new PublicClientApplication(msalConfig);

const app = (
    <MsalProvider instance={msalInstance}>
        <MsalAuthProvider>
            <QueryClientProvider client={queryClient}>
                <XApolloProvider>
                    <XAuthProvider>
                        <Router>
                            <UserProvider>
                                <ModelParameterDefinitionsProvider>
                                    <App/>
                                </ModelParameterDefinitionsProvider>
                            </UserProvider>
                        </Router>
                    </XAuthProvider>
                </XApolloProvider>
            </QueryClientProvider>
        </MsalAuthProvider>
    </MsalProvider>
);

process.env.TZ = 'Europe/Amsterdam';

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
