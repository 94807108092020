import { gql } from '@apollo/client';

const alertDelete = gql`
  mutation ($id: Int!) {
    delete_alert_by_pk(id: $id) {
      id
    }
  }
`;

export default alertDelete;
