import { gql } from '@apollo/client';

export const cdmDataByIdentifier = gql`
  query QueryUDMLatest(
    $identifier: String!
    $ts_start: date = "0001-01-01"
    $ts_end: date = "9999-12-31"
  ) {
    cdm_calendar(
      order_by: { day: asc }
      where: {
        _and: {
          identifier: { _eq: $identifier }
          _and: { day: { _gte: $ts_start } }
          day: { _lte: $ts_end }
        }
      }
    ) {
      id
      day
      active
      created_at
      identifier
      updated_at
    }
  }
`;

export default cdmDataByIdentifier;
