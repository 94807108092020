import RunsPanel from "../../../shared/Run/RunsPanel";
import {Col} from "antd";

export const RunsPanelContainer = ({runs, loading, type, title, onRunClick, path, history}) => {
    return (
      <Col xxl={12} xl={24}>
        <RunsPanel
          type={type}
          runs={runs}
          loading={loading}
          title={title}
          onRunClick={onRunClick(path, history)}
        />
      </Col>
    );
}
