import {createContext, useRef, useState} from "react";

export const FormButtonRefContext = createContext(null);

export const FormButtonRefProvider = ({ children }) => {
    const formButtonRef = useRef(null);

    return (
        <FormButtonRefContext.Provider value={{formButtonRef}}>
            {children}
        </FormButtonRefContext.Provider>
    );
};
