import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import FilterSearchDropDown from './FilterSearchDropDown';
import './AdvancedTable.less';
import { useUserSession } from '../../../store/useUserSession';

const onChange =
  (updateFilters, type, updateSorters) =>
  (pagination, filters, sorter, extra) => {
    updateFilters({ [type]: filters });
    updateSorters({ [type]: sorter });
  };

export const AdvancedTable = ({
  columns,
  dataSource,
  loading,
  type,
  ...tableProps
}) => {
  const { filters, updateFilters, updateSorters, sorters } = useUserSession(
    ({ filters, updateFilters, updateSorters, sorters }) => ({
      filters,
      updateFilters,
      updateSorters,
      sorters
    }),
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: FilterSearchDropDown,
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? 'primary' : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '';
    },
  });

  const advancedColumns = columns.map((c) => {
    const tmpc = { ...c };

    // Sort
    if (c.sort === true && c.sorter === undefined) {
      tmpc.sorter = (a, b) => {
        if (typeof a[tmpc.dataIndex] === 'string')
          return a[tmpc.dataIndex].localeCompare(b[tmpc.dataIndex]);
        if (typeof a[tmpc.dataIndex] === 'number')
          return a[tmpc.dataIndex] - b[tmpc.dataIndex];
      };
    }

    // Sorting via User Session
    if (tmpc.key === sorters?.[type]?.columnKey) {
        tmpc.sortOrder = sorters?.[type]?.order;
    }

    // Filter
    if (c.filter === true && c.filters === undefined) {
      if (dataSource) {
        const uniqueValues = [
          ...new Set(dataSource.map((item) => item[c.dataIndex])),
        ];
        tmpc.filters = uniqueValues
          .filter((value) => {
            if (
              value &&
              (typeof value === 'string' || typeof value === 'number')
            )
              return true;
            return false;
          })
          .map((value) => {
            return { text: value, value: value };
          });
        if (c.onFilter === undefined) {
          tmpc.onFilter = (value, record) => {
            if (value && typeof value === 'string')
              return value.localeCompare(record[c.dataIndex]) === 0;
            return value === record[c.dataIndex];
          };
        }
      }
    }
    // if (c.filteredValue === undefined) tmpc.filteredValue = null // this prevents an ugly antd warning

    // programatically set filters for each column based on its key
    tmpc.filteredValue = filters?.[type]?.[c.key];

    // Search
    if (c.search === true) {
      return { ...tmpc, ...getColumnSearchProps(c.dataIndex) };
    }
    return { ...tmpc };
  });

  if (loading)
    return <Table {...tableProps} loading={loading} columns={columns} />;
  return (
    <Table
      {...tableProps}
      tableLayout={'fixed'}
      dataSource={dataSource}
      columns={advancedColumns}
      onChange={onChange(updateFilters, type, updateSorters)}
    />
  );
};

export default AdvancedTable;
