import { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {useAuthStore} from "../../../store/useAuthStore";

export const ProfileAvatar = (props) => {
  const {profilePicture, userData } = useAuthStore((state) => ({
    profilePicture: state.profilePicture,
    userData: state.userData,
  }));
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (profilePicture) {
      if (userData?.name === 'Matthias Blum') {
        setImage(
          'https://www.die-gesunde-kita.de/images/elternabend/PippiLangstrumpf.jpg',
        );
      } else {
        setImage(window.URL.createObjectURL(profilePicture));
      }
    }
  }, [profilePicture, userData]);

  if (image === null) {
    return <Avatar icon={<UserOutlined />} />;
  }
  return <Avatar src={image} />;
};

export default ProfileAvatar;
