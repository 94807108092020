import { Divider, Modal, Row, Col, Typography, Collapse } from 'antd';

import { useUserStore } from 'contexts/User';
import AlertTypeSwitch from './AlertTypeSwitch';
import {ExecutionEditForm} from './ExecutionEditForm';
import {ParameterEditForm} from '../ParameterEditForm';
import ModelInfoCollapseDescription from 'components/shared/Model/ModelInfoCollapseDescription';
import ModelInfoCollapseModelView from 'components/shared/Model/ModelInfoCollapseModelView';
import ModelInfoCollapseSecrets from 'components/shared/Model/ModelInfoCollapseSecrets';

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const alertTypes = ['onSuccess', 'onFailed'];

export const ExecutionEditModal = (props) => {
  const { execution, ...modalProps } = props;
  const [state, dispatch] = useUserStore();

  const alerts = state?.alerts;

  const handleChange = () => {
    if (props.onChange) props.onChange();
  };

  return (
    <Modal
      {...modalProps}
      title={'Execution: ' + execution.name}
      footer={null}
      width={1100}
      destroyOnClose
    >
      <Divider />
      <Row gutter={[0, 6]}>
        {execution.model.name && (
          <Col span={24}>
            <Title level={5}>Model: {execution.model.name}</Title>
            {execution.model.description && (
              <ModelInfoCollapseDescription
                description={execution.model.description}
              />
            )}
            {execution.model && (
              <ModelInfoCollapseModelView model={execution.model} />
            )}
            {execution.model.secrets && execution.model.secrets.length > 0 && (
              <ModelInfoCollapseSecrets secrets={execution.model.secrets} />
            )}
          </Col>
        )}

        {execution?.deployment?.parameter_openapi_schema?.properties &&
          Object.keys(execution?.deployment?.parameter_openapi_schema?.properties).length >
            0 && (
            <>
              <Divider />
              <Col span={24}>
                {/* <Title level={5}>Edit Model Parameters:</Title> */}
                <Collapse ghost defaultActiveKey="">
                  <Panel
                    header="Edit Model Parameters"
                    key={execution?.model?.id}
                  >
                    <ParameterEditForm
                      execution={{ ...execution }}
                      model={{ ...execution.model }}
                      onChange={handleChange}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </>
          )}

        <Divider />
        <Col span={24}>
          <ExecutionEditForm
            execution={{ ...execution }}
            onChange={handleChange}
          />
        </Col>

        <Divider />

        <Col span={24}>
          <Title level={5}>Manage Alerts</Title>
          {alertTypes.map((alertType) => {
            const alert = alerts?.find(
              (a) => a.type === alertType && a.execution_id === execution.id,
            );
            return (
              <AlertTypeSwitch
                key={alertType + execution.id}
                alert={alert}
                executionId={execution.id}
                type={alertType}
              />
            );
          })}
        </Col>
      </Row>
    </Modal>
  );
};