import { generatePath } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, List, Spin, Typography } from 'antd';
import { CodeSandboxOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

import environmentsAll from 'common/graphql/querys/environmentsAll';

const { Text } = Typography;

export const EnvironmentsList = (props) => {
  const history = useHistory();
  const { data, loading, error } = useQuery(environmentsAll);

  if (loading) return <Spin size="large" />;
  if (error) {
    console.log(error);
    return <Text>An Error occured.</Text>;
  }
  const environments = data?.environment;
  if (!environments || environments.length === 0)
    return <Text>No Environments found.</Text>;

  const reduced_env_list = environments.slice(-5);

  const handleClick = (id) => {
    const path = generatePath('/environment/:envId', { envId: id });
    history.push(path);
  };

  return (
    <List
      style={{ backgroundColor: 'white' }}
      itemLayout="horizontal"
      dataSource={reduced_env_list}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            onClick={() => {
              handleClick(item.id);
            }}
            avatar={
              <Avatar
                size={48}
                style={{ marginLeft: '10px', backgroundColor: 'primary' }}
                icon={<CodeSandboxOutlined />}
              />
            }
            title={
              <Link key={item.id} to={'/environment/' + item.id}>
                <b>{item.image}</b>
              </Link>
            }
            description={
              <div>
                {'created at ' +
                  DateTime.fromISO(item.created_at).toLocaleString(
                    DateTime.DATETIME_FULL,
                  )}
                {item.python_version
                  ? ' using python version  ' + item.python_version
                  : ''}
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default EnvironmentsList;
