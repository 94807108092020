import { Breadcrumb, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

export const ProjectPage = (props) => {
  const id = props.match?.params?.id;

  const items = [
    {
        title: <Link to="/">Home</Link>
    },
    {
        title: 'Project'
    }
  ]

  return (
    <div id="project-page" style={{ backgroundColor: '#F1F0EB' }}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Title>Project ID {id}</Title>
      <Text>Work in Progress</Text>
    </div>
  );
};

export default ProjectPage;
