import { useEffect, useState } from 'react';
import { Form } from 'antd';
import Editor from '@monaco-editor/react';

export const JsonEdit = (props) => {
  const { formData } = props;
  const [jsonData, setJsonData] = useState(JSON.stringify(formData, null, 2));
  const [jsonError, setJsonError] = useState(null);

  useEffect(() => {
    setJsonData(JSON.stringify(formData, null, 2));
  }, [formData]);

  const handleChange = (newData) => {
    try {
      setJsonData(newData);
      props.onChange(JSON.parse(newData));
      setJsonError(null);
    } catch (error) {
      setJsonError(error.message);
    }
  };

  return (
    <Form.Item
      validateStatus={jsonError ? 'error' : 'success'}
      help={jsonError}
      style={{ marginBottom: '0px' }}
    >
      <div
        style={{
          borderStyle: 'solid',
          borderRadius: '2px',
          borderWidth: '1px',
          borderColor: '#D9D9D9',
        }}
      >
        <Editor
          options={{
            automaticLayout: true,
            scrollBeyondLastColumn: 0,
            contextmenu: false,
            readOnly: props.readonly || false,
            formatOnType: true,
            overviewRulerBorder: false,
            overviewRulerLanes: 0,
          }}
          height={'100px'}
          theme="light"
          defaultLanguage="json"
          value={jsonData}
          onChange={handleChange}
        />
      </div>
    </Form.Item>
  );
};

export default JsonEdit;
