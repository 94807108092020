import { useEffect, useState } from 'react';
import { Col, Row, Select, Tag } from 'antd';

const { Option } = Select;

export const MultiSelectEdit = (props) => {
  const [value, setValue] = useState(props.formData || []);
  // console.log("MultiSelectEdit props", props)
  const placeholder = props.schema?.placeholder;
  const value_key = props.schema?.value_key || 'value';
  const label_key = props.schema?.label_key || 'label';

  useEffect(() => {
    setValue(props.formData);
  }, [props.formData]);
  const renderOptions = () => {
    return props.schema?.enum?.map((item, index) => {
      return (
        <Option key={item[value_key]} value={item[value_key]}>
          {item[label_key]}
        </Option>
      );
    });
  };

  const handleChange = (value) => {
    setValue(value);
    if (props.onChange) props.onChange(value);
  };

  function tagRender(tagprops) {
    const { label, value, closable, onClose } = tagprops;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={props.schema?.tag_color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  return (
    <Row wrap={false}>
      <Col flex={'auto'}>
        <Select
          mode={'multiple'}
          allowClear
          placeholder={placeholder}
          style={{ width: '100%' }}
          value={value}
          onChange={handleChange}
          tagRender={tagRender}
        >
          {renderOptions()}
        </Select>
      </Col>
    </Row>
  );
};

export default MultiSelectEdit;
