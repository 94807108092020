import { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';

import { useApolloClient, useQuery } from '@apollo/client';
import AdvancedForm from '../../shared/AdvancedForm/AdvancedForm';
import permissionCreate from 'common/graphql/mutations/permissionCreate';
import permissionEdit from 'common/graphql/mutations/permissionEdit';
import projectsAll from 'common/graphql/querys/projectsAll';
import processAll from 'common/graphql/querys/processAll';
import permissionTypesAll from 'common/graphql/querys/permissionTypesAll';

const PermissionEditModal = (props) => {
  const { permission, onDataChange, ...modalProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: null,
    permission_description: null,
    permission_type: 'READ',
    projects: [],
    processes: [],
  });

  const resProjectsAll = useQuery(projectsAll);
  const resProcessAll = useQuery(processAll);
  const resPermissionTypesAll = useQuery(permissionTypesAll);

  useEffect(() => {
    if (permission)
      return setFormData({
        name: permission.name,
        permission_description: permission.description,
        permission_type: permission.permission_type,
        projects: permission.projects.map((i) => i.project?.id),
        processes: permission.processes.map((p) => p.process?.id),
      });
    return setFormData({
      name: null,
      permission_description: null,
      permission_type: 'READ',
      projects: [],
      processes: [],
    });
  }, [permission]);

  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
      description: {
        type: 'string',
        label: 'Description',
      },
      permission_type: {
        type: 'string',
        enum: resPermissionTypesAll.data?.auth_permission_type?.map(
          (o) => o.permission_type,
        ),
      },
      projects: {
        type: 'array',
        subtype: 'multi_select',
        enum: resProjectsAll.data?.project,
        label_key: 'name',
        value_key: 'id',
        placeholder: 'Please select projects',
        tag_color: 'blue',
      },
      processes: {
        type: 'array',
        subtype: 'multi_select',
        enum: resProcessAll.data?.process,
        label_key: 'name',
        value_key: 'id',
        placeholder: 'Please select processes',
        tag_color: 'geekblue',
      },
    },
    required: ['name', 'permission_type'],
  };

  const handleDataChange = (e) => {
    console.log('formData:', e.formData);
    setFormData(e.formData);
  };

  const handleFormReset = () => {
    if (permission)
      setFormData({
        name: permission.name,
        permission_description: permission.description,
        permission_type: permission.permission_type,
        projects: permission.projects.map((i) => i.project.id),
        processes: permission.processes.map((p) => p.process.id),
      });
    else
      setFormData({
        name: null,
        permission_description: null,
        permission_type: 'READ',
        projects: [],
        processes: [],
      });
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (!permission) {
      // console.log({name: formData.name, description: formData.description, permission_type: formData.permission_type,
      //     process_ids: formData.processes.map(id => { return {process_id: id}}), project_ids: formData.projects.map(id => {return {project_id: id}})})
      client
        .mutate({
          mutation: permissionCreate,
          variables: {
            name: formData.name,
            description: formData.permission_description,
            permission_type: formData.permission_type,
            process_ids: formData.processes.map((id) => {
              return { process_id: id };
            }),
            project_ids: formData.projects.map((id) => {
              return { project_id: id };
            }),
          },
        })
        .then((result) => {
          message.success({ content: 'Successfully created Permission.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to create Permission: ' + error,
          });
          setLoading(false);
        });
    } else {
      const removedProjects = permission.projects
        .filter((p) => !formData.projects.includes(p.project.id))
        .map((p) => p.project.id);
      const addedProjects = formData.projects.filter(
        (project_id) =>
          !permission.projects.map((p) => p.project.id).includes(project_id),
      );
      const removedProcesses = permission.processes
        .filter((p) => !formData.processes.includes(p.process.id))
        .map((p) => p.process.id);
      const addedProcesses = formData.processes.filter(
        (process_id) =>
          !permission.processes.map((p) => p.process.id).includes(process_id),
      );

      client
        .mutate({
          mutation: permissionEdit,
          variables: {
            id: permission.id,
            name: formData.name,
            description: formData.permission_description,
            permission_type: formData.permission_type,
            process_ids_added: addedProcesses.map((id) => {
              return { permission_id: permission.id, process_id: id };
            }),
            project_ids_added: addedProjects.map((id) => {
              return { permission_id: permission.id, project_id: id };
            }),
            process_ids_removed: removedProcesses,
            project_ids_removed: removedProjects,
          },
        })
        .then((result) => {
          message.success({ content: 'Successfully updated Permission.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to update Permission: ' + error,
          });
          setLoading(false);
        });
    }
  };

  const formChanged = () => {
    if (!permission) {
      if (formData) return true;
    } else
      return (
        permission.name !== formData.name ||
        permission.description !== formData.description ||
        permission.permission_type !== formData.permission_type ||
        permission.projects !== formData.projects ||
        permission.processes !== formData.processes
      );
  };

  const onClose = () => {
    handleFormReset();
    if (modalProps.onClose) modalProps.onClose();
  };
  const onCancel = () => {
    handleFormReset();
    if (modalProps.onCancel) modalProps.onCancel();
  };

  const title = () => {
    if (!permission) return 'Create Permission';
    else return 'Edit Permission';
  };

  return (
    <Modal
      {...modalProps}
      title={title()}
      width={800}
      onCancel={onCancel}
      onClose={onClose}
      destroyOnClose
      footer={[
        <Button
          key="resetButton"
          onClick={handleFormReset}
          hidden={!formChanged}
        >
          Reset
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {!permission ? 'Create' : 'Update'} Permission
        </Button>,
      ]}
    >
      <AdvancedForm
        schema={schema}
        formData={formData}
        onChange={handleDataChange}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default PermissionEditModal;
