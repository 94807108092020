import AdvancedTable from '../../../shared/AdvancedTable/AdvancedTable';
import { Modal } from 'antd';
import { useState } from 'react';
import { RunLogsTaskRunInterfaceContainer } from '../../../shared/Run/components/RunLogsTaskRunInterfaceContainer';
const columns = [
  // {
  //   title: 'Id',
  //   dataIndex: 'id',
  //   key: 'id',
  //   align: 'center',
  //   width: '10%',
  //   ellipsis: true,
  //   sort: true,
  // },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    align: 'center',
    width: '25%',
    ellipsis: true,
    sort: true,
  },
  {
    title: 'Execution',
    dataIndex: 'execution',
    key: 'execution',
    align: 'center',
    width: '25%',
    ellipsis: true,
    sort: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '15%',
    ellipsis: true,
    sort: true,
  },
  {
    title: 'Time Taken',
    dataIndex: 'timeTaken',
    key: 'timeTaken',
    align: 'center',
    width: '15%',
    ellipsis: true,
    sort: true,
  },
  {
    title: 'Data Volume',
    dataIndex: 'dataVolume',
    key: 'dataVolume',
    align: 'center',
    width: '20%',
    ellipsis: true,
    sort: true,
  },
];

// const dataSource = [
//   {
//     timestamp: '2021-08-01 12:00:00',
//     execution: 'DE ReGas Plant Optimization',
//     name: 'calculating model',
//     timeTaken: '320ms',
//     dataVolume: '100kb',
//   },
//   {
//     timestamp: '2021-08-01 12:15:00',
//     execution: 'DE ReGas Plant Optimization',
//     name: 'calculating model',
//     timeTaken: '320ms',
//     dataVolume: '100kb',
//   },
//   {
//     timestamp: '2021-08-01 12:30:00',
//     execution: 'DE ReGas Plant Optimization',
//     name: 'calculating model',
//     timeTaken: '320ms',
//     dataVolume: '100kb',
//   },
// ];

const dataInterfaceUsage = {
  data_info:
    '{"identifiers": "PFC_POWER_DE_EOD_FORWARD_CURVE", "begin_ts": "2023-05-09 15:04:25.034444+02:00", "end_ts": "2023-05-09 15:04:25.034469+02:00", "options": ["LASTVALUEOFDATA"]}',
  request_type: 'load',
  interface_name: 'MdmClient.load_curve_df',
  __typename: 'task_run_interface',
};

const onRowClick =
  (setOpenInterfaceUsage, setInterfaceUsage) => (record, index, event) => {
    return {
      onClick: (event) => {
        // console.log('row clicked', record);
        // TODO display the record info within the Modal
        setInterfaceUsage(record);
        setOpenInterfaceUsage(true);
      },
    };
  };



// TODO sort on timestamp
export const DataInterfaceUsageTable = ({dataSource, className }) => {
  const [openInterfaceUsage, setOpenInterfaceUsage] = useState(false);
  const [interfaceUsage, setInterfaceUsage] = useState(null);

  return (<>
    <AdvancedTable
      className={className}
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      size="small"
      pagination={{ hideOnSinglePage: true }}
      // pagination={{showSizeChanger: true,  defaultPageSize: 15, pageSizeOptions: ['10', '15', '20', '30', '50']}}
      onRow={onRowClick(setOpenInterfaceUsage, setInterfaceUsage)}
      type="dataInterfaceUsageTable"
    />
    <Modal
      title="Interace Usage Log"
      centered
      width={1200}
      destroyOnClose
      open={openInterfaceUsage}
      onOk={() => setOpenInterfaceUsage(false)}
      onCancel={() => setOpenInterfaceUsage(false)}
    >
      {/*// TODO discuss content of Modal with Vincent*/}
      <RunLogsTaskRunInterfaceContainer
        taskRunInterface={[dataInterfaceUsage]}
      />
    </Modal>
  </>);
};