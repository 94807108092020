import { gql } from '@apollo/client';



export const updateFlowRunToRerun = gql`
    mutation updateFlowRunToRerun(
        $flow_run_id: uuid!,
        $state_id: uuid!,
        $state_timestamp: timestamptz,
        $expected_start_time: timestamptz,
        $updated: timestamptz
    ) {
        update_oe_flow_run(
            where: { id: { _eq: $flow_run_id } },
            _set: {
                state_type: "SCHEDULED",
                start_time: null,   
                flow_version: null,
                empirical_policy: {},
                state_id: $state_id,
                state_name: "Scheduled",
                state_timestamp: $state_timestamp,
                expected_start_time: $expected_start_time,
                next_scheduled_start_time: $expected_start_time,
                end_time: null,
                updated: $updated,
                total_run_time: "00:00:00",
            }
        ) {
            returning {
                id
            }
        }
    }
`;
