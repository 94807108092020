import {useContext, useRef, useState} from 'react';
import {generatePath} from 'react-router';
import {Link} from 'react-router-dom';
import {Breadcrumb} from 'antd';
import ModelEditModal from 'components/shared/ModelEditModal/ModelEditModal';
import {ModelsOverviewTable} from './ModelsOverviewTable';
import {ParameterDefinitionsContext} from "../../shared/ExecutionEditModal/provider/ModelParameterDefinitionsProvider";
import {FormButtonRefProvider} from "./FormButtonRefProvider";
import {FormHasValidationErrorProvider} from "./FormHasValidationErrorProvider";

export const ModelsOverview = (props) => {
    const [modalRow, setModalRow] = useState({});
    const [showConfigurationModal, setShowConfigurationModal] = useState(false);
    const formRef = useRef();
    const {setDefinitions} = useContext(ParameterDefinitionsContext) || {};

    const closeModal = () => {
        const path = generatePath(props.match.path, {});
        props.history.replace(path);
        setShowConfigurationModal(false);
        setModalRow({});
        setDefinitions({});
    };

    const items = [
        {
            title: <Link to="/">Home</Link>,
        },
        {
            title: 'Models',
        },
    ];

    return (
        <div id="models-overview">
            <Breadcrumb style={{marginBottom: '20px'}} items={items}/>

            <ModelsOverviewTable
                {...{
                    modalRow,
                    setModalRow,
                    showConfigurationModal,
                    setShowConfigurationModal,
                    history: props.history,
                    path: props.match.path,
                }}
            />
            <FormButtonRefProvider>
                <FormHasValidationErrorProvider>
                    <ModelEditModal
                        open={showConfigurationModal}
                        model={{...modalRow}}
                        onClose={closeModal}
                    />
                </FormHasValidationErrorProvider>
            </FormButtonRefProvider>
        </div>
    );
};

export default ModelsOverview;
