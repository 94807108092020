import {Row} from 'antd';
import {
    UploadOutlined,
    DownloadOutlined,
    ExclamationOutlined,
} from '@ant-design/icons';

import './TaskNodeDB.less';

const lookup = {store: <UploadOutlined/>, load: <DownloadOutlined/>};

export const TaskNodeDB = ({
                               dataManagementInfo,
                               dataManagementInfo: {clientNames, requestType} = {},
                               hasFailed,
                               hasColor
                           }) => {
    if (!dataManagementInfo) return null;

    const icon = lookup[requestType] || <ExclamationOutlined/>;

    const background = hasColor ? hasFailed ? 'rgb(225, 95, 95)' : '#1BB470' : '#4D4D4D';

    return (
        <Row
            className="task-node-db"
            style={{
                justifyContent: 'center',
                background,
                padding: '.2em',
                color: 'white',
                fontWeight: 'bold',
            }}
        >
            {icon}
            {typeof clientNames === 'string'
                ? clientNames
                : Array.from(clientNames).map((item) => (
                    <p key={item} style={{fontSize: '.8rem', marginBottom: 0}}>
                        {item}
                    </p>
                ))}
        </Row>
    );
};
