import { Breadcrumb } from 'antd';
import SwaggerUI from 'swagger-ui-react';
import { Link } from 'react-router-dom';

import useTimeout from 'utils/useTimeout';
import { useAuthStore } from '../../../store/useAuthStore';

export const SwaggerPage = () => {
  const authData = useAuthStore((state) => state.authData);

  function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      'value',
    ).set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  }

  const { reset, clear } = useTimeout(() => {
    const element = document.querySelector('[aria-label="auth-bearer-value"]');
    // console.log(element)
    if (element) {
      setNativeValue(element, authData.accessToken);

      element.dispatchEvent(new Event('change', { bubbles: true }));

      const form =
        element.parentElement?.parentElement?.parentElement?.parentElement;
      // console.log(form)
      const button = form.querySelector('.button');
      // console.log(button)
      button.click();
    } else reset();
  }, 1000);

  const items = [
      {
          title: <Link to="/">Home</Link>
      },
      {
            title: 'REST-API Swagger'
      }
  ]

  return (
    <div id={'swagger'}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <div
        style={{
          backgroundColor: '#fff',
          paddingTop: '10px',
          paddingBottom: '20px',
        }}
      >
        <SwaggerUI
          url={process.env.PUBLIC_URL + '/gryt_exeqt_swagger.json'}
          oauth2RedirectUrl={window.location.origin + '/swagger'}
        />
      </div>
    </div>
  );
};

export default SwaggerPage;
