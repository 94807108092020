import { useState } from 'react';
import { Button, Select, Row, Col } from 'antd';
import { CompassOutlined } from '@ant-design/icons';

const { Option, OptGroup } = Select;

export const TimeZoneSelector = (props) => {
  const [value, setValue] = useState(props.value || null);

  const renderOptions = () => {
    const timezones = Intl.supportedValuesOf("timeZone");
    const mappedValues = {};
    const regions = [];

    // legacy code
    // eslint-disable-next-line array-callback-return
    timezones.map((timezone) => {
      const splitTimezone = timezone.split('/');
      const region = splitTimezone[0];
      if (!mappedValues[region]) {
        mappedValues[region] = [];
        regions.push(region);
      }
      mappedValues[region].push(timezone);
    });
    return regions.map((region) => {
      const options = mappedValues[region].map((timezone) => {
        return <Option key={timezone}>{timezone}</Option>;
      });
      return (
        <OptGroup
          key={region}
          title={<div style={{ fontSize: 30 }}>{region}</div>}
        >
          {options}
        </OptGroup>
      );
    });
  };

  const handleChange = (value) => {
    setValue(value);
    if (props.onChange) props.onChange(value);
  };

  return (
    <Row wrap={false}>
      <Col flex={'40px'}>
        <Button
          style={{ marginRight: '5px' }}
          type={'primary'}
          icon={<CompassOutlined />}
          onClick={() => {
            handleChange(Intl.DateTimeFormat().resolvedOptions().timeZone);
          }}
        />
      </Col>
      <Col flex={'auto'}>
        <Select
          showSearch
          style={{ width: '100%' }}
          value={value}
          onChange={(value) => {
            handleChange(value);
          }}
        >
          {renderOptions()}
        </Select>
      </Col>
    </Row>
  );
};

export default TimeZoneSelector;
