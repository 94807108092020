import {Layout, Typography} from "antd";
import SideNavigation from "../SideNavigation/SideNavigation";
import TopBar from "../TopBar/TopBar";

const { Content, Footer } = Layout;
const { Text } = Typography;

export const RootLayout = ({ children }) => (
  <Layout className="layout__wrapper">
    <SideNavigation />
    <Layout>
      <TopBar />
      <Content>
        <div className="layout__content" style={{ minHeight: 360 }}>
          {children}
        </div>
      </Content>
      <Footer align="center">
        <Text type="secondary">© FORRS Partners GmbH</Text>
      </Footer>
    </Layout>
  </Layout>
);