import { useState } from 'react';
import { Modal, Radio, Upload, Button, Row, Col } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { BlockBlobClient } from '@azure/storage-blob';

import { useApolloClient } from '@apollo/client';

import runReRun from 'common/graphql/mutations/runReRun';
import actionUploadBlobUrl from 'common/graphql/mutations/actionUploadBlobUrl';

const { Dragger } = Upload;

export const RerunOptionsButton = (props) => {
  const client = useApolloClient();
  const { nodeData, ...buttonProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [fileOption, setFileOption] = useState('original');
  const [fileList, setFileList] = useState([]);

  const openModal = (e) => {
    setIsVisible(true);
  };

  const triggerRerun = (run, context_parameters) => {
    return client.mutate({
      mutation: runReRun,
      variables: {
        run_id: run.id,
        execution_id: run.execution_id,
        model_id: run.model_id,
        run_parameters: run.run_parameters,
        context_parameters: context_parameters,
      },
    });
  };

  const getUploadUrl = (oldBlob) => {
    // Todo: Later make this more open, so you can also start reruns where no prior blob exists
    return client.mutate({
      mutation: actionUploadBlobUrl,
      variables: { blob: oldBlob, blob_container: 'raw-data-dump' },
    });
  };

  const uploadBlob = (connectionString, data) => {
    const blockBlobClient = new BlockBlobClient(connectionString);
    return blockBlobClient.uploadData(data);
  };

  const closeModal = (e) => {
    setIsVisible(false);
    setIsLoading(false);
    setFileList([]);
    setFileOption('original');
  };

  const onChangeFileOption = (e) => {
    setFileOption(e.target.value);
  };

  const onDrop = (e) => {
    console.log('Dropped files', e.dataTransfer.files);
  };

  const _dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleRerun = (e) => {
    e.stopPropagation();

    const context_parameters = {
      start_after: nodeData.state.slug,
      data: nodeData.state.result,
    };

    //    Original File
    if (fileOption === 'original') {
      setIsLoading(true);
      triggerRerun(nodeData.run, context_parameters)
        .then((result) => {
          setIsLoading(false);
          setIsVisible(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });

      //    Manually Uploaded File
    } else if (fileList.length === 1) {
      setIsLoading(true);
      // get blob url with sas token
      let blobUploadUrl = '';
      let blobUploadUrlSas = '';
      getUploadUrl(nodeData.state.result)
        .then((result) => {
          blobUploadUrl = result.data.upload_blob_url.blob_url;
          blobUploadUrlSas = result.data.upload_blob_url.blob_url_sas;
          return uploadBlob(blobUploadUrlSas, fileList[0].originFileObj);
        })
        .then((result) => {
          context_parameters.data = blobUploadUrl;
          return triggerRerun(nodeData.run, context_parameters);
        })
        .then((result) => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      console.log("Shouldn't happen.");
    }
  };

  const onChange = (info) => {
    console.log('status: ' + info.file.status);
    switch (info.file.status) {
      case 'uploading':
        setFileList([info.file]);
        setFileOption('manual');
        break;
      case 'done':
        setFileList([info.file]);
        setFileOption('manual');
        break;
      default:
        setFileList([]);
        setFileOption('original');
        break;
    }
  };

  return (<>
    <Button {...buttonProps} loading={isLoading} onClick={openModal}></Button>
    <Modal
      title={nodeData.name}
      open={isVisible}
      footer={null}
      width={400}
      onCancel={closeModal}
      destroyOnClose
    >
      <Row justify="space-between">
        <Col>
          <Radio.Group
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={onChangeFileOption}
            value={fileOption}
          >
            <Radio.Button value="original">Original File</Radio.Button>
            <Radio.Button value="manual" disabled={fileList.length === 0}>
              Uploaded File
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Button type="primary" onClick={handleRerun} loading={isLoading}>
            Rerun
          </Button>
        </Col>
      </Row>

      <Dragger
        multiple={false}
        fileList={fileList}
        customRequest={_dummyRequest}
        onChange={onChange}
        onDrop={onDrop}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area for upload.
        </p>
      </Dragger>
    </Modal>
  </>);
};

export default RerunOptionsButton;
