import { gql } from '@apollo/client';

const processUpdate = gql`
  mutation (
    $process_id: Int!
    $name: String
    $owner: String
    $description: String
  ) {
    update_process(
      where: { id: { _eq: $process_id } }
      _set: { name: $name, description: $description, owner: $owner }
    ) {
      affected_rows
    }
  }
`;

export default processUpdate;
