import { useState } from 'react';
import { Button, message } from 'antd';
import { useApolloClient } from '@apollo/client';

import { downloadFileFromUrl } from 'utils/utils.js';
import actionUDMDownloadBlobData from 'common/graphql/mutations/actionUDMDownloadBlobData';

export const UDMBlobDownloadButton = (props) => {
  const { blob_id, ...btnProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    if (!blob_id) return console.log('No blob_id parameter');

    setLoading(true);
    client
      .mutate({
        mutation: actionUDMDownloadBlobData,
        variables: { id: blob_id },
      })
      .then((result) => {
        downloadFileFromUrl(
          result.data.udm_download_blob_data.udm_sas_data[0].blob_sas_url,
        );
        message.success({ content: 'Successfully downloaded Blob' });
      })
      .catch((error) => {
        message.error({
          content: 'Error while trying to download Blob: ' + error,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button {...btnProps} loading={loading} onClick={handleClick}></Button>
  );
};

export default UDMBlobDownloadButton;
