import { Input, Tooltip, Image } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import cronExpressionHelp from 'assets/CronExpression.png';

export const CronExpressionInput = (props) => {
  const helpText = <Image preview={false} src={cronExpressionHelp} />;

  const helpTextTooltip = (
    <Tooltip placement="top" title={helpText}>
      <QuestionCircleOutlined />
    </Tooltip>
  );

  return <Input {...props} addonAfter={helpTextTooltip} />;
};

export default CronExpressionInput;
