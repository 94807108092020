import { gql } from '@apollo/client';

export const createFlowRunStateForReRuns = gql`
  mutation createFlowRunStateForReRuns(
    $flow_run_id: uuid = ""
  ) {
    insert_oe_flow_run_state_one(
      object: {
        flow_run_id: $flow_run_id
        name: "Scheduled"
        type: "SCHEDULED"
      }
    ) {
      id,
      timestamp
    }
  }
`;