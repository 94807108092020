import {useState} from 'react';
import {Breadcrumb, DatePicker, Space, Form, Row, Col, Button} from 'antd';
import {
    DownloadOutlined,
    CloudDownloadOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {Link, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import udmDataByIdentifier from 'common/graphql/querys/udmDataByIdentifier';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import JsonViewButton from 'components/shared/JsonViewButton';
import UDMBlobDownloadButton from './UDMBlobDownloadButton';
import UDMBlobUploadButton from './UDMBlobUploadButton';
import dayjs from "dayjs";
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone');
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const UDMDataOverview = (props) => {
    const {identifier} = useParams();
    const [form] = Form.useForm();

    const [tsStart, setTsStart] = useState(
        dayjs()
            .subtract(2, 'days')
            .set('hour', 0).set('minute',0).set('second',0).set('millisecond', 0)
    );
    const [tsEnd, setTsEnd] = useState(
        dayjs()
            .add(1, 'days')
            .set('hour', 0).set('minute',0).set('second',0).set('millisecond', 0)
    );

    const getVariables = () => {
        const v = {identifier: identifier};
        if (tsStart) v.ts_start = dayjs.utc(tsStart).format();
        if (tsEnd) v.ts_end = dayjs.utc(tsEnd).format();
        return v;
    };

    const {loading, error, data, refetch} = useQuery(udmDataByIdentifier, {
        variables: getVariables(),
        fetchPolicy: 'no-cache',
    });
    const udmData = data?.udm_data;
    const dataType = data?.udm_reference_by_pk?.data_type;
    const isJson = dataType === 'JSON';
    const isBlob = dataType === 'BLOB';

    if (error) console.log(error);

    const columns = [
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            sort: true,
            search: true,
            render: (state, record, index) => {
                return (
                    <>{dayjs(record.timestamp).tz('Europe/Berlin').format('LLL')}</>
                );
            },
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
            render: (state, record, index) => {
                return (
                    <Space>
                        {isJson && record.json && (
                            <JsonViewButton jsonData={record.json} title={record.timestamp}/>
                        )}
                        {isJson && record.json && (
                            <Button
                                icon={<DownloadOutlined/>}
                                shape="circle"
                                type={'primary'}
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                    record.json,
                                )}`}
                                download={`${identifier}__${record.timestamp}.json`}
                            />
                        )}

                        {isBlob && record.blob_path && (
                            <UDMBlobDownloadButton
                                blob_id={record.id}
                                icon={<CloudDownloadOutlined/>}
                                shape="circle"
                                type={'primary'}
                            />
                        )}
                    </Space>
                );
            },
        },
    ];

    const onSearch = (values) => {
        setTsStart(values.start);
        setTsEnd(values.end);
        refetch();
    };

    const items = [
        {
            title: <Link to="/">Home</Link>,
        },
        {
            title: <Link to="/data/udm/">UDM Data Management</Link>,
        },
        {title: identifier},
    ];

    return (
        <div id="udm-data-overview" style={{backgroundColor: '#F1F0EB'}}>
            <Breadcrumb style={{marginBottom: '20px'}} items={items}/>

            <Row gutter={[16, 16]} wrap={false}>
                <Col flex="auto">
                    <Form
                        form={form}
                        onFinish={onSearch}
                        initialValues={{start: tsStart, end: tsEnd}}
                    >
                        <Row stype={{width: '100%'}}>
                            <Col span={8} style={{textAlign: 'left'}}>
                                <Form.Item label="Start" name="start">
                                    <DatePicker showTime/>
                                </Form.Item>
                            </Col>

                            <Col span={8} style={{textAlign: 'left'}}>
                                <Form.Item label="End" name="end">
                                    <DatePicker showTime/>
                                </Form.Item>
                            </Col>

                            <Col span={8} style={{textAlign: 'right'}}>
                                <Button type="primary" htmlType="submit">
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col>
                    <UDMBlobUploadButton
                        icon={<UploadOutlined/>}
                        identifier={identifier}
                        dataType={dataType}
                    >
                        Upload Data
                    </UDMBlobUploadButton>
                </Col>
            </Row>

            <AdvancedTable
                rowKey="id"
                loading={loading}
                dataSource={udmData}
                columns={columns}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 15,
                    pageSizeOptions: ['10', '15', '20', '30', '50'],
                }}
                type="udmDataOverview"
            />
        </div>
    );
};

export default UDMDataOverview;
