import { Handle } from 'react-flow-renderer/nocss';

import './alertnode.css';

import AlertDeleteButton from 'components/shared/AlertDeleteButton';

const AlertNode = (node) => {
  return (
    <div>
      <AlertDeleteButton alert={node.data.alert} />

      {node.data._io_type !== 'input' &&
        node.data._io_type !== 'standalone' && (
          <Handle
            type="target"
            position="left"
            id={'in' + node.id}
            style={{ borderRadius: 0 }}
          />
        )}
      {node.data._io_type !== 'output' &&
        node.data._io_type !== 'standalone' && (
          <Handle
            type="source"
            position="right"
            id={'out' + node.id}
            style={{ borderRadius: 0 }}
          />
        )}
    </div>
  );
};

export default AlertNode;
