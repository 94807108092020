import { gql } from '@apollo/client';

const runReRun = gql`
  mutation (
    $run_id: Int!
    $execution_id: Int!
    $model_id: Int!
    $run_parameters: jsonb!
    $context_parameters: jsonb!
  ) {
    update_run_by_pk(
      pk_columns: { id: $run_id }
      _set: { state: "Rerun", state_message: "Rerun was manually triggered" }
    ) {
      id
    }
    insert_run_one(
      object: {
        prior_run_id: $run_id
        execution_id: $execution_id
        model_id: $model_id
        run_parameters: $run_parameters
        context_parameters: $context_parameters
        state: "Scheduled"
        state_message: "Run got executed by manual action."
      }
    ) {
      id
    }
  }
`;

export default runReRun;
