import { useCallback, useState } from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';

import ModelTitle from 'components/shared/Model/ModelTitle';
import RunLogsTable from 'components/shared/Run/RunLogsTable/RunLogsTable';
import { RunLogsTaskRunInterfaceContainer } from './components/RunLogsTaskRunInterfaceContainer';
import { LoadingIndicator } from '../Loading/LoadingIndicator';
import ModelFlow from '../DataFlow/ModelFlow/ModelFlow';
import { Markdown } from '@forrs-ems/doc-tool-components';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { ParametersContainer } from './components/ParametersContainer';
import { Flex, Space, Tag } from 'antd';
import RunStateTag from './RunStateTag';
import FlowRunTag from './FlowRunTag';

const flowRunInfo = gql`
    subscription ModelByFlowId ($flowRunId: uuid!) {
        oe_flow_run(where: { id: { _eq: $flowRunId } }) {
            expected_start_time
            start_time
            end_time
            total_run_time
            flow {
                model {
                    gitlink
                    name
                }
            }
            tags
            state {
              stateName : name
              message
            }
        }
    }
`;

export const RunLogsView = ({ flowRunId }) => {
  const [selected, setSelected] = useState(null);
  // Using a counter to track updates instead of passing data directly
  const [updateVersion, setUpdateVersion] = useState(0);

  const { data, loading, error } = useSubscription(flowRunInfo, { variables: { flowRunId } });

  const handleDataUpdate = useCallback(() => {
    // Increment the counter to signal an update
    setUpdateVersion(prevVersion => prevVersion + 1);
  }, []);

  if (loading) return <LoadingIndicator />;

  if (error) return <div>Error</div>;

  const {
    start_time: startTime,
    end_time: endTime,
    total_run_time: totalRunTime,
    flow: { model: { name = '', gitlink = '' } },
    expected_start_time: expectedStartTime,
    tags,
    state,
  } = data.oe_flow_run[0];

  const handleSelectionChange = (elements) => {
    if (!elements || !elements[0]?.data) setSelected(null);
    else setSelected(elements[0]);
  };

  const boxStyle = {
    width: '100%',
    marginBottom: '10px',
  };
  return (
    <div>
      <div>Run ID: {flowRunId}</div>
      <ModelTitle gitlink={gitlink} name={name} level={4} />
      <Flex style={boxStyle} gap={'large'} align={'flex-start'} justify={'space-between'}>
        {startTime ?
          <>
            <div><b>Scheduled for</b>: {expectedStartTime.substring(0, 19)} UTC</div>
            <Flex gap={'0.2em 0'} vertical>
              <div><b>Start Time</b>: {startTime.substring(0, 19)} UTC</div>
              {endTime ? <div><b>End Time</b>: {endTime.substring(0, 19)} UTC</div> : null}
              {endTime ? <div><b>Duration</b>: {totalRunTime}</div> : null}
            </Flex></>
          :
          <div>Expected Start Time: {expectedStartTime?.substring(0, 19)} UTC</div>
        }
        <Flex gap={'5px 2px'} align={'flex-end'} justify={'flex-end'} vertical>
          <Flex gap={'5px 2px'} align={'flex-start'} justify={'flex-end'} wrap={'wrap'}>
            {tags.map((item) => <FlowRunTag key={item} tag={item} />)}
          </Flex>
          {state ? <RunStateTag state={state.stateName} key={state.stateName} message={state.message} /> :  <RunStateTag state={"Deleted"} key={"Deleted"} message={"Execution has been deleted after completion of this run."} />}
        </Flex>
      </Flex>
      <ParametersContainer flowRunId={flowRunId} data={data} />
      <ModelFlow
        flowRunId={flowRunId}
        elementsSelectable={true}
        onSelectionChange={handleSelectionChange}
        height={300}
        updateVersion={updateVersion}
      />
      {
        selected?.data?.description && (
          <div style={{ paddingBottom: '10px', backgroundColor: '#EEE' }}>
            <div style={{ marginLeft: '20px' }}>
              <Markdown
                HashLink={HashLink}
                Link={Link}
                className='description'
                style={{ paddinLeft: '20px' }}
              >{`### ${selected.data.name}\n ${selected.data.description}`}</Markdown>
            </div>
          </div>
        )
      }
      <RunLogsTaskRunInterfaceContainer
        {...{ taskRunInterface: selected?.data?.state?.task_run_interface }}
      />

      <RunLogsTable
        flowRunId={flowRunId}
        filter={
          selected?.data?.state?.task_run_id
            ? { task_run_id: selected.data.state.task_run_id }
            : null
        }
        handleDataUpdate={handleDataUpdate}
      />
    </div>
  )
    ;
};

export default RunLogsView;
