import { gql } from '@apollo/client';

export const packagesCustom = gql`
  query customPackages {
    package(where: { state: { _eq: "SHOULD_INSTALL" } }) {
      id
      name
      type
      version
      gitlink
      gittoken
      updated_at
      created_at
    }
  }
`;
export default packagesCustom;
