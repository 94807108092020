import { gql } from '@apollo/client';

const actionTriggerPipeline = gql`
  mutation ($ref: String!) {
    trigger_exeqt_builder(ref: $ref) {
      data
    }
  }
`;

export default actionTriggerPipeline;
