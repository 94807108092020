import jwt_decode from 'jwt-decode';

const REFRESH_TOKEN_BUFFER = 60 * 2;

export function getTokenState(token) {
  if (!token) return { valid: false, needRefresh: true };

  const decoded = jwt_decode(token);
  const timestampNow = Math.floor(Date.now() / 1000);

  if (decoded.exp - timestampNow < 180)
    console.log('JWT expires in: ' + (decoded.exp - timestampNow) + ' seconds');
  if (!decoded) {
    return { valid: false, needRefresh: true };
  } else if (decoded.exp && timestampNow + REFRESH_TOKEN_BUFFER > decoded.exp) {
    return { valid: false, needRefresh: true };
  }
  return { valid: true, needRefresh: false };
}
