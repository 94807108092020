import { Link } from 'react-router-dom';
import { Typography, Breadcrumb, Row, Col } from 'antd';

import EnvironmentsList from './EnvironmentsList';
import CustomPackageManagement from './CustomPackageManagement';
import PipelineStatus from './PipelineStatus';

const { Title } = Typography;

export const EnvironmentManagementPage = (props) => {
  const pipelineRef = window._env_.REACT_APP_ENVIRONMENT;
  const items = [
    {
        title: <Link to="/">Home</Link>
    },
    {
        title: 'Packages'
    }
  ]
  return (
    <div id="packages-page" style={{ backgroundColor: '#F1F0EB' }}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Row gutter={[16, 32]}>
        <Col key="environmentlist" span={24}>
          <Title level={3}>Environment List</Title>
          <EnvironmentsList />
        </Col>
        <Col key="custompackages" span={24}>
          <Title level={3}>Custom Packages</Title>
          <CustomPackageManagement />
        </Col>
        {pipelineRef && (
          <Col key="pipelines" span={24}>
            <Title level={3}>Pipelines</Title>
            <PipelineStatus pipelineRef={pipelineRef} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EnvironmentManagementPage;
