import { Typography } from 'antd';
import { prettyString } from 'utils/utils';

const { Text } = Typography;

export const BasicFieldTitle = ({ label }) => {
  return (
    <>
      {label && (
        <Text strong style={{ textTransform: 'capitalize' }}>
          {prettyString(label)}:
        </Text>
      )}
    </>
  );
};
