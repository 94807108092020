import {useContext, useState} from 'react';
import {Collapse} from 'antd';

import {useErrors} from '../../../ModelEditModal/FormErrorContext';
import {ParameterDefinitionsContext} from "../../../ExecutionEditModal/provider/ModelParameterDefinitionsProvider";
import {PanelHeader} from "./components/PanelHeader";
import {FilteredErrors} from "./components/FilteredErrors";
import {MyIcon} from "./components/MyIcon";
import {ExeqtObjectFieldBody} from "./components/ExeqtObjectFieldBody";

const {Panel} = Collapse;


const ExeqtObjectField = (props) => {
    const [editJson, setEditJson] = useState(false);
    const {errors, setErrors} = useErrors();
    const {definitions} = useContext(ParameterDefinitionsContext) || {};
    const filteredErrors = errors?.filter((error) =>
        error.property.includes(props.label),
    );


    const handleSwitchJson = (checked, event) => {
        event.stopPropagation();
        setEditJson(checked);
    };


    const enhancedSchema = {...props.schema, definitions};
    return (
        <Collapse
            style={{marginTop: '6px', marginBottom: '6px'}}
            defaultActiveKey={['1']}
            ghost={false}
            bordered={true}
            expandIconPosition="end"
            expandIcon={() => <MyIcon isActive={props.isActive}/>}
        >
            <Panel header={<PanelHeader {...{...props, editJson, handleSwitchJson}} />} key="1"
                   style={{paddingLeft: '0px'}}>
                <ExeqtObjectFieldBody editJson={editJson} enhancedSchema={enhancedSchema} {...props} />
                <FilteredErrors filteredErrors={filteredErrors}/>
            </Panel>
        </Collapse>
    );
};

export default ExeqtObjectField;

