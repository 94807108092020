import {Col, Row, Switch, Typography} from "antd";
import {InfoTooltip} from "./InfoToolTip";
import {theme} from "../../../../../../config/js/theme";
import {prettyString} from "../../../../../../utils/utils";

const {Text} = Typography;

function endsWithHyphenAndNumbers(str) {
    return /-\d+$/.test(str);
}

export const PanelHeader = (props) => {
    const title = props.label && !endsWithHyphenAndNumbers(props.label) ? props.label : 'ObjectParameter';
    return (
        <Row wrap={false}>
            <Col flex={'20px'}><InfoTooltip {...{
                // TODO find a better way to get the description
                description: props.schema.description ,
                uiSchema: props.uiSchema
            }} /></Col>
            <Col flex={'auto'}>
                <Text
                    strong
                    style={{
                        textTransform: 'capitalize',
                        color: theme['primary-color-green-light'],
                    }}
                >
                    {prettyString(title)}:
                </Text>
            </Col>
            <Col flex={'60px'}>
                <Switch
                    checked={props.editJson}
                    checkedChildren="json"
                    unCheckedChildren="form"
                    onChange={props.handleSwitchJson}
                />
            </Col>
        </Row>
    )
};