import {gql} from '@apollo/client';

export const runCreate = gql`
  mutation CreateRun ($deployment_id: uuid!, $run_parameters: jsonb, $model_name: String!, $project_name: String!) {
    create_run(deployment_id: $deployment_id, run_parameters: $run_parameters, model_name: $model_name, project_name: $project_name) {
        run_id
        status
        details
    }
  }
`;