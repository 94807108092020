import { gql } from '@apollo/client';

const udmReferenceEdit = gql`
  mutation udmReferenceEdit(
    $domain: String = ""
    $identifier: String!
    $new_identifier: String!
    $schema: jsonb = ""
    $meta: jsonb = ""
    $subdomain: String = ""
    $data_type: String!
  ) {
    update_udm_reference_by_pk(
      pk_columns: { identifier: $identifier }
      _set: {
        identifier: $new_identifier
        domain: $domain
        schema: $schema
        meta: $meta
        subdomain: $subdomain
        data_type: $data_type
      }
    ) {
      identifier
    }
  }
`;

export default udmReferenceEdit;
