import { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';

import { useApolloClient, useQuery } from '@apollo/client';
import AdvancedForm from '../../shared/AdvancedForm/AdvancedForm';
import userGroupCreate from 'common/graphql/mutations/userGroupCreate';
import userGroupEdit from 'common/graphql/mutations/userGroupEdit';
import permissionAll from 'common/graphql/querys/permissionAll';
import userAll from 'common/graphql/querys/userAll';

const UserGroupEditModal = (props) => {
  const { userGroup, onDataChange, ...modalProps } = props;
  const emptyForm = {
    name: null,
    user_group_description: null,
    permissions: [],
    users: [],
  };
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(emptyForm);

  const resPermissionsAll = useQuery(permissionAll);
  const resUserAll = useQuery(userAll);

  useEffect(() => {
    if (userGroup)
      return setFormData({
        name: userGroup.name,
        user_group_description: userGroup.description,
        permissions: userGroup.permissions?.map((i) => i.permission.id),
        users: userGroup.users?.map((u) => u.user.id),
      });
    return setFormData(emptyForm);
  }, [userGroup]);

  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
      user_group_description: {
        type: 'string',
        label: 'Description',
      },
      users: {
        type: 'array',
        subtype: 'multi_select',
        enum: resUserAll.data?.auth_user,
        label_key: 'name',
        value_key: 'id',
        placeholder: 'Please select users',
        tag_color: 'green',
      },
      permissions: {
        type: 'array',
        subtype: 'multi_select',
        enum: resPermissionsAll.data?.auth_permission,
        label_key: 'name',
        value_key: 'id',
        placeholder: 'Please select permissions',
        tag_color: 'orange',
      },
    },
    required: ['name', 'users', 'permissions'],
  };

  const handleDataChange = (e) => {
    console.log('formData:', e.formData);
    setFormData(e.formData);
  };

  const handleFormReset = () => {
    if (userGroup)
      setFormData({
        name: userGroup.name,
        user_group_description: userGroup.description,
        permissions: userGroup.permissions?.map((i) => i.permission.id),
        users: userGroup.user?.map((u) => u.user.id),
      });
    else setFormData(emptyForm);
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (!userGroup) {
      // console.log({name: formData.name, description: formData.description, userGroup_type: formData.userGroup_type,
      //     process_ids: formData.processes.map(id => { return {process_id: id}}), project_ids: formData.projects.map(id => {return {project_id: id}})})
      client
        .mutate({
          mutation: userGroupCreate,
          variables: {
            name: formData.name,
            description: formData.user_group_description,
            users: formData.users.map((id) => {
              return { user_id: id };
            }),
            permissions: formData.permissions.map((id) => {
              return { permission_id: id };
            }),
          },
        })
        .then((result) => {
          message.success({ content: 'Successfully created user group.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to create user group: ' + error,
          });
          setLoading(false);
        });
    } else {
      const removedUsers = userGroup.users
        .filter((u) => !formData.users.includes(u.user.id))
        .map((u) => u.user.id);
      const addedUsers = formData.users.filter(
        (user_id) => !userGroup.users.map((u) => u.user.id).includes(user_id),
      );
      const removedPermissions = userGroup.permissions
        .filter((p) => !formData.permissions.includes(p.permission.id))
        .map((p) => p.permission.id);
      const addedPermissions = formData.permissions.filter(
        (permission_id) =>
          !userGroup.permissions
            .map((p) => p.permission.id)
            .includes(permission_id),
      );

      client
        .mutate({
          mutation: userGroupEdit,
          variables: {
            id: userGroup.id,
            name: formData.name,
            description: formData.user_group_description,
            users_added: addedUsers.map((id) => {
              return { user_group_id: userGroup.id, user_id: id };
            }),
            permissions_added: addedPermissions.map((id) => {
              return { user_group_id: userGroup.id, permission_id: id };
            }),
            users_removed: removedUsers,
            permissions_removed: removedPermissions,
          },
        })
        .then((result) => {
          message.success({ content: 'Successfully updated user group.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to update user group: ' + error,
          });
          setLoading(false);
        });
    }
  };

  const formChanged = () => {
    if (!userGroup) {
      if (formData) return true;
    } else
      return (
        userGroup.name !== formData.name ||
        userGroup.description !== formData.user_group_description ||
        userGroup.projects !== formData.projects ||
        userGroup.processes !== formData.processes
      );
  };

  const onClose = () => {
    handleFormReset();
    if (modalProps.onClose) modalProps.onClose();
  };
  const onCancel = () => {
    handleFormReset();
    if (modalProps.onCancel) modalProps.onCancel();
  };

  const title = () => {
    if (!userGroup) return 'Create User Group';
    else return 'Edit User Group';
  };

  return (
    <Modal
      {...modalProps}
      title={title()}
      width={800}
      onCancel={onCancel}
      onClose={onClose}
      destroyOnClose
      footer={[
        <Button
          key="resetButton"
          onClick={handleFormReset}
          hidden={!formChanged}
        >
          Reset
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {!userGroup ? 'Create' : 'Update'} User Group
        </Button>,
      ]}
    >
      <AdvancedForm
        schema={schema}
        formData={formData}
        onChange={handleDataChange}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default UserGroupEditModal;
