import { gql } from '@apollo/client';

export const udmDataBIdentifier = gql`
  query QueryUDMLatest(
    $identifier: String!
    $ts_start: timestamptz = "0001-01-01T00:00:00.000000"
    $ts_end: timestamptz = "9999-12-31T00:00:00.000000"
  ) {
    udm_data(
      order_by: { timestamp: asc }
      where: {
        _and: {
          identifier: { _eq: $identifier }
          _and: { timestamp: { _gte: $ts_start } }
          timestamp: { _lte: $ts_end }
        }
      }
    ) {
      json
      blob_path
      timestamp
      created_at
      id
      identifier
    }
    udm_reference_by_pk(identifier: $identifier) {
      domain
      subdomain
      identifier
      meta
      schema
      data_type
      updated_at
    }
  }
`;

export default udmDataBIdentifier;
