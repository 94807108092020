import { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { useApolloClient } from '@apollo/client';

import processDelete from 'common/graphql/mutations/processDelete';

export const ProcessDeleteButton = (props) => {
  const { process_id, children, ...btnProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleDelete = (event) => {
    event.stopPropagation();
    if (!props.process_id) return console.log('No process_id parameter');

    setLoading(true);

    client
      .mutate({
        mutation: processDelete,
        variables: { process_id: process_id },
      })
      .then((result) => {
        message.success({ content: 'Successfully deleted Process' });
        setLoading(false);
        if (props.onDelete) props.onDelete();
      })
      .catch((error) => {
        message.error({
          content: 'Error while trying to delete process: ' + error,
        });
        setLoading(false);
      });
  };

  return (
    <Popconfirm
      placement="top"
      title={'Do you want to delete this Process?'}
      onConfirm={handleDelete}
      onCancel={(e) => e.stopPropagation()}
      okText="Yes"
      cancelText="No"
    >
      <Button
        {...btnProps}
        loading={loading}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

export default ProcessDeleteButton;
