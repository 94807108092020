import { useState } from 'react';
import { Button, Dropdown, Menu, Spin, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import {ProcessFlow} from '../../shared/DataFlow/ProcessFlow/ProcessFlow';
import ProcessDeleteButton from 'components/shared/ProcessDeleteButton';
import ProcessEditModal from './ProcessEditModal';
import useReSubscription from '../../../services/apollo/subscription';
import executionsFromProcess from 'common/graphql/subscriptions/executionsFromProcess';

const { Title, Text } = Typography;

const ProcessPanel = (props) => {
  const { process } = props;
  const [showModal, setShowModal] = useState(false);
  const { data, error, loading } = useReSubscription(executionsFromProcess, {
    variables: { process_id: process.id },
  });
  const executions = data?.execution;
  const hasExecutions = executions && executions.length > 0;

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChange = () => {
    if (props.onChange) props.onChange();
  };

  const items = [
    {
      key: 'edit',
      label: (
        <Button type="text" onClick={() => setShowModal(true)}>
          Edit
        </Button>
      ),
    },
    {
      key: 'delete',
      label: (
        <ProcessDeleteButton
          process_id={process.id}
          type="text"
          onDelete={handleChange}
        >
          Delete
        </ProcessDeleteButton>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: 'white', padding: '10px' }}>
      <Dropdown menu={{ items }} placement="bottomRight">
        <Button
          type="primary"
          icon={<MenuOutlined />}
          shape="circle"
          style={{ position: 'absolute', right: '0px', marginRight: '16px' }}
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>

      <Title level={5}>
        {process.name} {loading && <Spin size={'small'} />}
      </Title>
      <Text strong>Description: </Text>
      <Text>{process.description}</Text>
      <br />
      <Text strong>Owner: </Text>
      <Text>{process.owner}</Text>
      {hasExecutions && (
        <ProcessFlow
          height={500}
          executions={executions}
          elementsSelectable={true}
          selectionCallback={() => console.log('selection in ExecutionPage')}
        />
      )}

            <ProcessEditModal
                open={showModal}
                process={{...process}}
                onChange={handleChange}
                onClose={closeModal}
                onCancel={closeModal}
            />
        </div>
    );
};

export default ProcessPanel;
