import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Divider,
  Modal,
  Row,
  Col,
  Typography,
  Button,
  Popconfirm,
  message,
  Form,
  Space,
  Spin,
} from 'antd';
import { useApolloClient, useQuery } from '@apollo/client';

import ModelTitle from '../Model/ModelTitle';
import ModelInfoCollapseDescription from 'components/shared/Model/ModelInfoCollapseDescription';
import ModelInfoCollapseModelView from 'components/shared/Model/ModelInfoCollapseModelView';
import ModelInfoCollapseSecrets from 'components/shared/Model/ModelInfoCollapseSecrets';
import ModelInfoCollapseModelParameter from 'components/shared/Model/ModelInfoCollapseParameter';
import ExecutionSettingsForm from 'components/shared/ExecutionEditModal/ExecutionSettingsForm';

import processExecutionsSimple from 'common/graphql/querys/processExecutionsSimple';
import { getFlowId } from '../../../common/graphql/querys/getFlowId';
import {
  handleBack,
  handleCreateExecution,
  handleParameterChange,
  setFooter,
} from './utils';
import { FormErrorProvider } from './FormErrorContext';
import { ModelEditModalFooter } from './components/ModelEditModalFooter';
import { useAuthStore } from '../../../store/useAuthStore';

const { Title } = Typography;

export const ModelEditModal = (props) => {
  const { authData: { accessToken } } = useAuthStore((state) => ({
    authData: state.authData,
    updateAuthData: state.updateAuthData,
  }));
  const { model, adhocRunMode, ...modalProps } = props;
  const client = useApolloClient();

  const processesQuery = useQuery(processExecutionsSimple);
  if (processesQuery.error) console.log(processesQuery.error);


  const [parameters, setParameters] = useState({});
  const [isCreateRun, setIsCreateRun] = useState(adhocRunMode);
  const [isCreateExecution, setIsCreateExecution] = useState(false);
  const [isBtnSaveExecutionLoading, setIsBtnSaveExecutionLoading] =
    useState(false);
  const [createdRunId, setCreatedRunId] = useState(null);
  const [executionSettingForm] = Form.useForm();
  const [hasErrors, setHasErrors] = useState();


  // const tasks = model.tasks_details?.length ? model.tasks_details : model.tasks;

  return (
    <Modal
      {...modalProps}
      title={
        <div style={{ fontWeight: 'normal' }}>
          <ModelTitle gitlink={model?.gitlink} name={model?.name} />
          <div style={{ display: 'flex' }}>
            <p>Project: {model?.project_name ? model?.project_name : '-'}</p>
            {model?.owner && <p style={{ marginLeft: '40px' }}>Owner: {model.owner}</p>}
          </div>
          <Divider style={{ marginTop: 0 }} />
        </div>}
      width={1100}
      destroyOnClose
      onCancel={(e) => {
        handleBack(setIsCreateRun, setIsCreateExecution, setCreatedRunId);
        props.onClose();
        executionSettingForm.resetFields();
        setParameters({})
        // the three flags below should be set by handleBack,
        // but somehow they are not!?
        setIsCreateRun(false);
        setIsCreateExecution(false);
        setCreatedRunId(null)
      }}
      onOK={() => {
        handleBack(setIsCreateRun, setIsCreateExecution, setCreatedRunId);
        props.onClose();
        executionSettingForm.resetFields();
        setParameters({})
      }}
      footer={() => <ModelEditModalFooter {...{
        setIsCreateRun,
        setIsCreateExecution,
        isCreateRun,
        adhocRunMode,
        createdRunId,
        model,
        parameters,
        onClose: props.onClose,
        setCreatedRunId,
        isCreateExecution,
        executionSettingForm,
        isBtnSaveExecutionLoading,
      }} />}
    >
      <Row gutter={[0, 0]}>
        {model.name && (
          <Col span={24}>

            {model.description && (
              <ModelInfoCollapseDescription description={model.description} />
            )}
            {model && <ModelInfoCollapseModelView model={model} />}
            {model.secrets && model.secrets.length > 0 && (
              <ModelInfoCollapseSecrets secrets={model.secrets} />
            )}

            {model && model.has_parameter && (
              <>
                <Divider />
                <FormErrorProvider>
                  <ModelInfoCollapseModelParameter
                    model={model}
                    handleParameterChange={handleParameterChange(setParameters)}
                    expand={isCreateExecution || isCreateRun}
                    onError={(e) => setHasErrors(e.length > 0)} // if the error array is not empty, there are errors
                    cleanErrors={() => setHasErrors(false)}
                  />
                </FormErrorProvider>
              </>
            )}
          </Col>
        )}
        <Col span={24} hidden={!isCreateExecution}>
          <Title level={5}>Execution Settings:</Title>
          <ExecutionSettingsForm
            execution={false}
            form={executionSettingForm}
            handleFormChange={null}
            onFinish={(execution) => handleCreateExecution({
              processesQuery,
              model,
              parameters,
              setIsBtnSaveExecutionLoading,
              client,
              onClose: props.onClose,
              handleBack: handleBack(
                setIsCreateRun,
                setIsCreateExecution,
                setCreatedRunId,
              ),
              executionSettingForm,
              accessToken,
              flowId: model?.flow_id,
              modelName: model?.name,
              modelId: model?.id,
              executionName: executionSettingForm.getFieldValue('executionName'),
              processId: executionSettingForm.getFieldValue('process'),
              projectName: model?.project?.name,
              execution,
              deploymentId: model?.deployment_id,
            })}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModelEditModal;

