import {Button, Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {isObjectEmpty} from "../../../../utils/utils";
import {theme} from "../../../../config/js/theme";
import Editor from "@monaco-editor/react";
import {gql, useQuery} from "@apollo/client";

const flowRunParametersQuery = gql`
    query FlowRunParameters($flow_run_id: uuid!) {
        oe_flow_run_by_pk(id: $flow_run_id) {
            parameters
        }
    }`;


export const ParametersContainer = ({flowRunId}) => {

    const {data, loading, error} = useQuery(flowRunParametersQuery, {
        variables: {flow_run_id: flowRunId}
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;

    const flowRun = data.oe_flow_run_by_pk;

    if (!(flowRun?.parameters && !isObjectEmpty(flowRun?.parameters))) return null;

    const prettyParameters = JSON.stringify(flowRun?.parameters, null, 2);

    const parameterOverlayB = (
        <Menu
            style={{
                width: '600px',
                height: '250px',
                borderStyle: 'solid',
                borderColor: theme['primary-color-green-light'],
            }}
        >
            <Editor
                theme="light"
                defaultLanguage="json"
                defaultValue={prettyParameters}
            />
        </Menu>
    );

    return <Dropdown
        placement="bottomLeft"
        overlay={parameterOverlayB}
        trigger={'click'}
        arrowPointAtCenter
        disabled={!prettyParameters}
    >
        <Button
            type="primary"
            size="small"
        >
            Parameters <DownOutlined/>
        </Button>
    </Dropdown>
}