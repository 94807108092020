import {Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {theme} from "../../../../../../config/js/theme";

export const InfoTooltip = ({description, uiSchema}) => (
    <>
        {description ? (
            <Tooltip
                color="#003A2F"
                overlayClassName=""
                placement="right"
                title={description}
                trigger="hover"
            >
                <InfoCircleOutlined
                    style={{
                        paddingRight: '5px',
                        color: theme['primary-color-green-light'],
                    }}
                />
            </Tooltip>
        ) : (
            <InfoCircleOutlined
                style={{paddingRight: '5px', color: theme['primary-color-beige']}}
            />
        )}
    </>
);
