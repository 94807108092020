import {gql} from '@apollo/client';

export const flowRunsLastByDateUntilNow = gql`
    subscription flowRunsLastByDateUntilNow ($from: timestamptz!) {
        oe_flow_run(where: {expected_start_time: {_gte: $from}}, order_by: {expected_start_time: desc}, limit: 501) {
            id
            state_type
            state_name
            start_time
            expected_start_time
            next_scheduled_start_time
            end_time
            name
            flow {
              model {
                  name
              }
            }
            deployment_id
            deployment {
              execution {
                name
              }
              name
            }
            state {
              message
            }
            tags
        }
    }
`;