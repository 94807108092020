import { Collapse, Space, Typography, Row, Col } from 'antd';

import { SecretAvailableTag } from 'components/shared/SecretAvailableTag';

const { Text } = Typography;
const { Panel } = Collapse;

export const ModelInfoCollapseSecrets = (props) => {
  const { secrets } = props;

  return (
    <Collapse ghost defaultActiveKey="">
      <Panel header={<Text>Secrets:</Text>} key={'description'}>
        {secrets && secrets.length > 0 && (
          <Space>
            <Row gutter={[4, 4]}>
              {secrets.map((s) => (
                <Col key={s}>
                  <SecretAvailableTag key={s} secret={s} />
                </Col>
              ))}
            </Row>
          </Space>
        )}
      </Panel>
    </Collapse>
  );
};

export default ModelInfoCollapseSecrets;
