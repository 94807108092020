import {useState, useEffect, useContext} from 'react';
import {generatePath, useHistory} from 'react-router-dom';

import {useApolloClient} from '@apollo/client';
import {Button, message, Popconfirm, Space, Typography} from 'antd';

import AdvancedForm from './AdvancedForm/AdvancedForm';
import runInsertDirect from 'common/graphql/mutations/runInsertDirect';
import {
    CaretRightOutlined,
    SaveOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import {ParameterDefinitionsContext} from './ExecutionEditModal/provider/ModelParameterDefinitionsProvider';
import { hasRef, setFormDefaults } from './AdvancedForm/utils';
import {actionExecutionUpdate} from "../../common/graphql/mutations/actionExecutionUpdate";
import {isEmpty} from "lodash";
import {runCreate} from "../../common/graphql/mutations/runCreate";

const updateParameters = ({executionId, deploymentId, processId, modelName, projectName, parameters}) => {
        return {
            mutation: actionExecutionUpdate,
            variables: {
                parameters,
                model_name: modelName,
                execution_id: executionId,
                project_name: projectName,
                process_id: processId,
                deployment_id: deploymentId,
            }}
        }

export const ParameterEditForm = (props) => {
    const {model, execution, onError, cleanErrors} = props;
    // when creating an execution take the schema found in the initial deployment via the model relationship
    // when editing it means that there is already an execution, so then you can get the schema from the deployment
    // still I am not sure if this is neccessary, since the schema might not change
    const isEdit = !!execution;
    const parameterSchema = isEdit ? execution.deployment?.parameter_openapi_schema : model?.deployment.parameter_openapi_schema;
    const schema = {
        ...parameterSchema,
    };
    const history = useHistory();
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [isRunNowLoading, setIsRunNowLoading] = useState(false);

    const [formData, setFormData] = useState();

    const {definitions, setDefinitions} = useContext(ParameterDefinitionsContext);

    // setDefinitions(schema.definitions);
    useEffect(() => {
        setDefinitions(schema.definitions);
    }, [schema.definitions, setDefinitions]);

    useEffect(() => {
        if (execution && !isEmpty(execution.deployment.parameters)) {
            setFormData(execution.deployment.parameters)
        } else {
            setFormData(setFormDefaults(schema, schema.definitions));
        }

    }, [execution?.deployment?.parameters]);

    const handleDataChange = (e) => {
        setFormData(e.formData);
        if (props.onChange) props.onChange(e.formData);
    };

    const handleFormReset = () => {
        setFormData(!isEmpty(execution.deployment.parameters) ? execution.deployment.parameters : setFormDefaults(schema, schema.definitions));
    };

    const formChanged = execution?.deployment?.parameters !== formData;

    const onSubmit = (data, formData, execution) => {
        setLoading(true);
        console.log("hit onSubmit");
        const triggerType = execution.trigger_type;
        const {mutation, variables} = updateParameters({
            executionId: execution.id,
            deploymentId: execution.deployment_id,
            processId: execution.process_id,
            modelName: execution.model.name,
            projectName: execution.model.project.name,
            parameters: formData,
        });

        client
            .mutate({
                mutation,
                variables
            })
            .then((result) => {
                message.success({content: 'Successfully updated Parameter.'});
            })
            .catch((error) => {
                // have a look why Hasura delivers the error message in this way
                const errorMessage = error.graphQLErrors?.[0].extensions?.internal?.response?.body?.[0].message;
                message.error({
                    content: 'Error while trying to update Parameter: ' + errorMessage,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleRedirect = (id) => {
        const path = generatePath('/ops/runs/:id?', {id: id});
        history.push(path);
    };

    const handleRunNow = (formData, execution) => {
        let _id = null;
        if (execution?.model?.id) _id = execution.model.id;
        else if (model?.id) _id = model.id;

        setIsRunNowLoading(true);
        client
            .mutate({
                mutation: runCreate,
                variables: {
                    deployment_id: execution.deployment_id,
                    run_parameters: formData,
                    model_name: execution.model.name,
                    project_name: execution.model.project.name
                },
            })
            .then((result) => {
                message.success(
                    <Space>
                        <Typography.Text>Successfully scheduled direct run</Typography.Text>
                        <Button
                            icon={<CaretRightOutlined style={{color: 'white'}}/>}
                            size="small"
                            type="primary"
                            style={{marginLeft: '10px'}}
                            onClick={() => handleRedirect(result.data.create_run.run_id)}
                        >
                            Go To Run
                        </Button>
                    </Space>,
                    5,
                );
                setIsRunNowLoading(false);
            })
            .catch((error) => {
                message.error({
                    content: 'Error while trying to schedule direct run: ' + error,
                });
                setIsRunNowLoading(false);
            });
    };

    // no form data -> no parameters
    if (isEmpty(formData)) return <h3>No Parameters found</h3>;
    // Without definitions I cant get the objects for the schema properties if $refs are in the schema
    if (hasRef(schema) && (!definitions || Object.keys(definitions).length === 0) ) return <h3>Definitions not found in parameter schema. Error rendering parameter inputs.</h3>;

    return (
        <AdvancedForm
            readOnly={props.readonly}
            schema={schema}
            formData={formData}
            onChange={handleDataChange}
            onSubmit={props.child ? null : (data) => onSubmit(data, formData, execution)}
            onError={onError}
            cleanErrors={cleanErrors}
        >
            <Space
                style={{
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    visibility: props.child ? 'hidden' : 'visible',
                }}
            >
                <Button
                    icon={<SaveOutlined/>}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={!formChanged}
                >
                    Update Parameters
                </Button>
                <Button
                    icon={<UndoOutlined/>}
                    onClick={handleFormReset}
                    disabled={!formChanged}
                >
                    Reset Fields
                </Button>
                <Popconfirm
                    placement="top"
                    title={'Do you really want to start a direct run?'}
                    onConfirm={() => handleRunNow(formData, execution)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button icon={<CaretRightOutlined/>} loading={isRunNowLoading}>
                        Run Now {formChanged && 'with Overrides'}
                    </Button>
                </Popconfirm>
            </Space>
        </AdvancedForm>
    );
};
