import {useState} from 'react';
import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';

import {message, Button, Space, Typography, Popconfirm} from 'antd';
import {useApolloClient} from '@apollo/client';
import {CaretRightOutlined} from '@ant-design/icons';

import BasicTooltipButton from 'components/shared/BasicTooltipButton';

import {handleCancelRun} from "../ModelEditModal/utils";

const RunStopMessage = (props) => {
    const {onClick} = props;
    return (
        <Space>
            <Typography.Text>Terminating Run</Typography.Text>
            <Button
                icon={<CaretRightOutlined style={{color: 'white'}}/>}
                size="small"
                type="primary"
                style={{marginLeft: '10px'}}
                onClick={onClick}
            >
                Go To Run
            </Button>
        </Space>
    );
};

export const RunStopButton = (props) => {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleRedirect = (id) => {
        const path = generatePath('/ops/runs/:id?', {id: id});
        history.push(path);
    };

    const handleClick = async (event) => {
        event.stopPropagation();
        if (!props.run_id) return console.log('No run_id parameter');
        setLoading(true);
        try {
            await handleCancelRun({client: client, flowRunId: props.run_id});
            // message.success(<RunStopMessage onClick={() => handleRedirect(props.run_id)}/>, 5);
            setLoading(false);


        } catch (error) {
            message.error({content: 'Error while trying to stop Run. ' + error});
            setLoading(false);

        }
    };

    return (
        <Popconfirm
            placement="top"
            title={'Are you sure?'}
            onConfirm={handleClick}
            onCancel={(e) => e.stopPropagation()}
            okText="Yes"
            cancelText="No"
        >
            <BasicTooltipButton
                tooltip={'Stop Run'}
                {...props}
                loading={loading}
                onClick={(e) => e.stopPropagation()}
            />
        </Popconfirm>
    );
};

export default RunStopButton;
