import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Space, Breadcrumb, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';

import cdmReferenceAll from 'common/graphql/querys/cdmReferencesAll';
import cdmReferenceDelete from 'common/graphql/mutations/cdmReferenceDelete';

import BasicTooltipButton from 'components/shared/BasicTooltipButton';
import SafeDeleteIdMutationButton from 'components/shared/SafeDeleteIdMutationButton';
import CDMReferenceEditModal from './CDMReferenceEditModal';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';

export const CDMPage = () => {
  const { loading, error, data, refetch } = useQuery(cdmReferenceAll);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const history = useHistory();
  const references = data?.cdm_reference;

  const handleChange = () => {
    refetch();
  };

  const columns = [
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier',
      sort: true,
      search: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sort: true,
      filter: true,
    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      key: 'timezone',
      sort: true,
      filter: true,
    },
    {
      title: 'Default',
      dataIndex: 'default_active',
      key: 'default_active',
      sort: true,
      filter: true,
      render: (state, record, index) => {
        if (record.default_active) return <Tag color="green">active</Tag>;
        return <Tag color="magenta">disabled</Tag>;
      },
    },
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      render: (state, record, index) => {
        return (
          <Space>
            <BasicTooltipButton
              tooltip={'Show Data'}
              icon={<EyeOutlined />}
              size={'medium'}
              shape="circle"
              type="primary"
              onClick={(event) => {
                event.stopPropagation();
                const path = generatePath('/data/cdm/:identifier', {
                  identifier: record.identifier,
                });
                history.push(path);
              }}
            />

            <BasicTooltipButton
              tooltip={'Edit Reference'}
              icon={<EditOutlined />}
              size={'medium'}
              shape="circle"
              type="primary"
              onClick={(event) => {
                event.stopPropagation();
                openModal(record);
              }}
            />
            <SafeDeleteIdMutationButton
              id={record.identifier}
              idKey="identifier"
              mutation={cdmReferenceDelete}
              onDataChange={handleChange}
              tooltip={'Delete Reference'}
              icon={<DeleteOutlined />}
              size={'medium'}
              shape="circle"
              type="secondary"
            />
          </Space>
        );
      },
    },
  ];

  let transformed_data = [];
  if (references) transformed_data = references;

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  if (error) console.log(error);

  const items = [
    {
        title: <Link to="/">Home</Link>,
    },
    {
        title: 'CDM Data Management',
    }
  ]

  return (
    <div id="secret-page" style={{ backgroundColor: '#F1F0EB' }}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Space style={{ marginBottom: '10px', width: '100%' }}>
        <BasicTooltipButton type="primary" onClick={() => openModal(null)}>
          Add Reference
        </BasicTooltipButton>
      </Space>

      <CDMReferenceEditModal
        open={showModal}
        data={modalData}
        onDataChange={handleChange}
        onClose={closeModal}
        onCancel={closeModal}
      />

      {error && <p>Error</p>}
      {!error && (
        <AdvancedTable
          rowKey="identifier"
          dataSource={transformed_data}
          columns={columns}
          loading={loading}
          type="cdmPage"
        />
      )}
    </div>
  );
};

export default CDMPage;
