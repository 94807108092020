import { DateTime } from 'luxon';
import { message } from 'antd';

export function toTimeZone(
  timeString,
  timeZone = 'Europe/Berlin',
  format = 'yyyy-MM-dd HH:mm:ss',
) {
  if (timeString)
    return DateTime.fromISO(timeString).setZone(timeZone).toFormat(format);
  return null;
}

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  message.success({ content: 'Copied Value to Clipboard.' });
};

export function isObjectEmpty(object, deep = true) {
  if (Object.keys(object).length === 0) return true;
  if (Object.keys(object).length === 1) {
    if (deep) return object[Object.keys(object)[0]].length === 0;
  }
  return false;
}

export function downloadFileFromUrl(url) {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function prettifyJson(jsonData) {
  try {
    const obj = JSON.parse(jsonData);
    return JSON.stringify(obj, null, 2);
  } catch (error) {
    message.error({ content: 'JSON Error: ' + error });
    return '';
  }
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export function prettyString(str) {
  if (!str) return '';
  if (!isString(str)) throw new Error('Input is not a string');

  return str
    .replace(/\.[^/.]+$/, '')
    .replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function shortenString(fullStr, strLen, separator) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substring(0, frontChars) +
    separator +
    fullStr.substring(fullStr.length - backChars)
  );
}
