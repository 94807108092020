import { create } from 'zustand';

export const useAuthStore = create((set) => ({
    authData: JSON.parse(localStorage.getItem('authData')),
    userData: JSON.parse(localStorage.getItem('userData')),
    profilePicture: null,
    updateAuthData: (data) => {
        localStorage.setItem('authData', JSON.stringify(data));
        set({ authData: data });
    },
    updateUserData: (data) => {
        localStorage.setItem('userData', JSON.stringify(data));
        set({ userData: data });
    },
    updateProfilePicture: (data) => {
        set({ profilePicture: data });
    },
    logout: () => {
        localStorage.clear();
        set({ authData: null, userData: null });
    },
    getUserId: (state) => state.userData?.id,
}))