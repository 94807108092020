import { useState } from 'react';
import { message, Popconfirm } from 'antd';
import { useApolloClient } from '@apollo/client';

import BasicTooltipButton from 'components/shared/BasicTooltipButton';

export const SafeDeleteIdMutationButton = (props) => {
  const { id, idKey, mutation, onDataChange, children, ...btnProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleDelete = (event) => {
    event.stopPropagation();
    if (!props.id) return console.log('No id parameter');

    setLoading(true);

    const customIdKey = idKey || 'id';
    client
      .mutate({ mutation: mutation, variables: { [customIdKey]: id } })
      .then((result) => {
        message.success({ content: 'Successfully deleted.' });
        setLoading(false);
        if (onDataChange) onDataChange();
      })
      .catch((error) => {
        message.error({ content: 'Error while trying to delete: ' + error });
        setLoading(false);
      });
  };

  return (
    <Popconfirm
      placement="top"
      title={'Are you sure?'}
      onConfirm={handleDelete}
      onCancel={(e) => e.stopPropagation()}
      okText="Yes"
      cancelText="No"
    >
      <BasicTooltipButton
        {...btnProps}
        loading={loading}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </BasicTooltipButton>
    </Popconfirm>
  );
};

export default SafeDeleteIdMutationButton;
