import {Col, Row} from 'antd';
import {CloudDownloadOutlined, RedoOutlined} from '@ant-design/icons';
import {Handle} from 'react-flow-renderer/nocss';

import DownloadBlobButton from 'components/shared/DownloadBlobButton';
import RerunOptionsButton from './RerunOptionsButton';
import 'components/shared/DataFlow/grytnode.css';

import {setDataManagementInfo, getStyle} from './utils';
import {TaskNodeDB} from './TaskNodeDB';

export const TaskNode = ({selected, data, id}) => {
    const loading = data.state === 'Running';
    const style = data.hasColor ? getStyle(data?.state?.state_name) : {color: '#FFFFFF'};

    const interfaceInfos = data?.state?.task_run_interface.map(
        ({request_type, interface_name}) => ({
            request_type,
            interface_name,
        }),
    );
    const dataManagementInfo = setDataManagementInfo(interfaceInfos);

    return (
        <div className="grytnode_wrapper">
            {selected && <div className="grytnode_selected"></div>}

            <div className="grytnode" style={style}>
                <Row wrap={false} style={{height: '100%', justifyContent: 'center'}}>
                    <div
                        className="grytnode_main"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flex: 1,
                        }}
                    >
                        <div className="grytnode_label">{data.name}</div>
                        <TaskNodeDB
                            {...{
                                dataManagementInfo,
                                hasFailed: data?.state?.state_name === 'Failed',
                                hasColor: data.hasColor
                            }}
                        />
                    </div>
                    {data.state?.result && (
                        <Col flex="24px" className="grytnode_rightbar">
                            <Row gutter={[0, 4]}>
                                <Col span={24} style={{lineHeight: '16px'}}>
                                    <DownloadBlobButton
                                        icon={<CloudDownloadOutlined/>}
                                        size={'small'}
                                        shape="circle"
                                        type="primary"
                                        blob_url={data.state.result}
                                        blob_container={'raw-data-dump'}
                                    />
                                </Col>
                                {data.state?.slug && (
                                    <Col span={24} style={{lineHeight: '16px'}}>
                                        <RerunOptionsButton
                                            icon={<RedoOutlined/>}
                                            size={'small'}
                                            shape="circle"
                                            type="secondary"
                                            nodeData={data}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )}
                </Row>

                {loading && <div className="grytnode_loading"></div>}

                {data._io_type !== 'input' && (
                    <Handle type="target" position="left" style={{borderRadius: 0}}/>
                )}
                {data._io_type !== 'output' && (
                    <Handle
                        type="source"
                        position="right"
                        id={id}
                        style={{borderRadius: 0}}
                    />
                )}
            </div>
        </div>
    );
};

export default TaskNode;
