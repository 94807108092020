import {createContext, useState} from "react";

export const FormHasValidationErrorContext = createContext(null);

export const FormHasValidationErrorProvider = ({ children }) => {
    const [hasValidationError, setHasValidationError] = useState(false);

    return (
        <FormHasValidationErrorContext.Provider
            value={{ hasValidationError, setHasValidationError }}
        >
            {children}
        </FormHasValidationErrorContext.Provider>
    );
}