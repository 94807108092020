import { gql } from '@apollo/client';

const secretEdit = gql`
  mutation ($id: Int!, $key: String!, $value: String!) {
    update_secret_by_pk(
      pk_columns: { id: $id }
      _set: { key: $key, value: $value }
    ) {
      id
      key
      value
    }
  }
`;

export default secretEdit;
