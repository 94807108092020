import { useQuery } from '@apollo/client';

import { Tag, Tooltip } from 'antd';
import {
  LockFilled,
  SyncOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import secretExistsByKey from 'common/graphql/querys/secretExistsByKey';

export const SecretAvailableTag = (props) => {
  const { secret } = props;
  const { loading, error, data } = useQuery(secretExistsByKey, {
    variables: { key: secret },
  });

  if (error) {
    console.log(error);
    return (
      <Tooltip placement="top" title={"Couldn't load Secret state."}>
        <Tag icon={<LockFilled />} color="default">
          {secret}
        </Tag>
      </Tooltip>
    );
  }

  if (loading)
    return (
      <Tooltip placement="top" title={'Loading Secret State.'}>
        <Tag icon={<SyncOutlined spin />} color="processing">
          {secret}
        </Tag>
      </Tooltip>
    );

  if (data) {
    if (data.secret.find((s) => s.key === secret))
      return (
        <Tooltip placement="top" title={'Secret available.'}>
          <Tag icon={<CheckCircleOutlined />} color="success">
            {secret}
          </Tag>
        </Tooltip>
      );
    return (
      <Tooltip placement="top" title={'Secret missing.'}>
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {secret}
        </Tag>
      </Tooltip>
    );
  }
};
