import { gql } from '@apollo/client';

export const userGroupsAll = gql`
  query {
    auth_user_groups {
      id
      name
      description
      users {
        user {
          id
          name
        }
      }
      permissions {
        permission {
          id
          name
          permission_type
          processes {
            process {
              id
              name
            }
          }
          projects {
            project {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default userGroupsAll;
