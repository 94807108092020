import { gql } from '@apollo/client';

const udmReferenceDelete = gql`
  mutation ($identifier: String!) {
    delete_udm_reference_by_pk(identifier: $identifier) {
      identifier
    }
  }
`;

export default udmReferenceDelete;
