import { useQuery, useSubscription } from '@apollo/client';
import { flowRunsLastByDateRange } from '../../../../common/graphql/subscriptions/flowRunsLastByDateRange';
import { flowRunsLastByDateUntilNow } from '../../../../common/graphql/subscriptions/flowRunsLastByDateUntilNow';

export const useRunsQuery = (dateStrings) => {
  const {
    data: queryData,
    error: queryError,
    loading: queryLoading,
  } = useQuery(flowRunsLastByDateRange, {
    variables: {
      from: dateStrings.from,
      to: dateStrings.to,
    },
    skip: !dateStrings.to,
  });

  const {
    data: subscriptionData,
    error: subscriptionError,
    loading: subscriptionLoading,
  } = useSubscription(flowRunsLastByDateUntilNow, {
    variables: {
      from: dateStrings.from,
    },
    skip: !!dateStrings.to,
  });

  const data = queryData || subscriptionData;
  const error = queryError || subscriptionError;
  const loading = queryLoading || subscriptionLoading;

  return { data, error, loading };
};