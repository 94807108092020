import { Tooltip } from 'antd';
import { theme } from 'config/js/theme.js';
import { InfoCircleOutlined } from '@ant-design/icons';

export const InfoTooltip = (props) => {
  return (
    <>
      {props.uiSchema['ui:description'] || props.schema?.description ? (
        <Tooltip
          color={theme['primary-color']}
          overlayClassName=""
          placement="topLeft"
          title={props.uiSchema['ui:description'] || props.schema.description}
          trigger="hover"
        >
          <InfoCircleOutlined
            style={{
              paddingRight: '5px',
              color: theme['primary-color-green-light'],
            }}
          />
        </Tooltip>
      ) : (
        <InfoCircleOutlined
          style={{ paddingRight: '5px', color: theme['primary-color-beige'] }}
        />
      )}
    </>
  );
};
