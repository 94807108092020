import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DocumentationPageBreadcrumb } from './DocumentationPageBreadcrumb';
import {
  DocumentationPage,
  useBaseUrl,
  HomeDocumentationPage,
  onUpdateSideNavigationState,
} from '@forrs-ems/doc-tool-components';
import { useSideNavigationKeysStore } from '../../../store/useSideNavigationKeysStore';

export const DocumentationPageContainer = () => {
  const baseUrl = useBaseUrl();
  const [localUrl, setLocalUrl] = useState();
  const history = useHistory();
  const { pathname } = useLocation();
  const { addOpenKeys, openKeys, setSelectedKey } = useSideNavigationKeysStore(
    ({ addOpenKeys, openKeys, setSelectedKey }) => ({
      addOpenKeys,
      openKeys,
      setSelectedKey,
    }),
  );

  useEffect(() => {
    if (baseUrl.includes('localhost')) {
      setLocalUrl('http://localhost:3002');
    } else {
      setLocalUrl(null);
    }
  }, [baseUrl]);
  // I presume that files have an extension, and directories do not.
  if (pathname.endsWith('/')) {
    return (
      <>
        <DocumentationPageBreadcrumb />
        <HomeDocumentationPage
          pathname={pathname}
          useQuery={useQuery}
          Link={Link}
          localUrl={localUrl}
          history={history}
          onClickAnchor={onUpdateSideNavigationState({
            openKeys,
            addOpenKeys,
            setSelectedKey,
            hasDocumentationLevel: true,
          })}
          baseUrl={baseUrl}
        />
      </>
    );
  }

  return (
    <>
      <DocumentationPageBreadcrumb />
      <DocumentationPage
        pathname={pathname}
        useQuery={useQuery}
        Link={Link}
        localUrl={localUrl}
        onClickAnchor={onUpdateSideNavigationState({
          openKeys,
          addOpenKeys,
          setSelectedKey,
          hasDocumentationLevel: true,
        })}
        baseUrl={baseUrl}
      />
    </>
  );
};