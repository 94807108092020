import { Button, Tooltip } from 'antd';

export const BasicTooltipButton = (props) => {
  const {
    tooltip,
    tooltipPlacement,
    tooltipMouseEnterDelay,
    children,
    ...btnProps
  } = props;

  if (tooltip) {
    return (
      <Tooltip
        placement={tooltipPlacement || 'top'}
        mouseEnterDelay={tooltipMouseEnterDelay || 0.1}
        title={tooltip}
      >
        <Button {...btnProps}>{children}</Button>
      </Tooltip>
    );
  }
  return <Button {...btnProps}>{children}</Button>;
};

export default BasicTooltipButton;
