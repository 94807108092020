import {useEffect, useState} from 'react';
import {Input, Row, Col, Tooltip, Select, DatePicker} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';

import BasicFieldLayout from '../Layout/BasicFieldLayout';
import dayjs from "dayjs";

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const {Option} = Select;

const formats = [
    'YYYY-MM-DDTHH:mm:ss[Z]',
    'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    'YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]',
    'YYYY-MM-DD',
];

const DateFixedRelativeField = (props) => {
    const {formData, schema} = props;
    const defaultDate = dayjs(formData, formats, true);
    const [isFixed, setIsFixed] = useState(formData && defaultDate.isValid());
    const [relativeValue, setRelativeValue] = useState(isFixed ? '' : formData);
    const [fixedValue, setFixedValue] = useState(
        defaultDate.isValid() ? defaultDate : dayjs(),
    );
    const fixedType = schema.anyOf[0]?.format
        ? schema.anyOf[0].format
        : schema.anyOf[1]?.format
            ? schema.anyOf[1].format
            : 'datetime';

    useEffect(() => {
        if (formData === undefined) {
            setIsFixed(true);
            setFixedValue(undefined);
            return;
        }
        const newDate = dayjs(formData, formats, true);
        const newInputType = formData && newDate.isValid();

        if (newInputType) {
            setIsFixed(true);
            setFixedValue(newDate);
        } else {
            setIsFixed(false);
            setRelativeValue(formData);
        }
    }, [formData]);

    const handleSelectionChange = (value) => {
        setIsFixed(value === 'fixed');
        if (value === 'fixed') {
            if (fixedValue === undefined) {
                props.onChange(fixedValue);
            } else {

                const newVal =
                    fixedType === 'date'
                        ? fixedValue.format('YYYY-MM-DD')
                        : fixedValue.toISOString();
                props.onChange(newVal);
            }
        } else {
            props.onChange(relativeValue);
        }
    };
    const handleRelativeChange = (e) => {
        try {
            setRelativeValue(e.target.value);
            props.onChange(e.target.value);
        } catch (error) {
            console.log(error);
        }
    };
    const handleFixedChange = (value) => {
        try {
            setFixedValue(value);
            if (!value) {
                props.onChange(undefined);
                return;
            }
            if (value?.isValid()) {
                const newVal =
                    fixedType === 'date'
                        ? value.format('YYYY-MM-DD')
                        : value.toISOString();
                props.onChange(newVal);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <BasicFieldLayout {...props}>
            <Row wrap={false}>
                <Col flex="125px">
                    <Select
                        style={{width: '120px'}}
                        onChange={handleSelectionChange}
                        disabled={props.disabled}
                        value={isFixed ? 'fixed' : 'relative'}
                    >
                        <Option value="relative">Relative</Option>
                        <Option value="fixed">
                            {fixedType === 'date' ? 'Date' : 'Date & Time'}
                        </Option>
                    </Select>
                </Col>
                <Col flex="auto">
                    {isFixed && fixedType === 'date-time' && (
                        <DatePicker
                            style={{width: '100%'}}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            disabled={props.disabled}
                            defaultValue={fixedValue}
                            onChange={handleFixedChange}
                        />
                    )}
                    {isFixed && fixedType === 'date' && (
                        <DatePicker
                            style={{width: '100%'}}
                            format="YYYY-MM-DD"
                            disabled={props.disabled}
                            defaultValue={fixedValue}
                            onChange={handleFixedChange}
                        />
                    )}
                    {!isFixed && (
                        <Input
                            addonAfter={
                                props.disabled ? (
                                    <QuestionCircleOutlined style={{color: 'lightgray'}}/>
                                ) : (
                                    <Tooltip
                                        placement="top"
                                        title="Enter an expression to describe a point of time relative to the model execution time, e.g. 'now', 'today at 6am', '2 days ago' etc."
                                    >
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                )
                            }
                            value={relativeValue}
                            onChange={handleRelativeChange}
                            disabled={props.disabled}
                        />
                    )}
                </Col>
            </Row>
        </BasicFieldLayout>
    );
};

export default DateFixedRelativeField;
