import { UDMPageDataManagementHeader } from './UDMPageDataManagementHeader';
import UDMReferenceEditModal from './UDMReferenceEditModal';
import { UDMPageTable } from './UDMPageTable';
import { useQuery } from '@apollo/client';
import udmReferencesAll from '../../../common/graphql/querys/udmReferencesAll';
import { useState } from 'react';

export const UDMPageDataManagementView = () => {
  const { loading, error, data, refetch } = useQuery(udmReferencesAll);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const references = data?.udm_reference;

  const handleChange = () => {
    refetch();
  };

  let transformed_data = [];
  if (references) transformed_data = references;

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  if (error) return <p>Error</p>;

  return (
    <>
      <UDMPageDataManagementHeader
        {...{
          openModal,
        }}
      />

      <UDMReferenceEditModal
        open={showModal}
        data={modalData}
        onDataChange={handleChange}
        onClose={closeModal}
        onCancel={closeModal}
      />

      <UDMPageTable
        {...{ error, transformed_data, loading, handleChange, openModal }}
      />
    </>
  );
};