import { gql } from '@apollo/client';

export const projectsAll = gql`
  query {
    project {
      id
      name
    }
  }
`;

export default projectsAll;
