import {
  CalculatorOutlined,
  CodeSandboxOutlined,
  DatabaseFilled,
  DatabaseOutlined,
  EditFilled,
  FundFilled,
  FundOutlined,
  FundProjectionScreenOutlined,
  InteractionOutlined,
  LockOutlined,
  ScheduleFilled,
  ScheduleOutlined,
  SettingFilled,
  SettingOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { prettyString } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import {Spin} from "antd";

const getMenuItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const setDashboardItems = (dashboards) => {
  return [
    ...new Set(
      dashboards.map((dashboard) =>
        getMenuItem(
          <Link to={'/dashboards' + dashboard.link}>
            {prettyString(dashboard.name)}
          </Link>,
          dashboard.link,
          null,
          undefined,
          'item',
        ),
      ),
    ),
  ];
};

const setDashboardMenu = (dashboards) => {
  // Dashboards Menu Items
  if (!dashboards) return null;

  const projectNames = [
    ...new Set(
      dashboards.map((d) => {
        return d.project.name;
      }),
    ),
  ];
  if (!projectNames) return null;

  return projectNames.map((projectName) => {
    return getMenuItem(
      prettyString(projectName),
      projectName,
      <FundOutlined />,
      setDashboardItems(
        dashboards.filter(
          (dashboard) => dashboard.project.name === projectName,
        ),
      ),
    );
  });
};

const setAdministrationProjects = (projects) => {
  if (!projects) return null;

  return projects.map((project) => {
    return getMenuItem(
      prettyString(project.name),
      project.name,
      <FundOutlined />,
      undefined,
      'item',
    );
  });
};

const hasPermission = (authPermissions, type) => {
  if (!authPermissions) return false;
  return authPermissions.some((e) => e.permission_type === type);
};

export const setNavigationItems = (dashboards, projects, authPermissions) => [
  getMenuItem('Operations', 'operations', <ScheduleFilled />, [
    getMenuItem(
      <Link to="/ops/runs/">Runs</Link>,
      'runs',
      <InteractionOutlined />,
      undefined,
      'item',
    ),
    getMenuItem(
      <Link to="/ops/executions">Executions</Link>,
      'executions',
      <ScheduleOutlined />,
      undefined,
      'item',
    ),
  ]),
  getMenuItem('Development', 'development', <EditFilled />, [
    getMenuItem(
      <Link to="/dev/models">Models</Link>,
      'models',
      <CalculatorOutlined />,
      undefined,
      'item',
    ),
  ]),
  getMenuItem(
    'Dashboards',
    'dashboards',
    <FundFilled />,
    setDashboardMenu(dashboards),
  ),
  getMenuItem('Data Management', 'data management', <DatabaseFilled />, [
    // getMenuItem(
    //   <Link to="/data/cdm">Calendar.DATA</Link>,
    //   'dcm',
    //   <DatabaseOutlined />,
    //   undefined,
    //   'item',
    // ),
    getMenuItem(
      <Link to="/data/udm">Unstructured.DATA</Link>,
      'udm',
      <DatabaseOutlined />,
      undefined,
      'item',
    ),
    getMenuItem(
      <Link to="/data/interface/mdm">MDM.DATA</Link>,
      'mdm',
      <DatabaseOutlined />,
      undefined,
    ),
  ]),
  getMenuItem('Administration', 'administration', <SettingFilled />, [
    false && getMenuItem(
      <Link to="/admin">General</Link>,
      'generaladmin',
      <SettingOutlined />,
      undefined,
      'item',
    ),
    false && projects &&
      getMenuItem(
        'Projects',
        'projects',
        <FundProjectionScreenOutlined />,
        setAdministrationProjects(projects),
        'item',
      ),
    false && hasPermission(authPermissions, 'ENV_ADMIN') &&
      getMenuItem(
        <Link to="/environments">Environments</Link>,
        'environments',
        <CodeSandboxOutlined />,
        undefined,
        'item',
      ),
    false && getMenuItem(
      <Link to="/secret">Secrets</Link>,
      'secrets',
      <LockOutlined />,
      undefined,
      'item',
    ),
    getMenuItem('Authorization', 'authorization', <UsergroupAddOutlined />, [
      getMenuItem(
        <Link to="/authoverview">Overview</Link>,
        'authoverview',
        undefined,
        undefined,
        'item',
      ),
      hasPermission(authPermissions, 'USER_ADMIN') &&
        getMenuItem(
          <Link to="/usermanagement">User Management</Link>,
          'usermanagement',
          undefined,
          undefined,
          'item',
        ),
    ]),
    // TODO continue with the Documentation
  ]),
];

export const setMenuItemSpinner = () => {
  return {
    key: 'loading',
    label: (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
    ),
  };
};