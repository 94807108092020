import { TaskInterfaceLoad } from './TaskInterfaceLoad';
import { TaskInterfaceStore } from './TaskInterfaceStore';
import { Divider } from 'antd';
import '../less/TaskInterfaceContainer.less'

export const RunLogsTaskRunInterfaceContainer = ({ taskRunInterface }) => {
  if (!taskRunInterface) return null;

  return (
    <>
      {taskRunInterface?.map(
        ({ request_type, interface_name, data_info }, index, array) => {
          const interfaceInfo = interface_name.split('.');
          const interfaceName = interfaceInfo[0];
          const interfaceFunction = interfaceInfo[1];
          const data = JSON.parse(data_info);
          return (
            <div key={interface_name}>
              <div
                className="taskRunInterfaceContainer"
                style={{
                  padding: '2em 5em',
                  backgroundColor: '#EEE',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                {request_type === 'load' ? (
                  <TaskInterfaceLoad
                    {...{ interfaceName, data, interfaceFunction }}
                  />
                ) : (
                  <TaskInterfaceStore
                    {...{ interfaceName, data, interfaceFunction }}
                  />
                )}
              </div>
              {index !== array.length - 1 && (
                <Divider style={{ margin: 0, backgroundColor: '#EEE' }} />
              )}
            </div>
          );
        },
      )}
    </>
  );
};
