import {Link, useParams} from 'react-router-dom';
import {Markdown} from '@forrs-ems/doc-tool-components';
import {Card, Col} from 'antd';

import {useEffect, useState} from 'react';
import {DataInterfacePageHeader} from './DataInterfaceHeader/DataInterfacePageHeader';
import {setDataInterfaceInfo} from './utils/utils';
import {SecretManagement} from './SecretManagement/SecretManagement';
import {DataInterfaceGrid} from './DataInterfaceGrid';
import {DataInterfaceUsage} from './DataInterfaceUsage/DataInterfaceUsage';
import { HashLink } from 'react-router-hash-link';
import './DataInterfacePage.css';

const dataInterfaceInfoMDM = {
    'System Type': 'Structured Data Management',
    Connector: 'Rest API',
    'Data Entities': ['External Marketdata', 'Internal Derived Data'],
    'Data Formats': ['Timeseries (Dataframe)', 'Curves (Dataframe)'],
    Capabilities: 'Versioning/Audit',
};
const dataInterfaceInfoUDM = {
    'System Type': 'Unstructured Data Management',
    Connector: 'GRYT UDM Python Client',
    'Data Entities': ['CSV Data', 'Internal Derived Data', 'Calculation Result'],
    'Data Formats': ['JSON', 'BLOB'],
    Capabilities: 'Versioning/Audit',
};

const Hr = () => <hr style="{boder: 0}"/>

const mockFetchingData = async (interfaceName) => {
    const lookup = {
        mdm: {
            url: {value: 'https://mdm-test.gryt.cloud/', isInternal: false},
            dataInterfaceInfo: dataInterfaceInfoMDM,
            description: `## GRYT MDM.Data

GRYT MDM.Data is a market data management system that stores time-series data from various data sources. 
It provides the users with a user interface for managing, analyzing, and processing data.

Time series data is stored in MDM.Data in two different data formats:

| Data type | Description |
| :------ | :------ |
| __*TimeSeries*__ | A *TimeSeries* is a dynamic list of 'flat' time-stamped data points defined by <ul><li>a unique system identifier</li><li>static data-attributes, such as name, source, currency, etc. that describe the collective TimeSeries object (all data points)</li><li>a collection of columns that describe each data point such as <ul><li>TimeStamp</li><li>Begin / End date of the delivery period</li><li>Data value</li></ul></li></ul> In other words, *TimeSeries* are typically used to store data that has a single value for each delivery period (specified by the "Begin" and "End" columns) that is written at a specified timestamp. An example would be data for historical power prices that don't change after settled.|
| __*Curve*__ | A *Curve* is a 2-dimensional, time-stamped collection of TimeSeries objects that are defined by <ul><li>a unique system identifier</li><li>static data-attributes, such as name, source, currency, etc.</li><li>a TimeStamp, e.g. the time of data creation</li><li>a collection of columns that describe each data point such as <ul><li>Begin / End date of the delivery period</li><li>Data value</li></ul></li></ul> In other words, *Curves* can store time-series data for a identical delivery periods for a any number of different timestamps. A typical example would be a price forward curve that can be updated several times a day.|

<br>  
 
[MDM.Data documentation](https://mdmdoc-test.gryt.cloud/)
`,
            documentation: `#### Data Access using the MDM.Data Python Client
The MDM.Data Python client for data access provides the following methods to load and store data:

- **load_timeseries_df**

  This method is used to receive the data of one or many MDM.Data timeseries identifier(s) withing a given timestamp range. There are several option available, e.g. to only load the last timestamp for each delivery period (\`LASTVALUEOFPRODUCT\`) or for data conversion to a required delivery period granularity (\`DELIVERYTYPECONVERSIONHOUR, DELIVERYTYPECONVERSIONDAY, DELIVERYTYPECONVERSIONMONTH\`).


- **load_curve_df**

  This method is used to receive the data of one or many MDM.Data curve identifier(s) withing a given timestamp range. There are several option available, e.g. to only load the last timestamp within the given period (\`LASTVALUE\`), the latest available timestamp overall (\`LASTVALUEOFDATA\`) or for data conversion to a required delivery period granularity (\`DELIVERYTYPECONVERSIONHOUR, DELIVERYTYPECONVERSIONDAY, DELIVERYTYPECONVERSIONMONTH\`).


- **store_timeseries_df**

  This method is used to upload new timeseries data for one or many MDM.Data curve identifier(s). The new data must be given as a Pandas DataFrame with the columns: Identifier, TimeStamp, Begin, Value (if required, substitute 'Value' with the actual column names in MDM for the used identifier).
    

- **store_curve_df**

  This method is used to upload new curve data for one or many MDM.Data curve identifier(s). The new data must be given as a Pandas DataFrame with the columns: Identifier, TimeStamp, Begin, Value (if required, substitute 'Value' with the actual column names in MDM for the used identifier).

  
##### Example

~~~~python
    client = MdmClient()

    # Fetch the latest data timestamp of a curve
    data_curve = client.load_curve_df(identifiers=['SOME_MDM_CURVE_ID'],
                                   begin_ts=datetime.now(),
                                   end_ts=datetime.now(),
                                   options=['LASTVALUEOFDATA'])
    
    # Fetch all data of a timeseries in January, 2023 as daily averages
    data_ts = client.load_timestamp_range(identifiers=['SOME_MDM_TIMESERIES_ID'], 
                                          begin_ts=datetime(2023, 1, 1),
                                          end_ts=datetime(2023, 2, 1),
                                          options=['DELIVERYTYPECONVERSIONDAY']) 
    
    # Store new data 
    # where 'new_data' is a Pandas DataFrame with the columns: Identifier, TimeStamp, Begin, Value
    client.store_timeseries_df(new_data)
~~~~
`,
        },
        udm: {
            url: {value: '/data/udm', isInternal: true},
            dataInterfaceInfo: dataInterfaceInfoUDM,
            description: `## Unstructured.DATA

Unstructured data refers to information that does not follow a predetermined data model or schema, 
and that cannot be stored in a conventional relational database. The **Unstructured.DATA** system can
store information in JSON format or 'binary large objects' (BLOB) of any format, e.g. DataFrame objects in
CSV or parquet formats.

Data is referenced first by name (identifier) and second by timestamp, meaning for each identifier a various number
of data can be stored for different times.

Each data reference is further characterized by:

| Parameter | Description |
| :------ | :------ |
| __*Identifier*__ | Unique identifier per each reference of unstructured data. |
| __*Domain*__ | Broad topic or category that the data falls under (e.g., "Power").|
| __*SubDomain*__ | More specific category within a domain (e.g., "Valuation Price").|
| __*Data Type*__ | This distinguishes between **JSON** and **BLOB** type data. JSON is used to store simple data structures and objects in JavaScript Object Notation (JSON) format. BLOB (Binary Large Object) data are used for large amounts of binary data, such as csv, xml or xlsx files. |
| __*Meta Data*__ | Attributes that can be added by the user. |
`,
            documentation: `#### Data Access using the Unstructured.DATA Python Client
There are two Python client for Unstructured.Data access: \`UDMJsonClient\` and \`UDMBlobClient\`. Which client to use depends on the type of data you want to access (JSON or BLOB data). The Unstructured.DATA Python Clients provides the following methods to load and store data:

- **load_timestamp_range**

  This method is used to receive the data one Unstructured.DATA reference withing a given timestamp range. Optionally, only the last timestamp within the given range can be requested.


- **load_latest_timestamp**

  This method is used to receive the latest available timestamp data of one Unstructured.DATA reference.


- **store_blob_data** (\`UDMBlobClient\` only)

  This method is used to upload new BLOB data for one Unstructured.DATA reference. The data will be stored using the given timestamp or use the current time if no timestamp is explicitly given. 
    

- **store_json_data**

  This method is used to upload new JSON data for one Unstructured.DATA reference. The data will be stored using the given timestamp or use the current time if no timestamp is explicitly given. 


- **load_reference**

  This Method loads the description of one Unstructured.DATA reference identified by its name. It returns a dictionary consisting of identifier, domain, sub domain, data type and meta data. 


- **load_all_references**
  
  This Method loads the description of all Unstructured.DATA references. It returns a list of dictionaries, each entry consisting of identifier, domain, sub domain, data type and meta data. 


- **create_udm_reference**

  This Method allows for the creation of a new data reference.
  
  
##### Example

~~~~python
    client = UDMJsonClient()

    # Fetch the latest data point 
    data = client.load_latest_timestamp(identifier='SOME_ID')
    
    # Fetch all data in January, 2023
    data2 = client.load_timestamp_range(identifier='SOME_ID', 
                                        ts_start=datetime(2023, 1, 1),
                                        ts_end=datetime(2023, 2, 1)) 
    
    # Store new data for the current time
    client.store_json_data(identifier='SOME_ID', timestamp=datetime.now(),
                           data={"Munich": "11.5°C", "Rome": "20.3°C", "New York": "16°C", "Singapore": "31°C"})
~~~~
`,
        },
    };
    return lookup[interfaceName];
};

export const DataInterfacePage = () => {
    const [openDataInterfaceForm, setOpenDataInterfaceForm] = useState(false);
    // TODO Final, I should be fetching the information from the backend.
    //  Think of it as a Post the passes its id as a parameter and returns the information
    //  params: url

    const {id: interfaceName} = useParams();
    const [data, setData] = useState({});

    // TODO Final use useQuery
    useEffect(() => {
        mockFetchingData(interfaceName).then((data) => setData(data));
    }, [interfaceName]);

    if (!data) return null;

    return (
        <div id="dashboards-page">
            <DataInterfacePageHeader
                {...{
                    interfaceName,
                    url: data.url,
                    setOpenDataInterfaceForm,
                    openDataInterfaceForm,
                }}
            />
            <DataInterfaceGrid>
                <Markdown Link={Link} HashLink={HashLink}>{data.description}</Markdown>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12} key={1}
                     style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    <Card
                        style={{flex: 1}}>{data.dataInterfaceInfo && Object.keys(data.dataInterfaceInfo).map((infoKey) => {
                        return (
                            <div
                                key={infoKey}
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                <span style={{width: '130px', margin: '3px', fontSize: '1.2em'}}>{infoKey}:</span>
                                <div style={{display: 'flex'}}>
                                    {setDataInterfaceInfo(data.dataInterfaceInfo, infoKey)}
                                </div>
                            </div>
                        );
                    })}
                    </Card>
                    <Card style={{flex: 2}}>
                        <SecretManagement interfaceName={interfaceName}/>
                    </Card>
                </Col>
                <DataInterfaceUsage {...{interfaceName}} />
                <Markdown Link={Link} HashLink={HashLink}>{data.documentation}</Markdown>
            </DataInterfaceGrid>
        </div>
    );
};

export default DataInterfacePage;
