import Plot from 'react-plotly.js';
import { setAggregateData } from './utils/utils';

export const DataInterfaceDataVolumeAndRequestsPlot = ({
  dataSource,
  bucket,
}) => {
  const aggregatedData = setAggregateData(dataSource, bucket);

  return (
    <Plot
      style={{ width: '100%', height: '250px' }}
      data={[
        {
          type: 'scatter',
          x: aggregatedData.loadTimestamps,
          y: aggregatedData.loadDataVolume,
          yaxis: 'y2',
          marker: { color: '#336D62' },
          name: 'Load Data Volume',
        },
        {
          type: 'scatter',
          yaxis: 'y2',
          x: aggregatedData.storeTimestamps,
          y: aggregatedData.storeDataVolume,
          marker: { color: '#1bb470' },
          name: 'Store Data Volume',
        },
        {
          x: aggregatedData.loadTimestamps,
          y: aggregatedData.loadRequestCount,
          type: 'bar',
          mode: bucket === 'monthly' ? 'lines+markers' : 'lines',
          marker: { color: '#003A2F' },
          name: 'Load Requests',
        },
        {
          x: aggregatedData.storeTimestamps,
          y: aggregatedData.storeRequestCount,
          type: 'bar',
          mode: bucket === 'monthly' ? 'lines+markers' : 'lines',
          // fill: 'tozeroy',
          marker: { color: '#8BCBB7' },
          name: 'Store Requests',
        },
      ]}
      layout={{
        yaxis: {
          title: 'Nr of Requests',
        },
        yaxis2: {
          title: 'Data Volume (kb)',
          side: 'right',
          overlaying: 'y',
        },
        xaxis:
          bucket === 'monthly'
            ? {
                tickformat: '%Y-%m',
                dtick: 'M1', // 1 month interval
              }
            : {},
        legend: {
          // Add a legend property to the layout object
          x: 0.5, // Set the x position of the legend
          y: 1.2, // Set the y position of the legend
          xanchor: 'center', // Set the x-anchor of the legend
          yanchor: 'top', // Set the y-anchor of the legend
          orientation: 'h', // Set the orientation (vertical or horizontal)
        },
        margin: {
          l: 45,
          r: 50,
          b: 80,
          t: 10,
          pad: 0,
        },
      }}
    />
  );
};
