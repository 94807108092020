import { Button, Flex, Typography } from 'antd';
import { GitlabOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const ModelTitle = ({level, gitlink, name}) => {



  const openGitLink = () => {
    window.open(gitlink, '_blank');
  };

  return (
    <Flex gap={'middle'} align={'flex-start'} justify={'flex-start'}>
    <Title level={level || 3} >
      Model: {name}</Title>
      {gitlink && (
        <Button
          type="primary"
          size="small"
          style={{ marginLeft: '10px' }}
          icon={<GitlabOutlined />}
          onClick={openGitLink}
        >
          Source
        </Button>
      )}
    </Flex>
  );
};

export default ModelTitle;
