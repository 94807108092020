import {gql} from '@apollo/client';

export const runStop = gql`
  mutation CancelRun ($flow_run_id: uuid!) {
    cancel_run(flow_run_id: $flow_run_id) {
      flow_run_id
      status
      details
    } 
}     
`;

