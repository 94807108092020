import { createContext, useState } from 'react';

export const ParameterDefinitionsContext = createContext(null);

export const ModelParameterDefinitionsProvider = ({ children }) => {
  const [definitions, setDefinitions] = useState({});

  return (
    <ParameterDefinitionsContext.Provider
      value={{ definitions, setDefinitions }}
    >
      {children}
    </ParameterDefinitionsContext.Provider>
  );
};