import { useState } from 'react';
import { Handle } from 'react-flow-renderer/nocss';
import { Col, Row, Typography } from 'antd';

import { theme } from 'config/js/theme.js';
import './cronnode.css';
// import CronEditModal from "components/shared/CronEditModal";

const { Text } = Typography;

const defaultStyles = {
  backgroundColor: theme['primary-color-beige-dark'],
  color: '#ffffff',
  cursor: 'auto',
};

const EventTriggerNode = (node) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const style = defaultStyles;

  const handleClick = (e) => {
    setIsModalVisible(true);
  };

  const closeModal = (e) => {
    setIsModalVisible(false);
  };

  return (
    <div className="grytnode_wrapper" style={{ backgroundColor: '00f' }}>
      {node.selected && <div className="grytnode_selected"></div>}

      <div className="grytnode" style={style} onClick={handleClick}>
        <Row wrap={false} style={{ height: '100%' }}>
          <Col flex="auto" className="grytnode_main">
            <div>
              <Text strong style={{ color: 'white' }}>
                Event
              </Text>
            </div>
            {/* <div><Text style={{color: 'black'}}>{node.data.eventTrigger.id}</Text></div> */}
          </Col>
        </Row>

        {node.data.loading && <div className="grytnode_loading"></div>}

        {node.data._io_type !== 'input' &&
          node.data._io_type !== 'standalone' && (
            <Handle type="target" position="left" style={{ borderRadius: 0 }} />
          )}
        {node.data._io_type !== 'output' &&
          node.data._io_type !== 'standalone' && (
            <Handle
              type="source"
              position="right"
              id={node.id}
              style={{ borderRadius: 0 }}
            />
          )}
      </div>

      {/* <CronEditModal cron={node.data.cron} visible={isModalVisible} onCancel={closeModal} onClose={closeModal} destroyOnClose /> */}
    </div>
  );
};

export default EventTriggerNode;
