import { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { prettifyJson } from 'utils/utils';

const { TextArea } = Input;

export const JsonViewButton = (props) => {
  const { jsonData, title, subtitle, ...btnProps } = props;
  const [showModal, setShowModal] = useState(false);

  const prettyJson = prettifyJson(jsonData);

  return (<>
    <Button
      icon={<EyeOutlined />}
      shape="circle"
      type={'primary'}
      {...btnProps}
      onClick={() => setShowModal(true)}
    />
    <Modal
      title={title}
      open={showModal}
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
    >
      {subtitle && { subtitle }}

      <TextArea rows={12} value={prettyJson} onChange={() => null} />
    </Modal>
  </>);
};

export default JsonViewButton;
