import { useState } from 'react';
import { Tag, Popconfirm, message } from 'antd';
import { useApolloClient } from '@apollo/client';

import alertDelete from 'common/graphql/mutations/alertDeleteById';
import { AlertOutlined, SyncOutlined } from '@ant-design/icons';
import { useUserStore } from 'contexts/User';

export const AlertDeleteTag = (props) => {
  const client = useApolloClient();
  const [state, dispatch] = useUserStore();
  const { alert, ...tagProps } = props;
  const [loading, setLoading] = useState(false);

  const tagColor = () => {
    if (alert.type === 'onFailed') return 'error';
    if (alert.type === 'onSuccess') return 'success';
    return 'default';
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    if (!props.alert) return console.log('No alert parameter');

    setLoading(true);
    client
      .mutate({ mutation: alertDelete, variables: { id: alert.id } })
      .then((response) => {
        message.success({ content: 'Successfully deleted Alert' });
        setLoading(false);
        dispatch({
          type: 'DELETE_USER_ALERT',
          payload: response.data.delete_alert_by_pk,
        });
      })
      .catch((error) => {
        message.error({
          content: 'Error while trying to delete Alert:' + error,
        });
        setLoading(false);
      });
  };

  return (
    <Popconfirm
      placement="top"
      title={'Do you want to delete this Alert?'}
      onConfirm={handleDelete}
      onCancel={(e) => e.stopPropagation()}
      okText="Yes"
      cancelText="No"
    >
      <Tag
        {...tagProps}
        color={tagColor()}
        icon={loading ? <SyncOutlined spin /> : <AlertOutlined />}
        onClick={(e) => e.stopPropagation()}
      >
        {alert.type}
      </Tag>
    </Popconfirm>
  );
};

export default AlertDeleteTag;
