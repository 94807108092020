import { gql } from '@apollo/client';

const userGroupEdit = gql`
  mutation (
    $id: Int!
    $name: String!
    $description: String
    $permissions_added: [auth_m2m_user_group__permission_insert_input!]! = []
    $users_added: [auth_m2m_user__user_groups_insert_input!]! = []
    $permissions_removed: [Int!]! = []
    $users_removed: [uuid!]! = []
  ) {
    update_auth_user_groups_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description }
    ) {
      id
    }
    insert_auth_m2m_user_group__permission(objects: $permissions_added) {
      affected_rows
    }
    insert_auth_m2m_user__user_groups(objects: $users_added) {
      affected_rows
    }
    delete_auth_m2m_user_group__permission(
      where: {
        _and: {
          user_group_id: { _eq: $id }
          permission_id: { _in: $permissions_removed }
        }
      }
    ) {
      affected_rows
    }
    delete_auth_m2m_user__user_groups(
      where: {
        _and: { user_group_id: { _eq: $id }, user_id: { _in: $users_removed } }
      }
    ) {
      affected_rows
    }
  }
`;

export default userGroupEdit;
