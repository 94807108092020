import { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import userInsertUpdate from 'common/graphql/mutations/userInsertUpdate';
import AuthLoadingPage from 'components/pages/AuthLoadingPage';
import { useAuthStore } from '../../store/useAuthStore';

const XAuthProvider = ({ children }) => {
  const client = useApolloClient();
  const { authData, userData, updateUserData } = useAuthStore((state) => ({
    authData: state.authData,
    userData: state.userData,
    updateUserData: state.updateUserData,
  }));

  useEffect(() => {
    if (authData) {
      if (authData?.idTokenClaims) {
        client
          .mutate({
            mutation: userInsertUpdate,
            variables: {
              id: authData.idTokenClaims.oid,
              email: authData.idTokenClaims.email,
              name: authData.idTokenClaims.name,
            },
          })
          .then((response) => {
            if (response) updateUserData(response.data.insert_auth_user_one);
          })
          .catch((e) => console.log(e));
      }
    }
  }, [authData]);

  if (!userData) return <AuthLoadingPage />;
  return children;
};

export default XAuthProvider;
