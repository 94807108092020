import { Collapse, Typography } from 'antd';

import { Markdown } from '@forrs-ems/doc-tool-components';
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const { Text } = Typography;
const { Panel } = Collapse;

export const ModelInfoCollapseDescription = (props) => {
  const { description } = props;

  return (
    <Collapse ghost defaultActiveKey="">
      <Panel header={<Text>Description:</Text>} key={'description'}>
        <Markdown Link={Link} HashLink={HashLink}>{description}</Markdown>
      </Panel>
    </Collapse>
  );
};

export default ModelInfoCollapseDescription;
