import { gql } from '@apollo/client';

const userGroupCreate = gql`
  mutation (
    $name: String!
    $description: String
    $permissions: [auth_m2m_user_group__permission_insert_input!]!
    $users: [auth_m2m_user__user_groups_insert_input!]!
  ) {
    insert_auth_user_groups_one(
      object: {
        name: $name
        description: $description
        permissions: { data: $permissions }
        users: { data: $users }
      }
    ) {
      id
    }
  }
`;

export default userGroupCreate;
