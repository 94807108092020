export const msalConfig = {
  auth: {
    clientId: window._env_.REACT_APP_AZURE_AD_APP_ID,
    authority: window._env_.REACT_APP_AZURE_AD_AUTHORITY,
    redirectUri: window._env_.REACT_APP_AZURE_AD_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
  // scopes: ["profile", window._env_.REACT_APP_AZURE_AD_APP_ID + "/User.Read"],
};

export const accessTokenRequest = {
  scopes: [
    'profile',
    'openid',
    window._env_.REACT_APP_AZURE_AD_APP_ID + '/User.Read',
  ],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphMePhotoEndPoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};
