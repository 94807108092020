import { gql } from '@apollo/client';

const cdmReferenceDelete = gql`
  mutation ($identifier: String!) {
    delete_cdm_reference_by_pk(identifier: $identifier) {
      identifier
    }
  }
`;

export default cdmReferenceDelete;
