import { gql } from '@apollo/client';

const actionLoadBlobUrl = gql`
  mutation ($blob: String!, $blob_container: String!) {
    load_blob_url(blob: $blob, blob_container: $blob_container) {
      blob_url_sas
    }
  }
`;

export default actionLoadBlobUrl;
