import ExeqtMultiSelectEditField from './FormComponents/ExeqtMultiSelectEditField';
import ExeqtObjectField from './FormComponents/ExeqtObjectField/ExeqtObjectField';
import ExeqtJsonEditField from './FormComponents/ExeqtJsonEditField';
import ExeqtArrayField from './FormComponents/ExeqtArrayField';
import ExeqtTimezoneEditField from './FormComponents/ExeqtTimezoneEditField';
import ExeqtDateFixedRelativeField from './FormComponents/ExeqtDateFixedRelativeField';
import {ExeqtUploadCSVFile} from './FormComponents/ExeqtUploadCSVFile';
import ExeqtField from './FormComponents/ExeqtField';
import {
    extractRefValues, hasNestedRef,
    hasRef, isArrayType, isDataUrlString,
    isDateFixedRelative,
    isMultiSelectArray,
    isObjectType,
    isRegularArray,
    isTimezoneString, isUndefinedType
} from "./utils";

function uiSchemaBuilder(schemaObj, readOnly, definitions, nestedLevel = 0) {
    const uiSchema = {
        'ui:disabled': readOnly,
        'ui:title': false,
    };


    if (isUndefinedType(schemaObj)) return uiSchema; // Return empty uiSchema if the schema is undefined
    if (isObjectType(schemaObj) || hasRef(schemaObj)) {
        assignObjectProperties(
            uiSchema,
            schemaObj,
            readOnly,
            definitions,
            nestedLevel,
        );
    } else {
        assignOtherProperties(uiSchema, schemaObj, readOnly, definitions, nestedLevel);
    }

    return uiSchema;
}


function assignObjectProperties(
    uiSchema,
    schemaObj,
    readOnly,
    definitions,
    nestedLevel,
) {
    if (schemaObj.properties) {
        // Create an ordered list of property names based on the 'position' property
        const orderedPropertyNames = Object.entries(schemaObj.properties)
            .sort(([, valueA], [, valueB]) => valueA.position - valueB.position)
            .map(([key]) => key);

        // Assign the ordered list to the ui:order property in the uiSchema
        uiSchema['ui:order'] = orderedPropertyNames;

        orderedPropertyNames.forEach((key) => {
            const value = schemaObj.properties[key];
            if (nestedLevel > 0) {
                uiSchema['ui:FieldTemplate'] = ExeqtObjectField;
            }
            uiSchema[key] = processSchemaValue(
                value,
                readOnly,
                definitions,
                nestedLevel,
            );
        });
    } else if (schemaObj?.anyOf?.length > 0) {
        uiSchema['ui:FieldTemplate'] = ExeqtObjectField;
        // uiSchema['ui:options'] = {
        //     'enumOptions': schemaObj.anyOf.map(item => {
        //         const definitionName = item.$ref.split('/').pop();
        //         return {
        //             value: definitionName,
        //             label: definitions[definitionName].title || definitionName
        //         };
        //     })
        // };

    } else {
        uiSchema['ui:FieldTemplate'] = ExeqtJsonEditField;
    }
}


function processSchemaValue(value, readOnly, definitions, nestedLevel) {
    // Extract reference value from the input
    const refValues = hasRef(value) ? extractRefValues(value) : null;

    // Call uiSchemaBuilder directly if no reference is found

    if (!refValues) return uiSchemaBuilder(value, readOnly, definitions, nestedLevel + 1);
    // Case: Construct Array and re run uiSchemaBuilder
    if (Array.isArray(refValues)) {
        // Construct an array schema where each element in `items` is a different schema
        const itemSchemas = refValues.map(refValue => {
            // case: anyOf contains a null type
            if (refValue?.type === 'null') return null;
            if (refValue?.type === 'array' && refValue?.items?.anyOf) {
                return refValue.items.anyOf
            }

            if (refValue?.$ref) {
                const definitionName = refValue.$ref.split('/').pop();
                // Return a schema object that references the definition
                return {"$ref": `#/definitions/${definitionName}`};
            }

            return [];
        }).filter(item => item !== null).flat();

        const arraySchema = {
            "type": "array",
            "items": itemSchemas,
            "title": value.title,
            "position": value.position,
            "description": value.description
        };

        return uiSchemaBuilder(arraySchema, readOnly, definitions, nestedLevel + 1);
    }
    // Build new object if a reference is found
    const definitionName = refValues.split('/').pop();
    const definition = definitions[definitionName];
    const newObj = {...definition, default: value.default};
    return uiSchemaBuilder(newObj, readOnly, definitions, nestedLevel + 1);
}

function assignOtherProperties(uiSchema, schemaObj, readOnly, definitions, nestedLevel) {
    if (isMultiSelectArray(schemaObj)) {
        uiSchema['ui:FieldTemplate'] = ExeqtMultiSelectEditField;
    } else if (isRegularArray(schemaObj)) {
        uiSchema['ui:ArrayFieldTemplate'] = ExeqtArrayField;

        if (schemaObj.items.$ref) {
            // If items is a single schema referenced by $ref
            const definitionName = schemaObj.items.$ref.split('/').pop();
            const definition = definitions[definitionName];
            const uiItemsSchemas = uiSchemaBuilder(definition, readOnly, definitions, nestedLevel + 1);
            uiSchema.items = uiItemsSchemas;
        } else if (isRegularArray(schemaObj)) {
            // Custom logic to select the schema for each array item
            uiSchema['ui:FieldTemplate'] = ExeqtObjectField;

            if (Array.isArray(schemaObj.items)) {
                // Create a placeholder for items that allows the user to select the schema for each item
                // uiSchema.items = {
                //     'ui:widget': 'select',
                //     'ui:options': {
                //         'enumOptions': schemaObj.items.map(item => {
                //             const definitionName = item.$ref.split('/').pop();
                //             return {
                //                 value: definitionName,
                //                 label: definitions[definitionName].title || definitionName
                //             };
                //         })
                //     }
                // };
                // schemaObj.items.forEach((item, index) => {
                //     const definitionName = item.$ref.split('/').pop();
                //     const definition = definitions[definitionName];
                //     return uiSchemaBuilder(definition, readOnly, definitions, nestedLevel + 1);
                // })

            } else {
                // Handle the case where there's a single schema for all items
                uiSchema.items = uiSchemaBuilder(schemaObj.items, readOnly, definitions, nestedLevel + 1);
            }
        }
    } else if (isTimezoneString(schemaObj)) {
        uiSchema['ui:FieldTemplate'] = ExeqtTimezoneEditField;
    } else if (isDateFixedRelative(schemaObj)) {
        uiSchema['ui:FieldTemplate'] = ExeqtDateFixedRelativeField;
    } else if (isDataUrlString(schemaObj)) {
        uiSchema['ui:FieldTemplate'] = ExeqtUploadCSVFile;
    } else {
        uiSchema['ui:FieldTemplate'] = ExeqtField;
    }
}


export default uiSchemaBuilder;

