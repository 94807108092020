import { useState } from 'react';

import { Button, Collapse, Form, message, Popconfirm, Space, Typography } from 'antd';
import { DeleteOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';

import { useApolloClient, useQuery } from '@apollo/client';
import processExecutionsSimple from 'common/graphql/querys/processExecutionsSimple';
import ExecutionSettingsForm from 'components/shared/ExecutionEditModal/ExecutionSettingsForm';
import { ParameterEditForm } from '../ParameterEditForm';
import { actionExecutionUpdate } from '../../../common/graphql/mutations/actionExecutionUpdate';
import { actionExecutionDelete } from '../../../common/graphql/mutations/actionExecutionDelete';

const { Title } = Typography;
const { Panel } = Collapse;


const executionTriggerSpecificLookup = {
  CRON: (execution) => {
    return {
      cron: execution.cron__expression,
      timezone: execution.cron__timezone,
      //calendar_id: execution?.cron__calendar_id,
    };
  },
  CHAIN: (execution, executionId, process) => {
    const chains = execution.chain__upstream_executions.map((executionName) => {
      const _execution = process.executions.find(
        (e) => e.name === executionName,
      );
      return { upstream_id: _execution.id, downstream_id: executionId };
    });
    return {chains: chains};
  },
  API: (execution) => {
    return {};
  },
  EVENT: (execution) => {
    return {};
  },
  // TODO Check if we will use the INTERVAL case
  INTERVAL: (execution) => {
    return {
      interval: execution.interval,
      anchor_date: execution.anchor_date,
      timezone: execution.timezone,
    };
  },
};


export const ExecutionEditForm = (props) => {
  const { execution, create, model } = props;
  const edit = !create;

  const client = useApolloClient();
  const processesQuery = useQuery(processExecutionsSimple);
  if (processesQuery.error) console.log(processesQuery.error);

  const [executionSettingForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const onFinish = async (values) => {

    const processesSelect = values.process;
    const process = processesQuery.data.process.find(
      (process) => process.id === processesSelect,
    );
    if (!process) {
      console.log('ERROR');
      return;
    }

    const oldTriggerType = execution.trigger_type;
    const newTriggerType = values.trigger_type;
    const schedule = executionTriggerSpecificLookup[newTriggerType]
      ? executionTriggerSpecificLookup[newTriggerType](values, execution.id, process)
      : undefined;
    if (!schedule) {
      return;
    }
    const mutation = actionExecutionUpdate;
    const variables = {
      model_name: execution.model.name,
      execution_id: execution.id,
      project_name: execution.model.project.name,
      schedule,
      process_id: process.id,
      deployment_id: execution.deployment_id,
      execution_name: values.executionName,
      description: values.description,
      new_trigger_type: newTriggerType,
      old_trigger_type: oldTriggerType,
    };

    setIsLoading(true);
    try {
      const result = await client.mutate({ mutation, variables });
      if (result?.data?.execution_update?.status !== '200') {
        throw new Error('Error while trying to update execution: ' + result?.data?.execution_update?.details?.message);
      }
      message.success({ content: 'Successfully updated Execution' });
      setFormChanged(false);
      if (props.onChange) props.onChange();
      processesQuery.refetch().then();
    }
    catch (error) {
      message.error({
        content: 'Error while trying to update Execution: ' + error,
      });
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleFormChange = (formName, { values, forms }) => {
    setFormChanged(true);
  };

  const handleFormReset = () => {
    executionSettingForm.resetFields();
    setFormChanged(false);
  };

  const handleDelete = async () => {
    setIsDeleteLoading(true);
    try {
      const result = await client.mutate({
        mutation: actionExecutionDelete,
        variables: {
          deployment_id: execution.deployment_id,
          model_name: execution.model.name,
          project_name: execution.model.project.name,
        },
      });
      if (result?.data?.execution_delete?.status !== '200') {
        throw new Error('Error while trying to delete execution: ' + result?.data?.execution_delete?.details?.message);
      }
      message.success({ content: 'Successfully deleted Execution' });
      processesQuery.refetch().then();
    }
    catch (error) {
      message.error({
        content: 'Error while trying to delete Execution: ' + error,
      });
    }
    finally {
      setIsDeleteLoading(false);
    }
  };

  const formJSX = (
    <>
      <ExecutionSettingsForm
        execution={edit ? execution : false}
        form={executionSettingForm}
        handleFormChange={handleFormChange}
        onFinish={onFinish}
      />

      <Space>
        <Button
          icon={<SaveOutlined />}
          loading={isLoading}
          type="primary"
          disabled={!formChanged}
          onClick={() => executionSettingForm.submit()}
        >
          {create ? 'Create' : 'Update'} Execution
        </Button>
        {!create && (
          <Button
            icon={<UndoOutlined />}
            onClick={handleFormReset}
            disabled={!formChanged}
          >
            Reset Fields
          </Button>
        )}
        {!create && (
          <Popconfirm
            placement="top"
            title={'Are you sure you want to Delete the Execution?'}
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} loading={isDeleteLoading}>
              Delete Execution
            </Button>
          </Popconfirm>
        )}
      </Space>
    </>
  );
  return (
    <>
      {/*TODO find out if the create case is ever used*/}
      {create && (
        <>
          <Title level={5}>Model Parameters</Title>
          <ParameterEditForm child model={{ ...model }} />
        </>
      )}
      {create ? (
        <>
          <Title level={5}>Execution Settings</Title>
          {formJSX}{' '}
        </>
      ) : (
        <>
          <Collapse ghost defaultActiveKey="">
            <Panel header="Edit Execution Settings" key={execution.id}>
              {formJSX}
            </Panel>
          </Collapse>
        </>
      )}
    </>
  );
};
