import { Badge, Layout } from 'antd';
import { BellFilled, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import UserMenu from './UserMenu';

const { Header } = Layout;

export const TopBar = (): JSX.Element => {
  return (
    <Header className="header__wrapper">
      <div className="header-menu__wrapper">
        {/* <div className="header-menu__item"> */ }
        {/*   <SearchOutlined /> */ }
        {/* </div> */ }
        {/* <div className="header-menu__item"> */ }
        {/*   <SettingOutlined /> */ }
        {/* </div> */ }
        {/* <div className="header-menu__item"> */ }
        {/*   <Badge count={2} dot> */ }
        {/*     <BellFilled /> */ }
        {/*   </Badge> */ }
        {/* </div> */ }
      </div>
      <div className="header__separator" />
      <UserMenu />
    </Header>
  );
};

export default TopBar;
