import AdvancedTable from '../../shared/AdvancedTable/AdvancedTable';
import {generatePath} from 'react-router';
import {toTimeZone} from '../../../utils/utils';
import {useEffect} from 'react';
import {ErrorMessage} from '../ErrorPage/ErrorMessage';
import modelsAll from '../../../common/graphql/subscriptions/modelsAll';
import {useParams} from 'react-router-dom';
import useReSubscription from '../../../services/apollo/subscription';
import {ModelAlwaysOnSwitch} from './components/ModelAlswaysOnSwitch';

const onRow =
    ({path, setModalRow, setShowConfigurationModal, history}) =>
        (record, rowIndex) => {
            return {
                onClick: (event) => {
                    const generatedPath = generatePath(path === '/' ? `dev/models/:slug`: `models/:slug`, {slug: record.slug});
                    history.replace(generatedPath);
                    setModalRow(record);
                    setShowConfigurationModal(true);
                },
            };
        };

const columns = [
    {
        title: 'Project',
        dataIndex: 'project_name',
        key: 'project',
        sort: true,
        filter: true,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sort: true,
        search: true,
    },
    {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
        sort: true,
        filter: true,
        search: true,
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        sort: true,
        search: true,
    },
    {
        title: 'Updated',
        dataIndex: 'updated',
        key: 'updated',
        sort: true,
        search: true,
    },
    {
        title: 'Always on',
        dataIndex: 'always_on',
        key: 'always_on',
        render: (state, record, index) => {
            return (
                <ModelAlwaysOnSwitch
                    defaultChecked={record.always_on}
                    model_id={record.id}
                />
            );
        },
    },
];

export const ModelsOverviewTable = ({
                                        modalRow,
                                        setModalRow,
                                        showConfigurationModal,
                                        setShowConfigurationModal,
                                        history,
                                        path,
                                    }) => {
    const {loading, error, data} = useReSubscription(modelsAll, {
        variables: {},
    });
    const {slug} = useParams();

    const transformedData = data?.model?.map((model) => {
        return {
            ...model,
            created: toTimeZone(model.created_at),
            updated: toTimeZone(model.updated_at),
            project_name: model.project.name,
            name: model.deployment?.name,
            description: model.deployment?.description,
            has_parameter: model.deployment?.parameter_openapi_schema,
            key: model.id,
            slug: encodeURIComponent(
                model.deployment?.name.replaceAll(' ', '_').toLocaleLowerCase(),
            ),
        };
    });

    // This function finds a record by slug and updates the modal state
    const findRecordAndUpdateModal = (slugValue) => {
        if (slugValue && transformedData) {
            const foundRecord = transformedData.find(
                (element) => element.slug === slugValue,
            );
            if (foundRecord && foundRecord !== modalRow) {
                setShowConfigurationModal(true);
                setModalRow(foundRecord);
            }
        }
    };

    useEffect(() => {
        if (data && slug) {
            findRecordAndUpdateModal(slug);
        }
    }, [slug, data]);

    useEffect(() => {
        // This logic runs when `showConfigurationModal` changes.
        if (!showConfigurationModal && slug) {
            findRecordAndUpdateModal(slug);
        }
    }, [showConfigurationModal, slug]);

    if (error) {
        console.log(error);
        return <ErrorMessage {...{message: error.message}} />;
    }

    return (
        <AdvancedTable
            dataSource={transformedData}
            loading={loading}
            columns={columns}
            pagination={{
                showSizeChanger: true,
                defaultPageSize: 15,
                pageSizeOptions: ['10', '15', '20', '30', '50'],
            }}
            onRow={onRow({path, setModalRow, setShowConfigurationModal, history})}
            type="modelsOverview"
        />
    );
};
