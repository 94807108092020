import { useState } from 'react';
import { Button, message } from 'antd';
import { useApolloClient } from '@apollo/client';

import { downloadFileFromUrl } from 'utils/utils.js';
import actionLoadBlobUrl from 'common/graphql/mutations/actionLoadBlobUrl';

export const DownloadBlobButton = (props) => {
  const { blob_url, blob_container, ...btnProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    if (!blob_url) return console.log('No blob_url parameter');

    setLoading(true);
    client
      .mutate({
        mutation: actionLoadBlobUrl,
        variables: { blob: blob_url, blob_container: blob_container },
      })
      .then((result) => {
        downloadFileFromUrl(result.data.load_blob_url.blob_url_sas);
        message.success({ content: 'Successfully downloaded Blob' });
        setLoading(false);
      })
      .catch((error) => {
        message.error({
          content: 'Error while trying to download Blob: ' + error,
        });
        setLoading(false);
      });
  };

  return (
    <Button {...btnProps} loading={loading} onClick={handleClick}></Button>
  );
};

export default DownloadBlobButton;
