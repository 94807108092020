import { gql } from '@apollo/client';

const modelAlwaysOnEdit = gql`
  mutation ($id: Int!, $value: Boolean!) {
    update_model_by_pk(
      pk_columns: { id: $id }
      _set: { always_on: $value }
    ) {
      id
      always_on
    }
  }
`;

export default modelAlwaysOnEdit;
