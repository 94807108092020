import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Col, DatePicker, Form, Row, Tag } from 'antd';
import { useQuery } from '@apollo/client';

import cdmDataByIdentifier from 'common/graphql/querys/cdmDataByIdentifier';
import AdvancedTable from '../../shared/AdvancedTable/AdvancedTable';
import dayjs from "dayjs";

export const CDMDataOverview = () => {
  const { identifier } = useParams();
  const [form] = Form.useForm();

  const [tsStart, setTsStart] = useState(dayjs().subtract(1, 'day'));
  const [tsEnd, setTsEnd] = useState(dayjs().add(3, 'day'));

  const getVariables = () => {
    const v = { identifier: identifier };
    if (tsStart) v.ts_start = dayjs.utc(tsStart).format('YYYY-MM-DD');
    if (tsEnd) v.ts_end = dayjs.utc(tsEnd).format('YYYY-MM-DD');
    return v;
  };

  const { loading, error, data, refetch } = useQuery(cdmDataByIdentifier, {
    variables: getVariables(),
    fetchPolicy: 'no-cache',
  });
  const cdmData = data?.cdm_calendar;

  if (error) console.log(error);

  const onSearch = (values) => {
    setTsStart(values.start);
    setTsEnd(values.end);
    refetch();
  };

  const columns = [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      sort: true,
      search: true,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      sort: true,
      search: true,
      render: (state, record, index) => {
        if (record.active) return <Tag color="green">active</Tag>;
        return <Tag color="magenta">disabled</Tag>;
      },
    },
  ];

  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: <Link to="/data/cdm/">CDM Data Management</Link>,
    },
    { title: identifier },
  ];

  return (
    <div id="udm-data-overview" style={{ backgroundColor: '#F1F0EB' }}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Form
        form={form}
        onFinish={onSearch}
        initialValues={{ start: tsStart, end: tsEnd }}
      >
        <Row stype={{ width: '100%' }}>
          <Col span={8} style={{ textAlign: 'left' }}>
            <Form.Item label="Start" name="start">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>

          <Col span={8} style={{ textAlign: 'left' }}>
            <Form.Item label="End" name="end">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>

          <Col span={8} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <AdvancedTable
        rowKey="id"
        loading={loading}
        dataSource={cdmData}
        columns={columns}
        type="cdmDataOverview"
      />
    </div>
  );
};

export default CDMDataOverview;
