import AdvancedTable from '../../../shared/AdvancedTable/AdvancedTable';
import {Card, Col, Row, Space, Spin} from 'antd';
import BasicTooltipButton from '../../../shared/BasicTooltipButton';
import {CopyOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {copyToClipboard} from '../../../../utils/utils';
import SafeDeleteIdMutationButton from '../../../shared/SafeDeleteIdMutationButton';
import secretDelete from '../../../../common/graphql/mutations/secretDelete';
import {useState} from 'react';
import SecretEditModal from '../../SecretPage/SecretEditModal';
import {useQuery as useReactQuery} from '@tanstack/react-query';
import {useAuthStore} from "../../../../store/useAuthStore";
import {getDataInterfaceSecrets} from "../../../../common/graphql/mutations/getDataInterfaceSecrets";
import {useApolloClient} from "@apollo/client";


const columns = (hideValue, openModal, handleChange) => [
    {
        title: 'Key',
        dataIndex: 'name',
        key: 'key',
        sort: true,
        search: true,
    },
    {
        title: 'Value',
        dataIndex: 'data.value',
        key: 'value',
        render: (state, record, index) => {
            if (hideValue) return '* * * * * * * *';
            return record?.data.id;
        },
    },
    {
        title: 'Actions',
        key: 'action',
        width: 150,
        render: (state, record, index) => {
            return (
                <Space>
                    <BasicTooltipButton
                        tooltip={'Copy to Clipboard'}
                        icon={<CopyOutlined/>}
                        size={'medium'}
                        shape="circle"
                        type="primary"
                        onClick={() => {
                            copyToClipboard(record?.data.value);
                        }}
                    />
                    <BasicTooltipButton
                        tooltip={'Edit Secret'}
                        icon={<EditOutlined/>}
                        size={'medium'}
                        shape="circle"
                        type="primary"
                        onClick={() => openModal(record)}
                    />
                    <SafeDeleteIdMutationButton
                        id={record.id}
                        mutation={secretDelete}
                        onDataChange={handleChange}
                        tooltip={'Delete Secret'}
                        icon={<DeleteOutlined/>}
                        size={'medium'}
                        shape="circle"
                        type="secondary"
                    />
                </Space>
            );
        },
    },
];

const closeModal = (setShowModal, setModalData) => () => {
    setShowModal(false);
    setModalData(null);
};

const openModal = (setModalData, setShowModal) => (data) => {
    setModalData(data);
    setShowModal(true);
};

const handleChange = (refetch) => () => {
    refetch();
};

const fetchSecretsWithString = async (client, name, accessToken) => {
    try {

        const result = await client.mutate({
                mutation: getDataInterfaceSecrets,
                variables: {name: name},
            }
        );

        return result?.data?.get_data_interface_secrets_with_string?.secrets;

    } catch (e) {
        throw new Error(e.message);
    }
}

export const SecretManagement = ({interfaceName}) => {
    // NOTE: We need the useApolloClient hook instead of the useMutation. We are using a Hasura action for fetching the
    // secrets and we can not subscribe to it. If we alter a secret entry in the FE we need to re-fetch the secrets list.
    // We can do this with the tanstack useQuery hook
    const client = useApolloClient();
    const {authData: {accessToken}} = useAuthStore((state) => ({
        authData: state.authData,
    }));

    // TODO make query to filter results according the interfaceName
    // const { loading, error, data, refetch } = useQuery(secretsByContainingKey, {
    //   variables: {
    //     _key: `%${interfaceName}%`,
    //   },
    // });


    const {
        loading,
        error,
        data,
        refetch
    } = useReactQuery(['secrets', interfaceName, accessToken], () => fetchSecretsWithString(client, interfaceName, accessToken));


    const [showModal, setShowModal] = useState(false);
    const [hideValue, setHideValue] = useState(true);
    const [modalData, setModalData] = useState(null);


    if (error) return <h2>Error: {error?.message}</h2>;


    if (loading)
        return (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Card style={{height: '100%'}}>
                    <Row style={{justifyContent: 'center'}}>
                        <Spin/>
                    </Row>
                </Card>
            </Col>
        );

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <h2>Secret Management</h2>
            <Space style={{marginBottom: '10px', width: '100%'}}>
                <BasicTooltipButton
                    onClick={() => {
                        setHideValue(!hideValue);
                    }}
                >
                    {hideValue ? 'Reveal Values' : 'Hide Values'}
                </BasicTooltipButton>
            </Space>
            <SecretEditModal
                open={showModal}
                secret={modalData}
                onDataChange={handleChange(refetch)}
                onClose={closeModal(setShowModal, setModalData)}
                onCancel={closeModal(setShowModal, setModalData)}
                isEditing={true}
            />
            <AdvancedTable
                columns={columns(
                    hideValue,
                    openModal(setModalData, setShowModal),
                    handleChange(refetch),
                )}
                dataSource={data}
                pagination={{hideOnSinglePage: true}}
                type="secretManagement"
            />
        </div>
    );
};