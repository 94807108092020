import { Menu, Dropdown, Row, Button } from 'antd';
import ProfileAvatar from './ProfileAvatar';
import { useMsal } from '@azure/msal-react';
import { useAuthStore } from '../../../store/useAuthStore';

export const UserMenu = (props) => {
  const { userData, logout } = useAuthStore((state) => ({
    userData: state.userData,
    logout: state.logout,
  }));
  const { instance } = useMsal();

  function handleLogout(instance) {
    logout();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  const items = [
    {
      key: 'logout',
      onClick: () => handleLogout(instance),
      label: <a type="text">Logout</a>,
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <Row align="middle">
        <div className="header__avatar-name">
          {userData?.name === 'Matthias Blum'
            ? 'Matthia Blümchen (She/Her)'
            : userData?.name || ''}
        </div>
        <ProfileAvatar />
      </Row>
    </Dropdown>
  );
};

export default UserMenu;
