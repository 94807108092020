import {useState, useEffect} from 'react';
import {Button, Modal, message} from 'antd';

import processCreate from 'common/graphql/mutations/processCreate';
import processUpdate from 'common/graphql/mutations/processUpdate';
import { useApolloClient } from '@apollo/client';
import AdvancedForm from '../../shared/AdvancedForm/AdvancedForm';

const ProcessEditModal = (props) => {
  const { process, onChange, ...modalProps } = props;
  const create = !process;

  const client = useApolloClient();

  const [formData, setFormData] = useState(() => {
    if (process)
      return {
        name: process.name,
        description: process.description,
      };
  });
  const [loading, setLoading] = useState(false);


    useEffect(() => {
        process && setFormData(process);
    }, [process]);

    const schema = {
        $schema: 'http://json-schema.org/draft-07/schema#',
        type: 'object',
        properties: {
            name: {
                type: 'string',
            },
            description: {
                type: 'string',
            },
        },
    };

  const handleDataChange = (e) => {
    setFormData(e.formData);
  };

  const handleFormReset = () => {
    if (process) setFormData(process);
    else setFormData(null);
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (create) {
      client
        .mutate({
          mutation: processCreate,
          variables: {
            name: formData.name,
            description: formData.description,
            owner: 'Default Owner',
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully created process.' });
          setLoading(false);
          if (onChange) onChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to create process: ' + error,
          });
          setLoading(false);
        });
    } else {
      client
        .mutate({
          mutation: processUpdate,
          variables: {
            process_id: process.id,
            name: formData.name,
            description: formData.description,
            owner: 'Default Owner',
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully updated process.' });
          setLoading(false);
          if (onChange) onChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to update process: ' + error,
          });
          setLoading(false);
        });
    }
  };

  const formChanged = () => {
    if (!process) {
      if (formData) return true;
    } else
      return (
        process.name !== formData.name ||
        process.description !== formData.description
      );
  };

  const onClose = () => {
    handleFormReset();
    if (modalProps.onClose) modalProps.onClose();
  };
  const onCancel = () => {
    handleFormReset();
    if (modalProps.onCancel) modalProps.onCancel();
  };

  const title = () => {
    if (process) return 'Edit Process: ' + process.name;
    else return 'Create new Process';
  };
  return (
    <Modal
      {...modalProps}
      title={title()}
      width={800}
      onCancel={onCancel}
      onClose={onClose}
      destroyOnClose
      footer={[
        <Button
          key="resetButton"
          onClick={handleFormReset}
          hidden={!formChanged}
        >
          Reset
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {create ? 'Create' : 'Update'} Process
        </Button>,
      ]}
    >
      <AdvancedForm
        schema={schema}
        formData={formData}
        onChange={handleDataChange}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default ProcessEditModal;
