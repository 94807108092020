import React, { createContext, useState, useContext } from 'react';

// Create a context for form errors
const FormErrorContext = createContext({
    errors: [],
    setErrors: () => {},
});

export const useErrors = () => useContext(FormErrorContext);

export const FormErrorProvider = ({ children }) => {
    const [errors, setErrors] = useState([]);

    return (
        <FormErrorContext.Provider value={{ errors, setErrors }}>
            {children}
        </FormErrorContext.Provider>
    );
};
