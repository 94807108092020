import { gql } from '@apollo/client';

const packageDelete = gql`
  mutation deletePackage($id: Int!) {
    delete_package_by_pk(id: $id) {
      id
    }
  }
`;

export default packageDelete;
