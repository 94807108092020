import { Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

export const PipelineStateTag = (props) => {
  const tag = () => {
    if (props.state === 'failed')
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {props.state}
        </Tag>
      );
    if (props.state === 'success')
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {props.state}
        </Tag>
      );
    if (props.state === 'running')
      return (
        <Tag icon={<SyncOutlined />} color="processing">
          {props.state}
        </Tag>
      );
    if (props.state === 'pending')
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          {props.state}
        </Tag>
      );
    return <Tag color="default">{props.state}</Tag>;
  };

  if (props.message) {
    return (
      <Tooltip placement="top" title={props.message}>
        {tag()}
      </Tooltip>
    );
  }
  return tag();
};

export default PipelineStateTag;
