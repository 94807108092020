import { create } from 'zustand';
import dayjs from "dayjs";

export const useUserSession = create((set) => ({
  filters: {},
  updateFilters: (newFilters) =>
    set((state) => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    })),
  sorters: {},
  updateSorters: (newSorters) =>
    set((state) => ({
      sorters: {
        ...state.sorters,
        ...newSorters,
      },
    })),
  config: {
    runsDateStrings: {
      from: dayjs().subtract(12, 'hour'),
      to: undefined,
    },
  },
  updateConfig: (newConfig) =>
    set((state) => ({
      config: {
        ...state.config,
        ...newConfig,
      },
    })),
}));