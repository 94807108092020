import {
  getSmoothStepPath,
  getMarkerEnd,
  useStoreState,
  Position,
  getBezierPath,
} from 'react-flow-renderer/nocss';

const getHandlePosition = (position, node, handle = null) => {
  const x = (handle?.x || 0) + node.__rf.position.x;
  const y = (handle?.y || 0) + node.__rf.position.y;
  const width = handle?.width || node.__rf.width;
  const height = handle?.height || node.__rf.height;

  switch (position) {
    case Position.Top:
      return { x: x + width / 2, y };
    case Position.Right:
      return { x: x + width, y: y + height / 2 };
    case Position.Bottom:
      return { x: x + width / 2, y: y + height };
    case Position.Left:
      return { x, y: y + height / 2 };
    default:
      return { x, y: y + height / 2 };
  }
};

const getCustomEdgePath = ({
  variant,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}) => {
  switch (variant) {
    case 'bezier':
      return getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      });
    case 'smoothstep':
      return getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      });
    case 'linear':
      return `M ${sourceX},${sourceY}L ${targetX},${targetY}`;
    default:
      return `M ${sourceX},${sourceY}L ${targetX},${targetY}`;
  }
};

const CustomEdge = ({
  id,
  style = {},
  sourcePosition,
  targetPosition,
  data,
  arrowHeadType,
  markerEndId,
  source,
  target,
}) => {
  const nodes = useStoreState((state) => state.nodes);

  const sourceNode = nodes.find((node) => node.id === source);
  const sourceHandle = getHandlePosition(sourcePosition, sourceNode, null);
  const targetNode = nodes.find((node) => node.id === target);
  const targetHandle = getHandlePosition(targetPosition, targetNode, null);

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const edgePath = getCustomEdgePath({
    sourceX: sourceHandle.x,
    sourceY: sourceHandle.y,
    sourcePosition,
    targetX: targetHandle.x,
    targetY: targetHandle.y,
    targetPosition,
    variant: data?.variant ?? 'bezier',
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {data?.label ? (
        <text>
          <textPath
            href={`#${id}`}
            style={{ fontSize: '12px' }}
            startOffset="50%"
            textAnchor="middle"
          >
            {data.label}
          </textPath>
        </text>
      ) : null}
    </>
  );
};

export default CustomEdge;
