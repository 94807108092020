import { useQuery } from '@apollo/client';
import { Button, Space, Spin, Typography } from 'antd';

import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import actionGetExeqtPipelines from 'common/graphql/querys/actionGetExeqtPipelines';
import { GitlabOutlined } from '@ant-design/icons';

import PipelineStateTag from 'components/shared/PipelineStateTag';
import BasicTooltipButton from 'components/shared/BasicTooltipButton';

const { Text } = Typography;

export const PipelineStatus = (props) => {
  const { pipelineRef } = props;
  const { data, loading, error, refetch } = useQuery(actionGetExeqtPipelines, {
    variables: { ref: pipelineRef },
  });

  if (loading) return <Spin size="large" />;
  if (error) {
    console.log(error);
    return <Text>An Error occured.</Text>;
  }

  const pipelines = data?.exeqt_builder_pipeline_status?.data;

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      search: true,
      sort: true,
    },
    {
      title: 'Env',
      dataIndex: 'ref',
      key: 'ref',
      search: true,
      sort: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      search: true,
      sort: true,
      render: (state, record, index) => {
        return <PipelineStateTag state={record.status} />;
      },
    },
    {
      title: 'Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sort: true,
    },
    {
      title: 'Link',
      dataIndex: 'web_url',
      key: 'web_url',
      sort: true,
      render: (state, record, index) => {
        return (
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: '10px' }}
            icon={<GitlabOutlined />}
            onClick={() => {
              window.open(record.web_url, '_blank');
            }}
          >
            Link
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Space style={{ marginBottom: '10px', width: '100%' }}>
        <BasicTooltipButton type="primary" onClick={() => refetch()}>
          Refresh
        </BasicTooltipButton>
      </Space>
      <AdvancedTable dataSource={pipelines} columns={columns} rowKey="id" type="pipelineStatus" />
    </div>
  );
};

export default PipelineStatus;
