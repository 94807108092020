import {gql, useQuery, useSubscription} from "@apollo/client";
import runlogsByFlowRunId from "../../../../common/graphql/subscriptions/runlogsByFlowRunId";
import { useEffect, useState} from "react";
import useReSubscription from "../../../../services/apollo/subscription";
import {activeStates} from "../../../../utils/flowRunStateTypes";

const RunLogsByFlowRunIdQuery = gql`
    query RunLogsByFlowRunId ($flow_run_id: uuid!) {
        oe_log(where: { flow_run_id: { _eq: $flow_run_id } }, order_by: { timestamp: asc }) {
            id
            message
            name
            level
            timestamp
            flow_run_id
            task_run {
                name
                id
            }
        }
    }
`;

const flowRunStateTypeQuery = gql`
    subscription GetFlowRun ($flow_run_id: uuid!) {
        oe_flow_run(where: {id: {_eq: $flow_run_id}}) {
            state_type
        }
    }
`;

export const useHandleFlowRunLogs = (flowRunId) => {
    const [runIsActive, setRunIsActive] = useState(null)
    const {
        data: stateTypeData,
        loading: stateTypeLoading,
        error: stateTypeError
    } = useSubscription(flowRunStateTypeQuery, {
        variables: {flow_run_id: flowRunId},
    });

    useEffect(() => {
        if (stateTypeData?.oe_flow_run?.length > 0) {
            const stateType = stateTypeData.oe_flow_run[0].state_type
            setRunIsActive(activeStates.includes(stateType))
        }

    }, [stateTypeData])

// I need the re subscription since the flow run might be pending
// The native apollo useSubscription hook does not support re-subscription
    const {data, loading, error} = useReSubscription(runlogsByFlowRunId, {
        variables: {flow_run_id: flowRunId},
        skip: runIsActive === false,
    });
    const {data: data2, loading: loading2, error: error2} = useQuery(RunLogsByFlowRunIdQuery, {
        variables: {flow_run_id: flowRunId},
        skip: runIsActive === true || runIsActive === null, // I need the null case for the first render
    });

    return {
        data: runIsActive ? data : data2,
        loading: runIsActive ? loading : loading2,
        error: runIsActive ? error : error2,
    };

}