import { Row, Col } from 'antd';
import { Handle } from 'react-flow-renderer/nocss';
import { getStyle } from './utils';

export const OtherNode = (node) => {
    const loading = node.data.state === 'Running';
    const style = getStyle(node.data?.state?.state_name);
    return (
      <div className="grytnode_wrapper">
        {node.selected && <div className="grytnode_selected"></div>}
  
        <div className="grytnode grytnode_other" style={style}>
          <Row wrap={false} style={{ height: '100%' }}>
            <Col flex="auto" className="grytnode_main">
              <div className="grytnode_label">{node.data.name}</div>
            </Col>
          </Row>
  
          {loading && <div className="grytnode_loading"></div>}
  
          {node.data._io_type !== 'input' && (
            <Handle type="target" position="left" style={{ borderRadius: 0 }} />
          )}
          {node.data._io_type !== 'output' && (
            <Handle
              type="source"
              position="right"
              id={node.id}
              style={{ borderRadius: 0 }}
            />
          )}
        </div>
      </div>
    );
  };