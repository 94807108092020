import { Typography } from 'antd';

import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';

export const PackagesTable = (props) => {
  const { packages } = props;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      search: true,
      sort: true,
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filter: true,
      sort: true,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      filter: true,
      sort: true,
    },
    {
      title: 'Info',
      dataIndex: 'context_data',
      key: 'context_data',
      render: (state, record, index) => {
        const updates = record?.context_data?.updates;
        if (!updates) return '';

        return (
          <div>
            {updates.map((u, i) => (
              <Typography.Text key={i}>{u}</Typography.Text>
            ))}
          </div>
        );
      },
    },
  ];

  return <AdvancedTable dataSource={packages} columns={columns} rowKey="id" style="packagesTable" />;
};

export default PackagesTable;
