import { useEffect, useState } from 'react';
import { Button, Modal, message } from 'antd';

import secretCreate from 'common/graphql/mutations/secretCreate';
import secretEdit from 'common/graphql/mutations/secretEdit';

import { useApolloClient } from '@apollo/client';
import AdvancedForm from '../../shared/AdvancedForm/AdvancedForm';

const schema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    key: {
      type: 'string',
    },
    value: {
      type: 'string',
    },
  },
  required: ['key'],
};

const schemaEdit = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    key: {
      type: 'string',
      readOnly: true,
    },
    value: {
      type: 'string',
    },
  },
  required: ['key'],
};
const SecretEditModal = (props) => {
  const { secret, onDataChange, isEditing, ...modalProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ key: null, value: null });

  useEffect(() => {
    if (secret) return setFormData({ key: secret.name, value: secret.data.value });
    return setFormData({ key: '', value: '' });
  }, [secret]);

  const handleDataChange = (e) => {
    setFormData(e.formData);
  };

  const handleFormReset = () => {
    if (secret) setFormData(secret);
    else setFormData({ key: '', value: '' });
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (!secret) {
      client
        .mutate({
          mutation: secretCreate,
          variables: { key: formData.key, value: formData.value },
        })
        .then((result) => {
          message.success({ content: 'Succesfully created Secret.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to create Secret: ' + error,
          });
          setLoading(false);
        });
    } else {
      console.log({ id: secret.id, key: formData.key, value: formData.value });
      client
        .mutate({
          mutation: secretEdit,
          variables: {
            id: secret.id,
            key: formData.key,
            value: formData.value,
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully updated Secret.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to update Secret: ' + error,
          });
          setLoading(false);
        });
    }
  };

  const formChanged = () => {
    if (!secret) {
      if (formData) return true;
    } else
      return secret.key !== formData.key || secret.value !== formData.value;
  };

  const onClose = () => {
    handleFormReset();
    if (modalProps.onClose) modalProps.onClose();
  };
  const onCancel = () => {
    handleFormReset();
    if (modalProps.onCancel) modalProps.onCancel();
  };

  const title = () => {
    if (!secret) return 'Create Secret: ';
    else return 'Edit Secret';
  };

  return (
    <Modal
      {...modalProps}
      title={title()}
      width={800}
      onCancel={onCancel}
      onClose={onClose}
      destroyOnClose
      footer={[
        <Button
          key="resetButton"
          onClick={handleFormReset}
          hidden={!formChanged}
        >
          Reset
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {!secret ? 'Create' : 'Update'} Secret
        </Button>,
      ]}
    >
      <AdvancedForm
        schema={isEditing ? schemaEdit : schema}
        formData={formData}
        onChange={handleDataChange}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default SecretEditModal;
