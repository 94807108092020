import { ReactComponent as RightArrow } from 'assets/arrow_right.svg';

export const TaskInterfaceArrow = ({ interfaceFunction }) => {
  return (
    <div
      className="arrowContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 8em',
      }}
    >
      <p style={{ fontWeight: 'bold' }}>{interfaceFunction}</p>
      <RightArrow />
    </div>
  );
};
