import DataFlow from 'components/shared/DataFlow/';
import ExecutionNode from './ExecutionNode';
import CronNode from './CronNode';
import AlertNode from './AlertNode';
import APITriggerNode from './APITriggerNode';
import EventTriggerNode from './EventTriggerNode';

export const ProcessFlow = (props) => {
  const { executions, ...flowParams } = props;

  const nodes = [];
  const edges = [];

  executions.forEach((execution) => {
    const cron = execution.crons[0];
    const apiTrigger = execution.api_triggers[0];
    const eventTrigger = execution.event_triggers[0];

    // const alerts = execution.alerts
    const upstream_chains = execution.upstream_chains;

    nodes.push({
      id: execution.id.toString(),
      name: execution.name,
      type: 'EXECUTION',
      execution: {
        ...execution,
        cron: cron,
        upstream_chains: upstream_chains,
      },
    });

    // CRON
    if (cron && cron.id) {
      const cron_node_id = 'CRON' + cron.id;
      nodes.push({
        id: cron_node_id,
        name: cron.expression,
        type: 'CRON',
        cron: cron,
        execution: execution,
      });
      edges.push({
        upstream_node: cron_node_id,
        downstream_node: execution.id.toString(),
      });
    }

    if (apiTrigger && apiTrigger.id) {
      const apiTriggerId = 'API' + apiTrigger.id;
      nodes.push({
        id: apiTriggerId,
        name: 'API',
        type: 'API',
        apiTrigger: apiTrigger,
      });
      edges.push({
        upstream_node: apiTriggerId,
        downstream_node: execution.id.toString(),
      });
    }

    if (eventTrigger && eventTrigger.id) {
      const eventTriggerId = 'EVENT' + eventTrigger.id;
      nodes.push({
        id: eventTriggerId,
        name: 'EVENT',
        type: 'EVENT',
        eventTrigger: eventTrigger,
      });
      edges.push({
        upstream_node: eventTriggerId,
        downstream_node: execution.id.toString(),
      });
    }

    // CHAIN
    if (upstream_chains) {
      upstream_chains.forEach((chain) => {
        edges.push({
          upstream_node: chain.upstream_id.toString(),
          downstream_node: execution.id.toString(),
        });
      });
    }

    // ALERTS
    // if (Array.isArray(alerts) && alerts.length > 0) {
    //     alerts.forEach(alert => {
    //         const alert_id = 'ALERT' + alert.id
    //         nodes.push({
    //             id: alert_id,
    //             name: alert.type,
    //             type: 'ALERT',
    //             alert: alert,
    //         })
    //         edges.push({upstream_node: execution.id.toString(), downstream_node: alert_id})
    //     })
    // }
  });

  flowParams.nodes = nodes;
  flowParams.edges = edges;
  flowParams.customNodes = {
    ALERT: { nodeObj: AlertNode, nodeWidth: 40, nodeHeight: 40 },
    CRON: { nodeObj: CronNode, nodeWidth: 120, nodeHeight: 58 },
    API: { nodeObj: APITriggerNode, nodeWidth: 120, nodeHeight: 58 },
    EVENT: { nodeObj: EventTriggerNode, nodeWidth: 120, nodeHeight: 58 },
    EXECUTION: { nodeObj: ExecutionNode, nodeWidth: 260, nodeHeight: 120 },
  };

  return <DataFlow {...flowParams} />;
};
