import { gql } from '@apollo/client';

const processCreate = gql`
  mutation ($name: String, $owner: String, $description: String) {
    insert_process_one(
      object: { name: $name, owner: $owner, description: $description }
    ) {
      id
    }
  }
`;

export default processCreate;
