import { Button } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';

export const AddButton = (props) => {
  return (
    <Button {...props} type="primary" icon={<PlusSquareOutlined />}>
      Add Item
    </Button>
  );
};

export default AddButton;
