import { useState } from 'react';
import { Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';

import packagesCustom from 'common/graphql/querys/packagesCustom';
import packageDelete from 'common/graphql/mutations/packageDelete';

import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import BasicTooltipButton from 'components/shared/BasicTooltipButton';
import SafeDeleteIdMutationButton from 'components/shared/SafeDeleteIdMutationButton';
import CustomPackageEditModal from './CustomPackageEditModal';
import TriggerPipelineButton from './TriggerPipelineButton';

export const CustomPackageManagement = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { loading, error, data, refetch } = useQuery(packagesCustom);
  if (error) console.log(error);

  const packages = data?.package;
  const pipelineRef = window._env_.REACT_APP_ENVIRONMENT;
  const handleChange = () => {
    refetch();
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      search: true,
      sort: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sort: true,
      filter: true,
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Git Link',
      dataIndex: 'gitlink',
      key: 'gitlink',
      search: true,
      sort: true,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      render: (state, record, index) => {
        return (
          <Space>
            <BasicTooltipButton
              tooltip={'Edit Package'}
              icon={<EditOutlined />}
              size={'medium'}
              shape="circle"
              type="primary"
              onClick={() => openModal(record)}
            />
            <SafeDeleteIdMutationButton
              id={record.id}
              mutation={packageDelete}
              onDataChange={handleChange}
              tooltip={'Remove Package'}
              icon={<DeleteOutlined />}
              size={'medium'}
              shape="circle"
              type="secondary"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Space style={{ marginBottom: '10px', width: '100%' }}>
        <BasicTooltipButton type="primary" onClick={() => openModal(null)}>
          Add Package
        </BasicTooltipButton>
        {pipelineRef && (
          <TriggerPipelineButton
            pipelineRef={window._env_.REACT_APP_ENVIRONMENT}
          />
        )}
      </Space>

      <AdvancedTable
        loading={loading}
        dataSource={packages}
        columns={columns}
        rowKey="id"
        type="customPackageManagement"
      />

      <CustomPackageEditModal
        open={showModal}
        data={modalData}
        onDataChange={handleChange}
        onClose={closeModal}
        onCancel={closeModal}
      />
    </div>
  );
};

export default CustomPackageManagement;
