import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

export function useReSubscription(
  query,
  { variables, onSubscriptionData, timeout = 2000, skip = false },
) {
  const client = useApolloClient();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [retrySwitch, setRetrySwitch] = useState(true);

  useEffect(() => {

    if (skip) {
      setLoading(false);
      setError(null);
      return;
    }

    if (!data) setLoading(true);
    const observer = client.subscribe({ query: query, variables: variables });
    const subscription = observer.subscribe(
      ({ data }) => {
        setData(data);
        setLoading(false);
        setError(null);
        if (onSubscriptionData) onSubscriptionData(data);
      },
      (error) => {
        if (error && subscription.closed) {
          console.log(error);
          setError(error);
          setLoading(false);
          setTimeout(() => {
            setRetrySwitch(!retrySwitch);
          }, timeout);
        }
      },
    );

    return () => subscription.unsubscribe();
  }, [retrySwitch, skip]);

  return { data, error, loading };
}

export default useReSubscription;
