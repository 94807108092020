import {Button, Dropdown, Menu, Spin} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import DataFlow from 'components/shared/DataFlow/';
import tasksByFlowId from "../../../../common/graphql/querys/tasksByFlowId";
import {isObjectEmpty} from 'utils/utils';
import Editor from '@monaco-editor/react';
import {theme} from 'config/js/theme.js';
import {TaskNode} from './TaskNode';
import {ParameterNode} from './ParameterNode';
import {InterfaceNode} from './InterfaceNode';
import {OtherNode} from './OtherNode';
import {isEmpty} from "lodash";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {useEffect, useState} from "react";

// This needs to be a mutation, since we want to trigger a Hasura Action.
// This can be seen as a post request which is neccessary for the Hasura Action
const getFlowGraph = gql`
    mutation GetFlowGraph($flow_run_id: uuid, $model_id: uuid) {
        get_flow_graph(flow_run_id: $flow_run_id, model_id: $model_id) {
            edges
            message
            status
            success
            tasks
            task_runs_details
        }
    }
`


export const ModelFlow = ({
                              flowId,
                              selectedNodeData,
                              flowRunId,
                              updateVersion,
                              hasColor = true,
                              ...flowParams
                          }) => {
    // need the useState due to a property being set -- destroyInactivePanel -- within the Collapse component in the ModelInfoCollapseModelView
    const [refedFlowId, __] = useState(flowId);
    const [getGraph, {data: graphData, loading: graphLoading, error: graphError}] = useMutation(getFlowGraph);
    const [getTasks, {
        loading: tasksLoading,
        error: tasksError,
        data: tasksData
    }] = useLazyQuery(tasksByFlowId);

    // this is a helper to keep the old data while the new data is loading
    const [modelFlowData, setModelFlowData] = useState({});
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (flowRunId || refedFlowId) {
            getGraph({variables: {flow_run_id: flowRunId, model_id: refedFlowId}});
            getTasks({
                variables: {
                    flowId: flowId
                }
            });
        }
    }, [flowRunId, updateVersion, refedFlowId]);

    useEffect(() => {
        if (graphData) setModelFlowData(graphData);
        if (tasksData) setTasks(tasksData?.oe_flow?.[0]?.model?.tasks || []);

    }, [graphData, tasksData]);


    if (graphError) return <div>Error</div>;
    if (isEmpty(modelFlowData.get_flow_graph) && !graphLoading)
        return <div>No Data for ID: {flowRunId}</div>;

    const {edges, task_runs_details} = modelFlowData?.get_flow_graph || {};

    const nodes = task_runs_details?.map((taskDetail) => {
        let taskDescription;

        if (tasksError) {
            taskDescription = "Error occured while fetching task descriptions...";
        } else if (tasksLoading) {
            taskDescription = "Loading task descriptions...";
        } else {
            taskDescription = tasks.find((task) => {
                return taskDetail.task_run_label.split("-")[0] === task.name
            })?.description;
        }

        return {
            id: taskDetail.task_run_label,
            name: taskDetail.task_run_label,
            type: taskDetail.type,
            description: taskDescription,
            state: taskDetail,
            hasColor
        };
    });

    flowParams.nodes = nodes;
    flowParams.edges = edges;
    flowParams.customNodes = {
        TASK: {nodeObj: TaskNode, nodeWidth: 220, nodeHeight: 80},
        PARAMETER: {nodeObj: ParameterNode, nodeWidth: 220, nodeHeight: 40},
        INTERFACE: {nodeObj: InterfaceNode, nodeWidth: 220, nodeHeight: 40},
        OTHER: {nodeObj: OtherNode, nodeWidth: 220, nodeHeight: 40},
    };

    return (
        <div style={{position: 'relative', marginTop: '10px'}}>
            {graphLoading ? (
                <div style={{display: "flex"}}>
                    <Spin tip="Loading..." className="spinner" style={{marginRight: '10px'}}/>
                    <p style={{fontWeight: "bold"}}>Fetching...</p>
                </div>
            ) : null}
            <DataFlow {...flowParams} />
        </div>
    );
};

export default ModelFlow;