import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import './App.css';
import './config/css/overrides/Layout.css';
import 'swagger-ui-react/swagger-ui.css';
import 'katex/dist/katex.min.css';

// ! The Line Below needs to stay otherwise react-flow will behave wierd
import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';

import RunsOverview from 'components/pages/RunsPage/RunsOverview';
import {ProcessesOverview} from 'components/pages/ExecutionsPage/ProcessesOverview';
import ModelsOverview from 'components/pages/ModelsPage/ModelsOverview';
import SecretPage from 'components/pages/SecretPage';
import ProjectPage from 'components/pages/ProjectPage';
import CDMPage from 'components/pages/CDMPage';
import CDMDataOverview from 'components/pages/CDMPage/CDMDataOverview';
import UDMDataOverview from 'components/pages/UDMPage/UDMDataOverview';
import DashboardPage from 'components/pages/DashboardPage/DashboardPage';
import GeneralAdminPage from 'components/pages/GeneralAdminPage';
import UserAuthorizationPage from 'components/pages/UserAuthorizationPage';
import UserManagementPage from 'components/pages/UserManagementPage/UserManagementPage';

import EnvironmentManagementPage from 'components/pages/EnvironmentManagementPage';
import PackagesOverview from 'components/pages/EnvironmentManagementPage/PackagesOverview';

// The Line Below needs to stay otherwise react-flow will behave wierd
import { useApolloClient } from '@apollo/client';
import { useUserStore } from 'contexts/User';

import alertsFromUser from 'common/graphql/querys/alertsFromUser';
import DataInterfacePage from './components/pages/DataInterfacePage/DataInterfacePage';
import SwaggerPage from 'components/pages/DocumentationPage/swagger';

import { DocumentationPageContainer } from './components/pages/DocumentationPage/DocumentationPageContainer';
import { UDMPageDataManagementView } from './components/pages/UDMPage/UDMPageDataManagementView';
import { NotFound } from './components/pages/NotFound/NotFound';
import { RootLayout } from './components/layout/RootLayout/RootLayout';
import {useAuthStore} from "./store/useAuthStore";

const App = () => {
  // const [authState, _] = useAuthStore();
  const userId = useAuthStore((state) => state.getUserId(state));
  const [__, dispatch] = useUserStore();
  const client = useApolloClient();

  useEffect(() => {
    client
      .query({
        query: alertsFromUser,
        variables: { user_id: userId },
      })
      .then((response) => {
        if (response?.data?.alert)
          dispatch({
            type: 'UPDATE_USER_ALERTS',
            payload: response.data.alert,
          });
        return response.data.alert;
      });
  }, [userId]);

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#1bb470',
          colorInfo: '#1bb470',
          fontFamily: 'Lato, sans-serif',
        },
        components: {
          Layout: {
            headerBg: '#fff',
            siderBg: '#fff',
            triggerBg: '#00493b',
            bodyBg: '#f1f0eb',
            footerBg: '#f1f0eb',
            // lightTriggerBg: '#00493b',
            // lightTriggerColor: '#00493b',
          },
          Menu: {
            darkItemBg: '#00493b',
            // colorBgHeader: '#fff',
            // itemColor: '#fff',
            // itemHoverColor: '#fff',
            // darkItemColor: '#fff',
            darkSubMenuItemBg: '#336d62',
            itemMarginInline: '0px',
            itemSelectedColor: 'red',
            // horizontalItemSelectedColor: "red"
            // darkItemSelectedBg: "#fff",
            // darkItemSelectedColor: "#fff"
            // darkGroupTitleColor: '#fff',
            // subMenuItemBg: '#fff',
          },
        },
      }}
    >
      <RootLayout>
        <Switch>
          {/* <DummyPage /> */}
          <Route exact path="/ops/runs/:id?" component={RunsOverview} />
          <Route exact path="/ops/executions" component={ProcessesOverview} />
          <Route exact path="/dev/models/:slug?" component={ModelsOverview} />

          {/* Data Management */}
          <Route exact path="/data/cdm" component={CDMPage} />
          <Route
            exact
            path="/data/cdm/:identifier"
            component={CDMDataOverview}
          />
          <Route exact path="/data/udm" component={UDMPageDataManagementView} />
          <Route
            exact
            path="/data/udm/:identifier"
            component={UDMDataOverview}
          />
          <Route
            exact
            path="/data/interface/:id"
            component={DataInterfacePage}
          />

          {/* Dashboards */}
          <Route exact path="/dashboards/:slug+" component={DashboardPage} />

          {/* Administration */}
          <Route exact path="/admin" component={GeneralAdminPage} />
          <Route exact path="/project/:id" component={ProjectPage} />
          <Route
            exact
            path="/environment/:envId"
            component={PackagesOverview}
          />
          <Route
            exact
            path="/environments"
            component={EnvironmentManagementPage}
          />
          <Route exact path="/secret" component={SecretPage} />
          <Route exact path="/authoverview" component={UserAuthorizationPage} />
          <Route exact path="/usermanagement" component={UserManagementPage} />
          {/* Docu */}
          <Route exact path="/docu/" component={DocumentationPageContainer} />
          <Route
            exact
            path="/docu/:slug+"
            component={DocumentationPageContainer}
          />
          <Route exact path="/swagger" component={SwaggerPage} />
          {/* Home */}
          <Route exact path="/" component={ModelsOverview} />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </RootLayout>
    </ConfigProvider>
  );
};

export default App;
