import {List, Typography} from "antd";

const {Text} = Typography;

export const FilteredErrors = ({filteredErrors}) => {

    if (!filteredErrors) return null;


    return (
        <List>
            {filteredErrors.map((error, index) => (
                <List.Item key={index}>
                    <Text type="danger">
                        {error.property.split('.').pop()}: {error.message}
                    </Text>
                </List.Item>
            ))}
        </List>
    )
}