import { gql } from '@apollo/client';

export const processExecutionsSimple = gql`
  query {
    process {
      id
      name
      owner
      description
      executions {
        id
        created_at
        description
        model_id
        last_successful_run
        name
        parameters
        process_id
        trigger_type
        updated_at
        crons {
          expression
          id
          execution_id
        }
        upstream_chains {
          downstream_id
          upstream_id
          id
          upstream_execution {
            name
          }
        }
      }
    }
  }
`;

export default processExecutionsSimple;
