import BasicFieldLayout from '../Layout/BasicFieldLayout';
import JsonEdit from '../InputComponents/JsonEdit';

const ExeqtJsonEditField = (props) => {
  return (
    <BasicFieldLayout {...props}>
      <JsonEdit {...props}></JsonEdit>
    </BasicFieldLayout>
  );
};

export default ExeqtJsonEditField;
