import { useEffect, useState } from 'react';
import { message, Space, Switch, Typography } from 'antd';
import { useApolloClient } from '@apollo/client';
import alertDelete from 'common/graphql/mutations/alertDeleteById';
import alertInsert from 'common/graphql/mutations/alertInsert';
import { useUserStore } from 'contexts/User';
import {useAuthStore} from "../../../store/useAuthStore";

const { Text } = Typography;

const AlertTypeSwitch = (props) => {
  const client = useApolloClient();
  const userId = useAuthStore((state) => state.getUserId(state));
  const [__, dispatch] = useUserStore();

  const { executionId, type, alert } = props;
  const [isChecked, setIsChecked] = useState(!!alert);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (alert?.id) setIsChecked(true);
    else setIsChecked(false);
  }, [alert]);

  const handleChange = () => {
    setIsLoading(true);
    if (isChecked) {
      client
        .mutate({
          mutation: alertDelete,
          variables: { id: alert.id },
        })
        .then((response) => {
          message.success({ content: 'Successfully disabled Alert' });
          setIsLoading(false);
          dispatch({
            type: 'DELETE_USER_ALERT',
            payload: response.data.delete_alert_by_pk,
          });
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to disable Alert: ' + error,
          });
          setIsLoading(false);
        });
    } else {
      client
        .mutate({
          mutation: alertInsert,
          variables: {
            user_id: userId,
            execution_id: executionId,
            type: type,
          },
        })
        .then((response) => {
          message.success({ content: 'Successfully enabled Alert' });
          setIsLoading(false);
          console.log(response);
          dispatch({
            type: 'CREATE_USER_ALERT',
            payload: response.data.insert_alert_one,
          });
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to enable Alert: ' + error,
          });
          setIsLoading(false);
        });
    }
  };

  return (
    <Space style={{ width: '100%', padding: '10px' }}>
      <Switch loading={isLoading} checked={isChecked} onChange={handleChange} />
      <Text>{type} Alert</Text>
    </Space>
  );
};

export default AlertTypeSwitch;
