import { Col, Row, Typography } from 'antd';

import { theme } from 'config/js/theme.js';
import { InfoTooltip } from './InfoTooltip';
import { BasicFieldTitle } from './BasicFieldTitle';

const { Text } = Typography;

export const BasicFieldLayout = (props) => {
  const label = props.schema?.label || props.label;

  return (
    <Row wrap={false} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
      {label && (
        <Col flex={'20px'} style={{ textAlign: 'center' }}>
          <InfoTooltip {...props} />
        </Col>
      )}
      {label && (
        <Col flex={'200px'} align={'left'}>
          <BasicFieldTitle label={label} />
          <Text strong style={{ color: theme['primary-color-green-light'] }}>
            {props.required ? ' *' : ''}
          </Text>
        </Col>
      )}

      <Col flex="auto" style={{ minWidth: '300px' }}>
        {props.children}
      </Col>
    </Row>
  );
};

export default BasicFieldLayout;
