import { Alert, Button, Col, DatePicker, Row } from 'antd';
import dayjs from 'dayjs';
import './RunsHeader.css';

export const RunsHeader = ({ updateConfig, runsDateStrings, runs }) => {
  return (
    <Col style={{ marginLeft: 'auto' }}>
      <Row>
        <DatePicker
          placeholder="From"
          format="YYYY/MM/DD HH:mm:ss"
          value={dayjs(runsDateStrings?.from)}
          onChange={(date, dateString) => {
            updateConfig({
              runsDateStrings: { from: dateString, to: runsDateStrings?.to },
            });
          }}
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          allowClear={false}
          disabledDate={(current) => {
              return current && runsDateStrings?.to && current >= dayjs(runsDateStrings?.to);
          }}
        />
        <DatePicker
          className="runs-header__date-picker"
          placeholder="Now"
          format="YYYY/MM/DD HH:mm:ss"
          value={runsDateStrings?.to ? dayjs(runsDateStrings?.to) : null}
          onChange={(date, dateString) => {
            updateConfig({
              runsDateStrings: { from: runsDateStrings?.from, to: dateString },
            });
          }}
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          disabledDate={(current) => {
            return current && current <= dayjs(runsDateStrings?.from);
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            updateConfig({
              runsDateStrings: {
                from: dayjs().subtract(12, 'hours').format(),
                to: undefined,
              },
            });
          }}
        >
          Reset
        </Button>
      </Row>
      {runs?.length > 500 && (
        <Alert
          style={{ marginTop: '.5em' }}
          message="Only 500 runs are displayed"
          type="warning"
        />
      )}
    </Col>
  );
};