import { Collapse, Typography } from 'antd';
import { useState } from 'react';

import { Markdown } from '@forrs-ems/doc-tool-components';
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import ModelFlow from "../DataFlow/ModelFlow/ModelFlow";

const { Text } = Typography;
const { Panel } = Collapse;

export const ModelInfoCollapseModelView = (props) => {
  const { model } = props;
  const [selected, setSelected] = useState(null);
  const handleSelectionChange = (elements) => {
    if (!elements || !elements[0]?.data) setSelected(null);
    else setSelected(elements[0]);
  };

  return (
    <Collapse ghost defaultActiveKey="" destroyInactivePanel>
      <Panel header={<Text>Model View:</Text>} key={'model'}>
        <ModelFlow flowId={model.flow_id} onSelectionChange={handleSelectionChange} hasColor={false} />
        {/* {selected?.data?.description && <div  style={{marginLeft:'40px'}}><Markdown>{`### ${selected.data.name}\n ${ selected.data.description}`}</Markdown></div>} */}
        {selected?.data?.description && (
          <div style={{ paddingBottom: '10px', backgroundColor: '#EEE' }}>
            <div style={{ marginLeft: '20px' }}>
              <Markdown
                  HashLink={HashLink}
                  Link={Link}
                style={{ paddinLeft: '20px' }}
              >{`### ${selected.data.name}\n ${selected.data.description}`}</Markdown>
            </div>
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

export default ModelInfoCollapseModelView;
