import { useState } from 'react';
import { Modal, Form, Button, message } from 'antd';
import { useApolloClient } from '@apollo/client';

import TimeZoneSelector from './AdvancedForm/InputComponents/TimeZoneSelector';
//import CalendarSelector from './AdvancedForm/InputComponents/CalendarSelector';
import CronExpressionInput from './AdvancedForm/InputComponents/CronExpressionInput';

import { actionExecutionUpdate } from '../../common/graphql/mutations/actionExecutionUpdate';

export const CronEditModal = (props) => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const { cron, ...modalProps } = props;
  const execution = props.execution;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    // TODO: there is a bit of a misalignment here: the UI dialog exposes the calendar field,
    // whereas the update schedule call to the action service does not accept this argument at all
    // and will trigger a validation error
    // const schedule = {"cron": values.expression, "timezone": values.timezone, "calendar_id": values.calendar_id};
    const schedule = {"cron": values.expression, "timezone": values.timezone};
    client
      .mutate({
        mutation: actionExecutionUpdate,
        variables: {
          model_name: execution.model.name,
          execution_id: execution.id,
          project_name: execution.model.project.name,
          schedule,
          process_id: execution.process_id,
          deployment_id: execution.deployment_id,
          execution_name: execution.name,
          description: execution.description,
          new_trigger_type: 'CRON',
          old_trigger_type: 'CRON',
        },
      })
      .then((result) => {
        message.success({ content: 'Successfully changed CRON expression' });
        setIsLoading(false);
        if (props.onClose) props.onClose();
      })
      .catch((error) => {
        message.error({
          content: 'Error while trying to change CRON expression: ' + error,
        });
        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...modalProps}
      title={'Edit Cron Expression'}
      footer={null}
      width={600}
      destroyOnClose
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        initialValues={{
          expression: cron.expression,
          timezone: cron.timezone,
          //calendar_id: cron.calendar_id,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="CRON Expression"
          name="expression"
          rules={[{ required: true, message: 'Please input CRON expression' }]}
        >
          <CronExpressionInput />
        </Form.Item>
        <Form.Item
          label="Timezone"
          name="timezone"
          rules={[{ required: true, message: 'Please select a Timezone' }]}
        >
          <TimeZoneSelector />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  label="Calendar"*/}
        {/*  name="calendar_id"*/}
        {/*  rules={[{ required: false, message: 'Please select a Calendar' }]}*/}
        {/*>*/}
        {/*  <CalendarSelector />*/}
        {/*</Form.Item>*/}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Update Expression
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CronEditModal;
