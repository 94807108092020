import { gql } from '@apollo/client';

export const udmReferencesAll = gql`
  query {
    udm_reference (order_by: {identifier: asc}) {
      created_at
      data_type
      domain
      identifier
      meta
      schema
      subdomain
      updated_at
    }
  }
`;

export default udmReferencesAll;
