import { Select, Space, Form } from 'antd';
import { useEffect, useState } from 'react';
import ButtonAdd from '../AdvancedForm/FormComponents/ButtonAdd';

const { Option } = Select;

export const ExecutionSelect = ({ options, onAdd }) => {
  const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (!options || !options?.some((option) => option?.name?.includes(selected))) setSelected(null);
    }, [options]);

  const handleChange = (value) => {
    setSelected(value);
  };

  const handleClick = () => {
    if (onAdd) {
      onAdd(selected);
      setSelected(null);
    }
  };

  if (!options) return <></>;

  return (
    <Space style={{ width: '100%', alignItems: 'start' }}>
      <Form.Item name="selectedOption">
        <Select
          value={selected}
          showSearch
          style={{ width: 200 }}
          onChange={handleChange}
          placeholder="Search for Executions"
          optionFilterProp="children"
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {options?.map(({ id, name }) => {
            return (
              <Option key={id} value={name}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <ButtonAdd onClick={handleClick} disabled={!selected} />
    </Space>
  );
};
