import JsonEdit from "../../../InputComponents/JsonEdit";
import {PropertiesContainer} from "./PropertiesContainer";
import {ExeqtObjectsDropdownControlledFormField} from "./ExeqtObjectsDropdownControlledFormField";

export const ExeqtObjectFieldBody = (props) => {

    if (props.editJson) {
        return <JsonEdit {...props} readonly={props.disabled}/>;
    }

    if (props.enhancedSchema?.properties) {
        // return <PropertiesContainer enhancedSchema={props.enhancedSchema}/>
        return <>{props.children}</>
    }

    if (props.enhancedSchema?.anyOf || props.enhancedSchema?.oneOf || props.enhancedSchema?.allOf) {
        // return <ExeqtObjectsDropdownControlledFormField enhancedSchema={props.enhancedSchema}/>
        // TODO work on this or the uiSchemaBuilder for the visualisation of the children
        return <>{props.children}</>
    }

    return <>{props.children}</>


}