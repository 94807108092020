import { gql } from '@apollo/client';

export const environmentAllById = gql`
  query environmentById($id: Int!) {
    environment_by_pk(id: $id) {
      id
      image
      updated_at
      created_at
      packages {
        context_data
        created_at
        environment_id
        gitlink
        id
        name
        state
        type
        updated_at
        version
      }
    }
  }
`;

export default environmentAllById;
