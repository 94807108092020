import { gql } from '@apollo/client';

export const createFlowRun = gql`
  mutation CreateFlowRun(
    $flow_id: uuid = ""
    $name: String = ""
    $parameters: jsonb = ""
    $deployment_id: uuid = ""
    $expected_start_time: timestamptz = ""
    $next_scheduled_start_time: timestamptz = ""
  ) {
    insert_oe_flow_run_one(
      object: {
        flow_id: $flow_id
        name: $name
        parameters: $parameters
        work_queue_name: "default"
        work_queue_id: "f0305f2c-7338-4d6c-97ec-865a6d575289"
        deployment_id: $deployment_id
        expected_start_time: $expected_start_time
        state_name: "Scheduled"
        state_type: "SCHEDULED"
        next_scheduled_start_time: $next_scheduled_start_time
      }
    ) {
      id
    }
  }
`;