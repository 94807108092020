import { gql } from '@apollo/client';

export const runlogsByFlowRunId = gql`
  subscription RunLogsByFlowRunId ($flow_run_id: uuid!) {
    oe_log(where: { flow_run_id: { _eq: $flow_run_id } }, order_by: { timestamp: asc }) {
      id
      message
      name
      level
      timestamp
      flow_run_id
      task_run {
        id
        name
      }
    }
  }
`;

export default runlogsByFlowRunId;
