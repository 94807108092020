import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export const DocumentationPageBreadcrumb = (): JSX.Element => {
  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: 'Documentation',
    },
  ];

  return <Breadcrumb style={{ marginBottom: '20px' }} items={items} />;
};
