import { gql } from '@apollo/client';

const processDelete = gql`
  mutation ($process_id: Int!) {
    delete_process_by_pk(id: $process_id) {
      id
    }
  }
`;

export default processDelete;
