import { gql } from '@apollo/client';

const udmReferenceCreate = gql`
  mutation udmReferenceCreate(
    $domain: String = ""
    $identifier: String!
    $schema: jsonb = ""
    $meta: jsonb = ""
    $subdomain: String = ""
    $data_type: String!
  ) {
    insert_udm_reference_one(
      object: {
        domain: $domain
        identifier: $identifier
        schema: $schema
        meta: $meta
        subdomain: $subdomain
        data_type: $data_type
      }
    ) {
      identifier
    }
  }
`;

export default udmReferenceCreate;
