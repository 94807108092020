import { gql } from '@apollo/client';

const alertInsert = gql`
  mutation ($user_id: uuid!, $execution_id: Int!, $type: String!) {
    insert_alert_one(
      on_conflict: {
        constraint: alert_type_execution_id_user_id_key
        update_columns: updated_at
      }
      object: { user_id: $user_id, execution_id: $execution_id, type: $type }
    ) {
      id
      execution_id
      last_fired
      type
    }
  }
`;

export default alertInsert;
