import { useEffect, useState } from 'react';
import { Button, Modal, message } from 'antd';

import packageInsert from 'common/graphql/mutations/packageInsert';
import packageUpdate from 'common/graphql/mutations/packageUpdate';

import { useApolloClient } from '@apollo/client';
import AdvancedForm from '../../shared/AdvancedForm/AdvancedForm';

export const CustomPackageEditModal = (props) => {
  const { data, onDataChange, ...modalProps } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ key: null, value: null });

  useEffect(() => {
    if (data)
      return setFormData({
        name: data.name,
        version: data.version,
        type: data.type,
        gitlink: data.gitlink,
        gittoken: data.gittoken,
      });
    return setFormData({
      name: '',
      version: '',
      type: '',
      gitlink: '',
      gittoken: '',
    });
  }, [data]);

  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
      type: {
        type: 'string',
        enum: ['CUSTOM_GIT', 'CUSTOM_PIP'],
      },
      version: {
        type: 'string',
      },
      gitlink: {
        type: 'string',
      },
      gittoken: {
        type: 'string',
      },
    },
    required: ['name', 'type'],
  };

  const handleDataChange = (e) => {
    setFormData(e.formData);
  };

  const handleFormReset = () => {
    if (data) setFormData(data);
    else
      setFormData({
        name: '',
        version: '',
        type: '',
        gitlink: '',
        gittoken: '',
      });
  };

  const onSubmit = () => {
    setLoading(true);
    if (!data) {
      client
        .mutate({
          mutation: packageInsert,
          variables: {
            name: formData.name,
            version: formData.version,
            type: formData.type,
            gitlink: formData.gitlink,
            gittoken: formData.gittoken,
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully added Package.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to add Package: ' + error,
          });
          setLoading(false);
        });
    } else {
      client
        .mutate({
          mutation: packageUpdate,
          variables: {
            id: data.id,
            name: formData.name,
            version: formData.version,
            type: formData.type,
            gitlink: formData.gitlink,
            gittoken: formData.gittoken,
          },
        })
        .then((result) => {
          message.success({ content: 'Succesfully updated Package.' });
          setLoading(false);
          if (onDataChange) onDataChange();
          onClose();
        })
        .catch((error) => {
          message.error({
            content: 'Error while trying to update Package: ' + error,
          });
          setLoading(false);
        });
    }
  };

  const formChanged = () => {
    if (!data) {
      if (formData) return true;
    } else
      return (
        data.name !== formData.name ||
        data.version !== formData.version ||
        data.type !== formData.type ||
        data.gitlink !== formData.gitlink ||
        data.gittoken !== formData.gittoken
      );
  };

  const onClose = () => {
    handleFormReset();
    if (modalProps.onClose) modalProps.onClose();
  };
  const onCancel = () => {
    handleFormReset();
    if (modalProps.onCancel) modalProps.onCancel();
  };

  const title = () => {
    if (!data) return 'Add Package: ';
    else return 'Edit Package';
  };

  return (
    <Modal
      {...modalProps}
      title={title()}
      width={800}
      onCancel={onCancel}
      onClose={onClose}
      destroyOnClose
      footer={[
        <Button
          key="resetButton"
          onClick={handleFormReset}
          hidden={!formChanged}
        >
          Reset
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {!data ? 'Create' : 'Update'} Package
        </Button>,
      ]}
    >
      <AdvancedForm
        schema={schema}
        formData={formData}
        onChange={handleDataChange}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default CustomPackageEditModal;
