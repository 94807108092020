import { gql } from '@apollo/client';

const permissionDelete = gql`
  mutation ($id: Int!) {
    delete_auth_permission_by_pk(id: $id) {
      id
    }
  }
`;

export default permissionDelete;
