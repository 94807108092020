import React from 'react';

import { Card, Col, Row } from 'antd';
import { DataInterfaceUsage } from './DataInterfaceUsage/DataInterfaceUsage';

const getColumn = (index, child) => {
  let xxlValue;
  if (index < 3) {
    xxlValue = 12;
  } else {
    xxlValue = 24;
  }

  // for the index 2 (second row)
  if (React.isValidElement(child) && child.type === DataInterfaceUsage) {
    return child;
  }

  if (React.isValidElement(child) && child.type === Col) {
    return child;
  }

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={xxlValue} key={index}>
      <Card style={{ height: '100%' }}>{child}</Card>
    </Col>
  );
};

export const DataInterfaceGrid = ({ children }) => {
  if (!children) return null;

  return (
    <Row gutter={[16, 16]}>
      {children.map((child, index) => getColumn(index, child))}
    </Row>
  );
};