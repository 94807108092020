import { Form, Input } from 'antd';
import ButtonRemove from './../AdvancedForm/FormComponents/ButtonRemove';
import { ExecutionSelect } from './ExecutionSelect';

const ChainSettings = ({ processesSelect, isChain, chainOptions, form }) => {
  if (!isChain) return null;

  return (
    <Form.Item
      label={'Chain Settings'}
      hidden={!processesSelect}
      required={true}
    >
      <Form.List
        name="chain__upstream_executions"
        rules={[
          {
            validator: (_, value) => {
              if (value?.length > 0) return Promise.resolve();
              return Promise.reject(new Error('At least one execution is required'));
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          const listFields = form.getFieldValue('chain__upstream_executions');
          return (
            <>
              {fields.map((field) => (
                <Form.Item
                  key={field.key}
                  required={false}
                  wrapperCol={{ span: 24, offset: 0 }}
                  help={field.errors}
                >
                  <Form.Item {...field} noStyle>
                    <Input disabled={true} style={{ width: '60%' }} />
                  </Form.Item>
                  <ButtonRemove onClick={() => remove(field.name)} />
                </Form.Item>
              ))}

              <ExecutionSelect
                options={chainOptions.filter(
                  ({ name }) => !listFields?.includes(name),
                )}
                onAdd={add}
              />
              <Form.ErrorList errors={errors} />
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

export default ChainSettings;
