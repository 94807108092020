import {useState} from 'react';
import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';

import {message, Button, Space, Typography} from 'antd';
import {useApolloClient} from '@apollo/client';
import {CaretRightOutlined} from '@ant-design/icons';

import BasicTooltipButton from 'components/shared/BasicTooltipButton';

import {updateFlowRunToRerun} from "../../../common/graphql/mutations/updateFlowRunToRerun";
import {createFlowRunStateForReRuns} from "../../../common/graphql/mutations/createFlowRunStateForReRuns";

const RunSuccessMessage = (props) => {
    const {onClick} = props;
    return (
        <Space>
            <Typography.Text>Successfully started Rerun</Typography.Text>
            <Button
                icon={<CaretRightOutlined style={{color: 'white'}}/>}
                size="small"
                type="primary"
                style={{marginLeft: '10px'}}
                onClick={onClick}
            >
                Go To Run
            </Button>
        </Space>
    );
};

export const RunReRunButton = (props) => {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleRedirect = (id) => {
        const path = generatePath('/ops/runs/:id?', {id: id});
        history.push(path);
    };

    const handleClick = async (event) => {
        event.stopPropagation();
        if (!props.flowRunId) return console.log('No run_id parameter');

        setLoading(true);
        try {
            // Second mutation to create the flow run state
            const createFlowRunStateResult = await client.mutate({
                mutation: createFlowRunStateForReRuns,
                variables: {
                    flow_run_id: props.flowRunId,
                },
            });
            // Extracting the state ID from the result
            const {id: stateId,timestamp } = createFlowRunStateResult.data.insert_oe_flow_run_state_one;

            // Mutation to update the flow run with the new state ID
            const now = new Date();
            const isoTimestamp = now.toISOString()
            const updatedIsoTimestamp = now.toISOString();
            await client.mutate({
                mutation: updateFlowRunToRerun,
                variables: {
                    flow_run_id: props.flowRunId,
                    state_id: stateId,
                    state_timestamp: timestamp,
                    expected_start_time: updatedIsoTimestamp,
                    updated: isoTimestamp,
                },
            });

            message.success(
                <RunSuccessMessage
                    onClick={() => handleRedirect(props.flowRunId)}
                />,
                5,
            );

        } catch (error) {
            message.error({
                content: 'Error while trying to  start Rerun. ' + error,
            });
        } finally {
            setLoading(false);

        }
    }

    return (
        <BasicTooltipButton
            tooltip={'Start Rerun'}
            {...props}
            loading={loading}
            onClick={handleClick}
        />
    );
};

export default RunReRunButton;
