import { TaskInterfaceArrow } from './TaskInterfaceArrow';
import { TaskInterfaceDataStructure } from './TaskInterfaceDataStructure';
import { TaskInterfaceDatabaseClient } from './TaskInterfaceDatabaseClient';

export const TaskInterfaceStore = ({
  interfaceName,
  data,
  interfaceFunction,
}) => {
  return (
    <>
      <TaskInterfaceDataStructure {...{ data }} />
      <TaskInterfaceArrow {...{ interfaceFunction }} />
      <TaskInterfaceDatabaseClient {...{ interfaceName }} />
    </>
  );
};
