import {gql} from '@apollo/client';

export const actionExecutionUpdate = gql`
    mutation ExecutionUpdate(
        $model_name: String!,
        $execution_id: Int!,
        $project_name: String!,
        $schedule: jsonb,
        $process_id: Int!,
        $deployment_id: uuid!,
        $execution_name: String,
        $description: String,
        $new_trigger_type: String,
        $old_trigger_type: String,
        $parameters: jsonb,
    ) {
        execution_update(
          model_name: $model_name,
          execution_id: $execution_id,
          project_name: $project_name,
          schedule: $schedule,
          process_id: $process_id,
          deployment_id: $deployment_id,
          execution_name: $execution_name,
          description: $description,
          new_trigger_type: $new_trigger_type,
          old_trigger_type: $old_trigger_type,
          parameters: $parameters,
        ) {
          success
          status
          details
        }
    }
`;