import { gql } from '@apollo/client';

export const cdmReferencesAll = gql`
  query {
    cdm_reference {
      created_at
      default_active
      description
      identifier
      timezone
      type
      updated_at
    }
  }
`;

export default cdmReferencesAll;
