import { gql } from '@apollo/client';

export const modelsAll = gql`
  subscription {
    model (order_by: [{project:{name:asc}}, {name: asc}]){
      id
      created_at
      updated_at
      project_id
      gitlink
      always_on
      deployment_id
      flow_id
      project {
        name
      }
      deployment {
        name
        description
        parameter_openapi_schema
      }
      owner
    }
  }
`;

export default modelsAll;
