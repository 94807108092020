import { Collapse, Typography } from 'antd';
import { ParameterEditForm } from '../ParameterEditForm';

const { Text } = Typography;
const { Panel } = Collapse;

export const ModelInfoCollapseModelParameter = (props) => {
  const { model, handleParameterChange, expand, onError, cleanErrors } = props;

  if (expand)
    return (
      <Collapse ghost defaultActiveKey="" activeKey="parameter">
        <Panel header={<Text>Model Parameter:</Text>} key={'parameter'}>
          <ParameterEditForm
            child
            model={model}
            onChange={handleParameterChange}
            readonly={false}
            onError={onError}
            cleanErrors={cleanErrors}
          />
        </Panel>
      </Collapse>
    );
  else
    return (
      <Collapse ghost defaultActiveKey="">
        <Panel header={<Text>Model Parameter:</Text>} key={'parameter'}>
          <ParameterEditForm
            child
            model={model}
            onChange={handleParameterChange}
            readonly={true}
            onError={onError}
            cleanErrors={cleanErrors}
          />
        </Panel>
      </Collapse>
    );
};

export default ModelInfoCollapseModelParameter;
