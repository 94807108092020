import { useState } from 'react';
import { useQuery } from '@apollo/client';

import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import permissionAll from 'common/graphql/querys/permissionAll';
import { Space, Tag } from 'antd';
import BasicTooltipButton from 'components/shared/BasicTooltipButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import SafeDeleteIdMutationButton from 'components/shared/SafeDeleteIdMutationButton';
import permissionDelete from 'common/graphql/mutations/permissionDelete';
import PermissionEditModal from 'components/pages/UserManagementPage/PermissionEditModal';

export const PermissionTable = (props) => {
  const { loading, error, data, refetch } = useQuery(permissionAll);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  if (error) console.error(error);

  const handleChange = () => {
    refetch();
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  let transformed_data = [];
  if (data && data.auth_permission) {
    transformed_data = data.auth_permission;
  }

  let projectsList = [];
  let processesList = [];
  if (transformed_data) {
    transformed_data.forEach((r) => {
      projectsList = projectsList.concat(
        r.projects?.map((p) => p.project?.name),
      );
      processesList = processesList.concat(
        r.processes?.map((p) => p.process.name),
      );
    });
    projectsList = Array.from(new Set(projectsList)).sort();
    processesList = Array.from(new Set(processesList)).sort();
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'permission_name',
      sort: true,
      search: true,
      sortOrder: 'ascend',
      render: (state, record, index) => {
        return (
          <Tag key={state} color={'orange'}>
            {state}
          </Tag>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'permission_desc',
      sort: true,
      search: true,
    },
    {
      title: 'Type',
      dataIndex: 'permission_type',
      key: 'permission_type',
      sort: true,
      filter: true,
    },
    {
      title: 'Projects',
      dataIndex: 'projects',
      key: 'projects',
      filter: !!projectsList,
      filters: projectsList.map((p) => {
        return { text: p, value: p };
      }),
      onFilter: (value, record) =>
        record.projects?.map((p) => p.project.name).includes(value),
      render: (state, record, index) => {
        return (
          record?.projects && (
            <>
              {' '}
              {record.projects.map((p) => (
                <Tag
                  key={`${index}-${p.project?.name}`}
                  color={'blue'}
                  style={{ marginBottom: '5px', marginRight: '5px' }}
                >
                  {p.project?.name}
                </Tag>
              ))}
            </>
          )
        );
      },
    },
    {
      title: 'Processes',
      dataIndex: 'processes',
      key: 'processes',
      filter: !!processesList,
      filters: processesList.map((p) => {
        return { text: p, value: p };
      }),
      onFilter: (value, record) =>
        record.processes?.map((p) => p.process.name).includes(value),
      render: (state, record, index) => {
        return (
          record?.processes && (
            <>
              {' '}
              {record.processes.map((p) => (
                <Tag
                  key={`${index}-${p.process.name}`}
                  color={'geekblue'}
                  style={{ marginBottom: '5px', marginRight: '5px' }}
                >
                  {p.process.name}
                </Tag>
              ))}
            </>
          )
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (state, record, index) => {
        return (
          <Space>
            <BasicTooltipButton
              tooltip={'Edit Permission'}
              icon={<EditOutlined />}
              size={'small'}
              shape="circle"
              type="primary"
              onClick={() => openModal(record)}
            />
            <SafeDeleteIdMutationButton
              id={record.id}
              mutation={permissionDelete}
              onDataChange={handleChange}
              tooltip={'Delete Permission'}
              icon={<DeleteOutlined />}
              size={'small'}
              shape="circle"
              type="secondary"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div id="user-admin-permission">
      <Space style={{ marginBottom: '10px', width: '100%' }}>
        <BasicTooltipButton type="primary" onClick={() => openModal(null)}>
          Add Permission
        </BasicTooltipButton>
      </Space>
      <PermissionEditModal
        open={showModal}
        permission={modalData}
        onDataChange={handleChange}
        onClose={closeModal}
        onCancel={closeModal}
      />

      <AdvancedTable
        verticalAlign="top"
        rowKey={'id'}
        dataSource={transformed_data}
        columns={columns}
        loading={loading}
        type="permissionTable"
      />
    </div>
  );
};

export default PermissionTable;
