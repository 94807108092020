import { DatePicker, Select } from 'antd';
import { DataInterfaceDataVolumeAndRequestsPlot } from './DataInterfaceDataVolumeAndRequestsPlot';
import {DataInterfaceTimeTakenPlot} from "./DataInterfaceTimeTakenPlot";
import {useState} from "react";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const data = [
  {
    x: ['Category 1', 'Category 2', 'Category 3'],
    y: [10, 15, 13],
    type: 'bar',
    marker: {
      color: 'rgb(55, 83, 109)',
    },
  },
];

const layout = {
  title: 'Simple Bar Chart',
  xaxis: {
    tickangle: -45,
  },
  barmode: 'group',
};
export const DataInterfaceUsageRight = ({ timeTakenPlotData, setDates, dates, data }) => {
    const [bucket, setBucket] = useState('hourly');

  if (!timeTakenPlotData) return null;

  return (
    <div style={{ gridColumn: 'span 2', padding: '0 .5em' }}>
      <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
        {/* TODO connect the granularity and date range to the graphs*/}
        <Select
          defaultValue="hourly"
          options={[
            { label: 'Minutely', value: 'minutely' },
            { label: 'Hourly', value: 'hourly' },
            { label: 'Daily', value: 'daily' },
            { label: 'Monthly', value: 'monthly' },
          ]}
          onChange={(value) => setBucket(value)}
        />
        <RangePicker
          showTime
          style={{ marginLeft: '10px' }}
          onChange={(dates) =>
            setDates({
              from: dates[0].toISOString(),
              to: dates[1].toISOString(),
            })
          }
          defaultValue={[dayjs(dates?.from), dayjs(dates?.to)]}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <DataInterfaceTimeTakenPlot timeTakenPlotData={timeTakenPlotData} />
        <DataInterfaceDataVolumeAndRequestsPlot dataSource={data} bucket={bucket} />
      </div>
    </div>
  );
};