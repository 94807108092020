import { gql } from '@apollo/client';

const packageUpdate = gql`
  mutation updatePackage(
    $id: Int!
    $name: String!
    $type: String!
    $version: String!
    $gitlink: String!
    $gittoken: String!
  ) {
    update_package_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        type: $type
        version: $version
        gitlink: $gitlink
        gittoken: $gittoken
      }
    ) {
      id
    }
  }
`;

export default packageUpdate;
