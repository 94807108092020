import { DataInterfaceUsageTable } from './DataInterfaceUsageTable';

export const DataInterfaceUsageLeft = ({ dataSource }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridColumn: 'span 3',
      }}
    >
      <h2 style={{ marginBottom: '1rem' }}>Model Interface Usage</h2>
      <DataInterfaceUsageTable dataSource={dataSource} />
    </div>
  );
};