import { gql } from '@apollo/client';

const userInsertUpdate = gql`
  mutation ($id: uuid!, $email: String!, $name: String!) {
    insert_auth_user_one(
      object: { id: $id, email: $email, name: $name }
      on_conflict: { constraint: user_id_key, update_columns: last_login }
    ) {
      id
      name
      email
      created_at
      last_login
    }
  }
`;

export default userInsertUpdate;
