import { gql } from '@apollo/client';

const runInsertDirect = gql`
  mutation (
    $model_id: Int!
    $run_parameters: jsonb!
    $state: String!
    $state_message: String!
  ) {
    insert_run_one(
      object: {
        model_id: $model_id
        run_parameters: $run_parameters
        state: $state
        state_message: $state_message
      }
    ) {
      id
    }
  }
`;

export default runInsertDirect;
