import { gql } from '@apollo/client';

export const secretsAll = gql`
  query {
    secret {
      id
      key
      value
    }
  }
`;

export default secretsAll;
