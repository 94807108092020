import { Flex, Space, Tag } from 'antd';
import {RedoOutlined, DeleteOutlined, StopOutlined} from '@ant-design/icons';

import RunStateTag from 'components/shared/Run/RunStateTag';
import RunStopButton from 'components/shared/Run/RunStopButton';
import RunReRunButton from 'components/shared/Run/RunReRunButton';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';

import runDelete from 'common/graphql/mutations/runDelete';
import SafeDeleteIdMutationButton from 'components/shared/SafeDeleteIdMutationButton';
import {toTimeZone} from 'utils/utils';
import {compact} from 'lodash';
import {useContext} from "react";
import { isActiveState } from '../../../utils/flowRunStateTypes';
import FlowRunTag from './FlowRunTag';

const columns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //     align: 'left',
    //     width: 70,
    //     sort: true,
    //     ellipsis: true
    // },

    {
        title: 'Execution',
        dataIndex: 'execution',
        key: 'execution',
        width: '30%',
        ellipsis: true,
        sort: true,
        search: true,
    },
    // {
    //     title: 'Model',
    //     dataIndex: 'model',
    //     key: 'model',
    //     ellipsis: true,
    //     sort: true,
    //     search: true
    // },
    {
        title: 'Start Time',
        dataIndex: 'start_time',
        key: 'start_time',
        align: 'center',
        width: '21%',
        ellipsis: true,
        sort: true,
    },
    {
        title: 'End Time',
        dataIndex: 'end_time',
        key: 'end_time',
        align: 'center',
        width: '21%',
        ellipsis: true,
        sort: true,
    },
    {
        title: 'Tag',
        dataIndex: 'tag',
        key: 'tag',
        align: 'right',
        width: 60,
        filter: true,
        render: (state, record, index) => {
            return <FlowRunTag color  tag={state} />;
        },
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        align: 'left',
        width: 120,
        sort: true,
        filter: true,
        render: (state, record, index) => {
            return <RunStateTag state={state} key={state} message={record.message}/>;
        },
    },
    {
        title: 'Actions',
        key: 'action',
        width: 75,
        render: (state, record, index) => {
            return (
                <Flex gap="2px 2px" justify={"center"} wrap="nowrap">
                    {record.state === 'Running' ||
                    record.state === 'Scheduled' ||
                    record.state === 'Pending' ||
                    record.state === 'User Terminate' ? (
                        <RunStopButton
                            disabled={record.state === 'User Terminate'}
                            icon={<StopOutlined/>}
                            size={'small'}
                            shape="circle"
                            type="danger"
                            tooltipPlacement={'right'}
                            tooltipMouseEnterDelay={1}
                            run_id={record.id}
                        />
                    ) : (
                        <>
                            <RunReRunButton
                                icon={<RedoOutlined/>}
                                size={'small'}
                                shape="circle"
                                type="primary"
                                tooltipPlacement={'left'}
                                tooltipMouseEnterDelay={1}
                                flowRunId={record.id}
                            />
                            <SafeDeleteIdMutationButton
                                icon={<DeleteOutlined/>}
                                size={'small'}
                                shape="circle"
                                type="secondary"
                                id={record.id}
                                mutation={runDelete}
                                tooltip={'Delete Run'}
                                tooltipPlacement={'right'}
                                tooltipMouseEnterDelay={1}
                            />
                        </>
                    )}
                </Flex>
            );
        },
    },
];

export const RunsTable = (props) => {
    const {runs, onRunClick, type, ...tableProps} = {...props};

    let transformed_data = [];
    if (runs) {
        transformed_data = compact(
            runs.map((run) => {
                //console.log(run.state_type);
                const state_type = run.state_type.charAt(0).toUpperCase() + run.state_type.slice(1).toLowerCase()
                let execution_name = run.deployment?.execution ? run.deployment.execution.name : run.name
                let tag = 'auto-scheduled'
                if(!run.state)
                    tag = 'Deleted'
                if(run.tags?.length && run.tags.includes('adhoc-run')) {
                    execution_name = run.name
                    tag = 'adhoc-run'
                }

                return {
                    id: run.id,
                    execution: execution_name,
                    start_time: toTimeZone(run.start_time || run.expected_start_time),
                    end_time: toTimeZone(run.end_time),
                    state: state_type,
                    message: run.state?.message,
                    key: run.id,
                    model_key: run.model?.id,
                    tag: tag
                };
            }),
        );
    }

    const onRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                // console.log(record.key);
                if (onRunClick) onRunClick(record.key);
            },
        };
    };

    return (
        <AdvancedTable
            className="runs-table"
            {...tableProps}
            size="small"
            dataSource={transformed_data}
            columns={columns}
            pagination={{hideOnSinglePage: true}}
            onRow={onRow}
            type={type}
        />
    );
};

export default RunsTable;
