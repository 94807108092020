import {Button} from "antd";
import {CaretRightOutlined, DoubleLeftOutlined} from "@ant-design/icons";
import {handleBack, handleSetExecution, handleCreateRun, handleRedirect} from "../utils";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {RunNowButton} from "./RunNowButton";

export const ModelEditModalFooter = ({
                                         setIsCreateRun,
                                         setIsCreateExecution,
                                         isCreateRun,
                                         adhocRunMode,
                                         createdRunId,
                                         model,
                                         parameters,
                                         onClose,
                                         setCreatedRunId,
                                         isCreateExecution,
                                         executionSettingForm,
                                         isBtnSaveExecutionLoading,
                                     }) => {

    const history = useHistory();
    const [isRunNowLoading, setIsRunNowLoading] = useState(false);


    const flowId = model.flow_id

    const runNowProps = {
        setIsRunNowLoading,
        model,
        parameters,
        handleRedirect: handleRedirect(history),
        adhocRunMode,
        onClose,
        setCreatedRunId,
        flowId,
    };


    if (isCreateRun) {
        return [
            !adhocRunMode && (
                <Button key="back" onClick={handleBack(setIsCreateRun, setIsCreateExecution, setCreatedRunId)}
                        icon={<DoubleLeftOutlined/>}>
                    Back
                </Button>
            ),
            <RunNowButton
                key="runNow"
                createdRunId={createdRunId}
                isRunNowLoading={isRunNowLoading}
                runNowProps={runNowProps}
            />,
        ];
    } else if (isCreateExecution)
        return [
            <Button key="back" onClick={handleBack(setIsCreateRun, setIsCreateExecution, setCreatedRunId)}
                    icon={<DoubleLeftOutlined/>}>
                Back
            </Button>,
            <Button
                key="saveExecution"
                onClick={() => executionSettingForm.submit()}
                icon={<CaretRightOutlined/>}
                type={'primary'}
                loading={isBtnSaveExecutionLoading}
            >
                Save Execution
            </Button>,
        ];

    return [
        <Button
            key="creatRun"
            onClick={handleCreateRun(setIsCreateRun, setIsCreateExecution)}
        >
            Create Run
        </Button>,
        <Button
            key="creatExecution"
            onClick={handleSetExecution(setIsCreateRun, setIsCreateExecution)}
        >
            Create Execution
        </Button>,
    ];
};

