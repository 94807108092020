import { Breadcrumb, Button, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import BasicTooltipButton from '../../shared/BasicTooltipButton';
import './UDMPageDataManagementHeader.css';

export const UDMPageDataManagementHeader = ({ openModal }) => {
    const items = [
        {
            title: <Link to="/">Home</Link>
        },
        {
                title: 'UDM Data Management'
        }
    ]

  return (
    <>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Space
        className="udm-space-header"
        style={{ marginBottom: '10px', width: '100%' }}
      >
        <Row>
          <BasicTooltipButton type="primary" onClick={() => openModal(null)}>
            Add Reference
          </BasicTooltipButton>
          <Button
            type="primary"
            style={{ marginLeft: 'auto' }}
          >
            <Link to="/data/interface/udm">Switch to Data Interface</Link>
          </Button>
        </Row>
      </Space>
    </>
  );
};