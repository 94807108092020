import {Tag, Tooltip} from 'antd';
import {CloseCircleOutlined, ClockCircleOutlined, WarningOutlined, BugOutlined} from '@ant-design/icons';


const lookUp = {
    0: <Tag color="default">NOTSET</Tag>,
    10: <Tag icon={<BugOutlined />} color="default">DEBUG</Tag>,
    20: <Tag icon={<ClockCircleOutlined/>} color="default">INFO</Tag>,
    30: <Tag icon={<WarningOutlined />} color="default">WARNING</Tag>,
    40: <Tag icon={<CloseCircleOutlined/>} color="error">ERROR</Tag>,
    50: <Tag icon={<CloseCircleOutlined/>}  color="error">CRITICAL</Tag>,
}

export const LogLevelTag = (props) => {

    if (props.message) {
        return (
            <Tooltip placement="top" title={props.message}>
                {lookUp[props.state]}
            </Tooltip>
        );
    }
    return <>{lookUp[props.state]}</>
};

export default LogLevelTag;
