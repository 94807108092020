import { gql } from '@apollo/client';

export const environmentsAll = gql`
  query {
    environment(order_by: { created_at: asc }) {
      id
      image
      python_version
      created_at
      updated_at
    }
  }
`;

export default environmentsAll;
