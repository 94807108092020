import { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { useApolloClient } from '@apollo/client';

import alertDelete from 'common/graphql/mutations/alertDeleteById';
import { AlertOutlined } from '@ant-design/icons';

export const AlertDeleteButton = (props) => {
  const client = useApolloClient();
  const { alert, ...btnProps } = props;
  const [loading, setLoading] = useState(false);

  const btnSTyle = () => {
    if (alert.type === 'onFailed')
      return { color: 'red', background: 'transparent', borderColor: 'red' };
    if (alert.type === 'onSuccess')
      return {
        color: 'green',
        background: 'transparent',
        borderColor: 'green',
      };
    return { color: 'grey', background: 'transparent', borderColor: 'grey' };
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    if (!props.alert) return console.log('No alert parameter');

    setLoading(true);
    client
      .mutate({ mutation: alertDelete, variables: { id: alert.id } })
      .then(() => {
        message.success({ content: 'Successfully deleted Alert' });
        setLoading(false);
      })
      .catch((error) => {
        message.error({
          content: 'Error while trying to delete Alert:' + error,
        });
        setLoading(false);
      });
  };

  return (
    <Popconfirm
      placement="top"
      title={'Do you want to delete this Alert?'}
      onConfirm={handleDelete}
      onCancel={(e) => e.stopPropagation()}
      okText="Yes"
      cancelText="No"
    >
      <Button
        {...btnProps}
        loading={loading}
        shape="round"
        style={btnSTyle()}
        icon={<AlertOutlined />}
        onClick={(e) => e.stopPropagation()}
      >
        {alert.type}
      </Button>
    </Popconfirm>
  );
};

export default AlertDeleteButton;
