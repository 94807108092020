export const isMultiSelectArray = (schemaObj) => {
    return schemaObj.type === 'array' && schemaObj.subtype === 'multi_select';
}

export const isRegularArray = (schemaObj) => {
    return schemaObj.type === 'array' && schemaObj.items;
}

export const isTimezoneString = (schemaObj) => {
    return schemaObj.type === 'string' && schemaObj.subtype === 'timezone';
}

export const isDateFixedRelative = (schemaObj) => {
    return (
        schemaObj.anyOf &&
        Array.isArray(schemaObj.anyOf) &&
        schemaObj.anyOf.every(
            (option) =>
                option.type === 'string' &&
                ['Date', 'Datetime', 'Relative', 'Date & Time'].includes(option.title),
        )
    );
}

export const isDataUrlString = (schemaObj) => {
    return schemaObj?.type === 'string' && schemaObj?.format === 'data-url';
}

export const hasRef = (value) => {
    // anyOf is often an item used for the selection between date and relative
    // But it may also contain multiple ref objects
    // Thus I added the last condition to check if anyOf contains a nested ref
    return value.hasOwnProperty('$ref') || ((value.hasOwnProperty('allOf') || value.hasOwnProperty('anyOf')) && hasNestedRef(value));
}

export const extractRefValues = (value) => {
    if (value.allOf) {
        // TODO case where allOf contains multiple refs
        if (value.allOf.length > 1) {
            return value.allOf
        }
        return value.allOf[0]?.$ref;
    } else if (value.items) {
        return value.items?.$ref;
    } else if (value.anyOf) {
        if (value.anyOf.length > 1) {
            // case: multiple refs
            // the schema delivers sometimes a 'null' string type. catch it and ignore this case
            // I would have done where item?.$ref but
            // I was not sure if there might be a case where anyOf contains an object
            return value.anyOf.filter((item) => item.type !== 'null');
        }
        return value.anyOf[0]?.$ref;

    } else {
        return value.$ref;
    }
}

export const isUndefinedType = (schemaObj) => {
    return typeof schemaObj === 'undefined';
}

export const isObjectType = (schemaObj) => {
    return schemaObj?.type === 'object';
}

export const isArrayType = (schemaObj) => {
    return schemaObj?.type === 'array';
}

export const hasNestedRef = (obj) => {
    // Check if the current object itself is the $ref property
    if (obj.hasOwnProperty('$ref')) {
        return true;
    }

    // Iterate over all properties of the object
    for (const key in obj) {
        // Make sure the property is directly on the object
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            // If the property is an object, call the function recursively
            if (typeof value === 'object' && value !== null) {
                if (hasNestedRef(value)) {
                    return true;
                }
            }
        }
    }

    // Return false if no $ref is found
    return false;
}

export const setTitleInAnyOfAllOfOneOf = (schemaProperties) => {
    if (!schemaProperties) return;

    // Helper function to process each of the special cases
    const processSpecialCase = (caseArray, caseName) => {
        caseArray.forEach((item, index) => {
            if (item.$ref) {
                // Extract the last part of the $ref as title
                const definitionName = item.$ref.split('/').pop();
                item.title = definitionName.replace(/([A-Z])/g, ' $1').trim();
            } else {
                // case: optional parameter
                if (item.type === 'null') {
                    item.title = 'None';
                } else {
                    // If there is no $ref, use a generic title or any other logic
                    item.title = `${caseName} Option ${index + 1}`;
                    // Recursive call if the item is an object itself
                    if (typeof item === 'object') {
                        setTitleInAnyOfAllOfOneOf(item);
                    }
                }
            }
        });
    };

    // Process the special cases if they exist in the current schema
    if (schemaProperties.anyOf) {
        processSpecialCase(schemaProperties.anyOf, 'AnyOf');
    }
    if (schemaProperties.allOf) {
        processSpecialCase(schemaProperties.allOf, 'AllOf');
    }
    if (schemaProperties.oneOf) {
        processSpecialCase(schemaProperties.oneOf, 'OneOf');
    }

    // Recursively apply the function to each property if it's an object
    Object.keys(schemaProperties.properties || schemaProperties.items || {}).forEach(key => {
        if (schemaProperties.properties && typeof schemaProperties.properties[key] === 'object') {
            setTitleInAnyOfAllOfOneOf(schemaProperties.properties[key]);
        } else if (typeof schemaProperties.items === 'object') {
            setTitleInAnyOfAllOfOneOf(schemaProperties.items);
        }
    });
};

export const setFormDefaults = (schema, definitions) => {
    // Return the default value if it exists.
    if ('default' in schema) {
        return schema.default;
    }

    // Handle object types with properties.
    if (schema.type === 'object' && schema.properties) {
        return Object.entries(schema.properties).reduce((acc, [key, prop]) => {
            if ('default' in prop) {
                acc[key] = prop.default;
            } else if (prop.properties) {
                acc[key] = setFormDefaults(prop, definitions);
            } else if (prop.allOf && prop.allOf.some((item) => '$ref' in item)) {
                const refSchema = prop.allOf.find((item) => '$ref' in item);
                const definitionsKey = refSchema.$ref.split('/').pop();
                acc[key] = definitions[definitionsKey] ? setFormDefaults(definitions[definitionsKey], definitions) : undefined;
            }
            // Additional conditions (e.g., 'anyOf', 'oneOf') can be added here.
            return acc;
        }, {});
    }

    // Handle array types if needed.
    if (schema.type === 'array') {
        // Implement array handling logic or return a default value for arrays.
        return []; // Example default, adjust as needed.
    }

    // For other types or cases where no default is found, return undefined.
    return undefined;
};

export const transformValuesFromNulltoUndefined = (formData) => {
    if (!formData)
        return undefined;

    Object.entries(formData).forEach(([key, value]) => {
        if (value === null) {
            formData[key] = undefined;
        } else if (Array.isArray(value)) {
            formData[key] = value.map(transformValuesFromNulltoUndefined);
        } else if (typeof value === 'object') {
            formData[key] = transformValuesFromNulltoUndefined(value);
        }
    });
    return formData;
}