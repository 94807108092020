import { gql } from '@apollo/client';

export const updateFlowRunWithStateId = gql`
    mutation UpdateFlowRunWithStateId(
        $flow_run_id: uuid!,
        $state_id: uuid!
    ) {
        update_oe_flow_run(
            where: { id: { _eq: $flow_run_id } },
            _set: {
                state_id: $state_id
            }
        ) {
            returning {
                id
            }
        }
    }
`;
