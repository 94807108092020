import { isEmpty } from 'lodash';

export const setDataManagementInfo = (interfaceInfos) => {
  if (!interfaceInfos || isEmpty(interfaceInfos)) return;
  if (interfaceInfos.length === 1) {
    const clientNames = setClientName(interfaceInfos[0]);
    return { clientNames, requestType: interfaceInfos[0].request_type };
  }

  return interfaceInfos.reduce((result, interfaceInfo) => {
    const clientName = setClientName(interfaceInfo);
    // I need the type only once. There wont be load and store in the same interface
    if (!result.requestType) result.requestType = interfaceInfo.request_type;
    // I dont need duplicates, thats why I use a Set
    result.clientNames.add(clientName);
    return result;
  }, {requestType: '', clientNames: new Set()});
};

const setClientName = ({ interface_name }) => {
  return interface_name.slice(0, 3);
};

const successStyles = {
  backgroundColor: '#C6F4DF',
  color: '#4D4D4D',
};

const rerunSuccessStyles = {
  backgroundColor: '#bbd5fd',
  color: '#4D4D4D',
};

const errorStyles = {
  backgroundColor: '#FF5E5F',
  color: '#4D4D4D',
};



const lookup = {
  Completed: successStyles,
  Failed: errorStyles,
  Retrying: rerunSuccessStyles,
};
export const getStyle = (state) => {
  return lookup[state] || null;
};
