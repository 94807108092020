import JsonViewButton from "../../shared/JsonViewButton";
import {BuildOutlined, DeleteOutlined, EditOutlined, EyeOutlined, GatewayOutlined} from "@ant-design/icons";
import {Space} from "antd";
import BasicTooltipButton from "../../shared/BasicTooltipButton";
import {generatePath} from "react-router";
import SafeDeleteIdMutationButton from "../../shared/SafeDeleteIdMutationButton";
import udmReferenceDelete from "../../../common/graphql/mutations/udmReferenceDelete";
import {useHistory} from "react-router-dom";
import AdvancedTable from "../../shared/AdvancedTable/AdvancedTable";


const setColumns = (handleChange, openModal, history) => [
  {
    title: 'Identifier',
    dataIndex: 'identifier',
    key: 'identifier',
    sort: true,
    search: true,
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    sort: true,
    filter: true,
  },
  {
    title: 'SubDomain',
    dataIndex: 'subdomain',
    key: 'subdomain',
    sort: true,
    search: true,
    filter: true,
  },
  {
    title: 'Data Type',
    dataIndex: 'data_type',
    key: 'data_type',
    sort: true,
    filter: true,
  },
  {
    title: 'Schema',
    dataIndex: 'schema',
    key: 'schema',
    sort: true,
    ellipsis: true,
    render: (state, record, index) => {
      return (
        <JsonViewButton
          icon={<GatewayOutlined />}
          type={'secondary'}
          jsonData={JSON.stringify(record.schema)}
          title={record.identifier}
        />
      );
    },
  },
  {
    title: 'Meta Data',
    dataIndex: 'meta',
    key: 'meta',
    sort: true,
    ellipsis: true,
    render: (state, record, index) => {
      return (
        <JsonViewButton
          icon={<BuildOutlined />}
          type={'secondary'}
          jsonData={JSON.stringify(record.meta)}
          title={record.identifier}
        />
      );
    },
  },
  {
    title: 'Actions',
    key: 'action',
    width: 150,
    render: (state, record, index) => {
      return (
        <Space>
          <BasicTooltipButton
            tooltip={'Show Data'}
            icon={<EyeOutlined />}
            size={'medium'}
            shape="circle"
            type="primary"
            onClick={(event) => {
              event.stopPropagation();
              const path = generatePath('/data/udm/:identifier', {
                identifier: record.identifier,
              });
              history.push(path);
            }}
          />

          <BasicTooltipButton
            tooltip={'Edit Reference'}
            icon={<EditOutlined />}
            size={'medium'}
            shape="circle"
            type="primary"
            onClick={(event) => {
              event.stopPropagation();
              openModal(record);
            }}
          />
          <SafeDeleteIdMutationButton
            id={record.identifier}
            idKey="identifier"
            mutation={udmReferenceDelete}
            onDataChange={handleChange}
            tooltip={'Delete Reference'}
            icon={<DeleteOutlined />}
            size={'medium'}
            shape="circle"
            type="secondary"
          />
        </Space>
      );
    },
  },
];

export const UDMPageTable = ({error, transformed_data, loading, handleChange, openModal}) => {
  const history = useHistory();

  return (
    <>
      {error && <p>Error</p>}
      {!error && (
        <AdvancedTable
          rowKey="identifier"
          dataSource={transformed_data}
          columns={setColumns(handleChange, openModal, history)}
          loading={loading}
          pagination={{showSizeChanger: true,  defaultPageSize: 15, pageSizeOptions: ['10', '15', '20', '30', '50']}}
          type="udmPageTable"
        />
      )}
    </>
  );
};