import { gql } from '@apollo/client';

const actionGetExeqtPipelines = gql`
  query ($ref: String!) {
    exeqt_builder_pipeline_status(ref: $ref) {
      data
    }
  }
`;

export default actionGetExeqtPipelines;
