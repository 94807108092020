import TimeZoneSelector from '../InputComponents/TimeZoneSelector';
import BasicFieldLayout from '../Layout/BasicFieldLayout';

const ExeqtTimezoneEditField = (props) => {
  const { formData, onChange } = props;

  return (
    <BasicFieldLayout {...props}>
      <TimeZoneSelector value={formData} onChange={onChange} />
    </BasicFieldLayout>
  );
};

export default ExeqtTimezoneEditField;
