import { gql } from '@apollo/client';

export const executionsFromProcess = gql`
  subscription ($process_id: Int!) {
    execution(where: { process_id: { _eq: $process_id } }) {
      id
      description
      name
      parameters
      last_successful_run
      process_id
      trigger_type
      deployment_id
      deployment {
        parameters
        parameter_openapi_schema
      }
      model {
        project {
          name
        }
        id
        name
        secrets
        flow_id
      }
      crons {
        id
        expression
        execution_id
        timezone
        calendar_id
      }
      api_triggers {
        id
      }
      event_triggers {
        id
      }
      upstream_chains {
        downstream_id
        upstream_id
        id
        upstream_execution {
          name
        }
      }
    }
  }
`;

export default executionsFromProcess;
