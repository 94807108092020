import { gql } from '@apollo/client';

export const processAll = gql`
  query {
    process(order_by: {name: asc}) {
      id
      name
      owner
      description
    }
  }
`;

export default processAll;
