import { gql } from '@apollo/client';

export const userPermissionsAll = gql`
  query {
    auth_permissions(order_by: { user: { name: asc } }) {
      project {
        name
      }
      process {
        name
      }
      user {
        name
      }
      permission_type
    }
  }
`;

export default userPermissionsAll;
