import { gql } from '@apollo/client';

export const taskRunInterface = gql`
    query getModelInterfaceUsage($_gte: timestamptz!, $_lte: timestamptz!, $_includes_interface_name: String!) {
        task_run_interface(where: {task_run: {created: {_gte: $_gte, _lte: $_lte}}, interface_name: {_ilike: $_includes_interface_name}}, order_by: {task_run: {created: asc}}) {
            task_run_id
            id
            task_run {
                name
                created
                updated
                flow_run {
                    deployment {
                        name
                    }
                }
            }
            request_type
        }
    }
`;