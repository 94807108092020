import { useEffect } from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { graphConfig, loginRequest, accessTokenRequest } from './authConfig';

import AuthLoadingPage from 'components/pages/AuthLoadingPage';
import { useAuthStore } from '../../store/useAuthStore';

export async function callMsGraphData(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function callMsGraphPhoto(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMePhotoEndPoint, options)
    .then((response) => response)
    .catch((error) => console.log(error));
}

const MsalAuthProvider = ({ children }) => {
  const { logout, updateAuthData, authData, updateProfilePicture } =
    useAuthStore((state) => ({
      logout: state.logout,
      updateAuthData: state.updateAuthData,
      authData: state.authData,
      updateProfilePicture: state.updateProfilePicture,
    }));
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      console.log(
        'Effect: inProgress === InteractionStatus.None && !isAuthenticated',
      );
      logout();
      instance.loginRedirect(loginRequest);
    }

    if (inProgress === InteractionStatus.None && isAuthenticated) {
      instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response) => {

          // Update ProfilePicture
          callMsGraphPhoto(response.accessToken)
            .then((response) => response.blob())
            .then((data) => {
              if (data !== null) updateProfilePicture(data);
            })
            .catch((e) => console.log(e));

          instance
            .acquireTokenSilent({ ...accessTokenRequest, account: accounts[0] })
            .then((response) => {
              updateAuthData(response);
            });
        })
        .catch((e) => console.log(e));
    }
  }, [isAuthenticated, inProgress]);

  if (!isAuthenticated || !authData?.accessToken) {
    return <AuthLoadingPage />;
  }

  return children;
};

export default MsalAuthProvider;
