import { Tree } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const { DirectoryTree } = Tree;

const mapJsonToTreeData = (jsonObj) =>
  Object.keys(jsonObj).map((key) => {
    const item = jsonObj[key];
    if (Array.isArray(item)) {
      const children = item.map((child, index) => {
        const uuid = uuidv4();
        if (typeof child === 'object') {
          const children = mapJsonToTreeData(child);
          return {
            title: key + '_' + index,
            key: key + '_' + uuid,
            children: children,
          };
        }
        return { title: child, key: child + '_' + uuid, isLeaf: true };
      });
      const uuid = uuidv4();
      return {
        title: key,
        key: key + '_' + uuid,
        children: children,
      };
    } else if (typeof item === 'object') {
      const uuid = uuidv4();
      return {
        title: key,
        key: key + '_' + uuid,
        children: mapJsonToTreeData(item),
      };
    } else {
      const uuid = uuidv4();
      return {
        title: `${key}: ${item}`,
        key: `${key}:${item}_${uuid}`,
        isLeaf: true,
      };
    }
  });

export const TaskInterfaceDataStructure = ({ data }) => {
  if (!data) return null;

  const treeData = mapJsonToTreeData(data);

  return (
    <div>
      <DirectoryTree
        className="dataTree"
        treeData={treeData}
        style={{ padding: '.5em', fontSize: '12px' }}
      />
    </div>
  );
};
