import AdvancedForm from '../../../shared/AdvancedForm/AdvancedForm';

const schema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    interfaceName: {
      type: 'string',
      title: 'Interface Name',
    },
    systemType: {
      type: 'string',
      title: 'System Type',
    },
    connector: {
      type: 'array',
      title: 'Connector',
      items: {
        type: 'string',
      },
      default: ['']
    },
    dataEntities: {
      type: 'array',
      title: 'Data Entities',
      items: {
        type: 'string',
      },
      default: ['']
    },
    dataFormats: {
      type: 'array',
      title: 'Data Formats',
      items: {
        type: 'string',
      },
      default: ['']
    },
    capabilities: {
      type: 'array',
      title: 'Capabilities',
      items: {
        type: 'string',
      },
      default: ['']
    },
  },
};

// TODO work on the styling of the form.
//  For the arrays maybe place the Add Item Button to each of the last row

export const AddDataInterfaceForm = () => {
  return <AdvancedForm schema={schema} />;
};