import BasicFieldLayout from '../Layout/BasicFieldLayout';
import {Button, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useState} from 'react';
import {last} from 'lodash';

const uploadProps = (setFileList, props) => ({
    beforeUpload: (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            console.log('CSV data:', e.target.result);
            // Now you have your data in CSV format in e.target.result
        };
        return false;
    },
    onChange: ({fileList}) => {
        // allow only one file in the list
        setFileList(fileList.slice(-1));
        const reader = new FileReader();
        reader.onload = (e) => {
            // console.log('CSV data:', e.target.result);
            // Now you have your data in CSV format in e.target.result
            const content = e.target.result;
            // this is the react-jsonschema onChange for the formData

            props.onChange(content);
        };
        const lastFile = last(fileList);

        if (lastFile) {
            reader.readAsText(lastFile.originFileObj);
        }
    },

    onRemove: () => {
        setFileList([]);
        props.onChange(undefined);

    },

});

export const ExeqtUploadCSVFile = (props) => {
    const [fileList, setFileList] = useState([]);

    return (
        <BasicFieldLayout {...props}>
            <Upload {...uploadProps(setFileList, props)} fileList={fileList} accept={".csv"}>
                {/*<Upload {...uploadProps(setFileList, setOptions)}  fileList={fileList}>*/}
                <Button
                    icon={<UploadOutlined/>}
                    disabled={props.disabled || props.readonly}
                >
                    Upload CSV
                </Button>
            </Upload>
        </BasicFieldLayout>
    );
};