import { Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  RedoOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

export const RunStateTag = (props) => {
  const tag = () => {
    if (props.state === 'Failed' || props.state === 'Crashed' )
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {props.state}
        </Tag>
      );
    if (props.state === 'Deleted')
      return (
        <Tag icon={<DeleteOutlined />} color="lightgrey">
          {props.state}
        </Tag>
      );
    if (props.state === 'Completed')
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {props.state}
        </Tag>
      );
    if (props.state === 'Outdated')
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {props.state}
        </Tag>
      );
    if (props.state === 'Running' || props.state === 'Cancelling')
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          {props.state}
        </Tag>
      );
    if (props.state === 'Cancelled')
      return (
        <Tag icon={<CloseCircleOutlined/>} color="warning">
          {props.state}
        </Tag>
      );
    if (props.state === 'Scheduled')
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          {props.state}
        </Tag>
      );
    if (props.state === 'Pending')
      return (
        <Tag icon={<ClockCircleOutlined spin />} color="orange">
          {props.state}
        </Tag>
      );
    if (props.state === 'Rerun')
      return (
        <Tag icon={<RedoOutlined />} color="default">
          {props.state}
        </Tag>
      );
    if (props.state === 'Aborted')
      return (
        <Tag icon={<CloseCircleOutlined />} color="default">
          {props.state}
        </Tag>
      );
    return <Tag color="default">{props.state}</Tag>;
  };

  if (props.message) {
    return (
      <Tooltip placement="top" title={props.message}>
        {tag()}
      </Tooltip>
    );
  }
  return tag();
};

export default RunStateTag;
