import {useEffect, useState} from 'react';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Typography} from 'antd';

import TextViewButton from '../../TextViewButton';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import LogLevelTag from '../LogLevelTag';
import {toTimeZone} from 'utils/utils';
import {useHandleFlowRunLogs} from "./useHandleFlowRunLogs";

const {Text} = Typography;

const RunLogsTable = (props) => {
    const [filteredInfo, setFilteredInfo] = useState({});

    const {data, loading, error} = useHandleFlowRunLogs(props.flowRunId);

    const flowRunLogs = data?.oe_log;

    // this is a helper for the ModelFlow
    // thanks to this the hasura action get_flow_graph
    // will get re-triggered
    useEffect(() => {
        if (data) {
            props?.handleDataUpdate();
        }
    }, [data]);

    useEffect(() => {
        if (props.filter && props.filter.task_run_id)
            setFilteredInfo({task_run_id: [props.filter.task_run_id]});
        else setFilteredInfo({name: null, message: null, task_run_id: null});
    }, [props.filter]);

    const columns = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            sort: true,
            width: '170px',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sort: true,
            search: true,
            filteredValue: filteredInfo.name,
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            sort: true,
            search: true,
            width: '50%',
            filteredValue: filteredInfo.message,
            render: (state, record, index) => {
                return (
                    <div>
                        <Text style={{whiteSpace: 'pre-wrap'}}> {record.message}</Text>
                        {record.details && (
                            <TextViewButton
                                text={record.details}
                                size="small"
                                type="link"
                                title="Detail Log Message"
                                icon={<InfoCircleOutlined/>}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            sort: true,
            width: '120px',
            render: (state, record, index) => {
                return <LogLevelTag state={record.level}/>;
            },
        },
    ];

    const transformedData = flowRunLogs ? flowRunLogs
            .map(({timestamp, task_run, name, message, level, id}) => ({
                time: toTimeZone(timestamp),
                name: task_run?.name ?? name.replace("prefect", "exeqt").replace("flow_run", "model_run"),
                task_run_id: task_run?.id ?? '-',
                message: message?.replace("flow run", "model run"),
                level,
                key: id,
            }))
            .filter(({task_run_id}) => !filteredInfo.task_run_id || filteredInfo.task_run_id.includes(task_run_id))
        : [];


    return (
        <AdvancedTable
            dataSource={transformedData}
            loading={loading}
            columns={columns}
            type="runLogsTable"
        />
    );
};

export default RunLogsTable;
