import { useEffect, useMemo, useState } from 'react';
import { Handle } from 'react-flow-renderer/nocss';
import { Card, Row, Col, Typography } from 'antd';

import './executionnode.css';
import { toTimeZone } from 'utils/utils';

import AlertDeleteTag from 'components/shared/AlertDeleteTag';

import { theme } from 'config/js/theme.js';
import { useUserStore } from 'contexts/User';
import {ExecutionEditModal} from "../../ExecutionEditModal/ExecutionEditModal";

const { Text } = Typography;

const defaultStyles = {
  backgroundColor: '#2f53da',
  color: '#ffffff',
  cursor: 'pointer',
};

const ExecutionNode = (node) => {
  const [state, dispatch] = useUserStore();

  const alerts = useMemo(() => {
    return state.alerts?.filter(
      (alert) => alert.execution_id === node.data.execution.id,
    );
  }, [state.alerts]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const style = defaultStyles;

  const handleClick = (e) => {
    setIsModalVisible(true);
  };

  const closeModal = (e) => {
    setIsModalVisible(false);
  };

  return (
    <div className="grytnode_wrapper" style={{ backgroundColor: '00f' }}>
      {node.selected && <div className="grytnode_selected"></div>}

      <div className="execution_node" style={style} onClick={handleClick}>
        <Card
          size="small"
          title={node.data.name}
          className="card--elevated"
          headStyle={{
            backgroundColor: theme['primary-color-green-light'],
            border: '0px',
          }}
        >
          <Row gutter={[0, 4]}>
            {node.data.execution?.model.name && (
              <Row gutter={[8, 4]} justify="space-between">
                <Col>
                  <Text strong>Model:</Text>
                </Col>
                <Col>
                  <Text>{node.data.execution.model.name}</Text>
                </Col>
              </Row>
            )}

            {node.data.execution.last_successful_run && (
              <Row gutter={[8, 4]} justify="space-between">
                <Col>
                  <Text strong>Last:</Text>
                </Col>
                <Col>
                  <Text>
                    {toTimeZone(node.data.execution.last_successful_run)}
                  </Text>
                </Col>
              </Row>
            )}

            {alerts && Array.isArray(alerts) && alerts.length > 0 && (
              <Row gutter={[8, 4]}>
                <Col>
                  <Text strong>Alerts:</Text>
                </Col>
                {alerts.map((alert) => (
                  <AlertDeleteTag size="small" key={alert.id} alert={alert} />
                ))}
              </Row>
            )}
          </Row>
        </Card>

        {node.data._io_type !== 'input' &&
          node.data._io_type !== 'standalone' && (
            <Handle
              type="target"
              position="left"
              id={'in' + node.id}
              style={{ borderRadius: 0 }}
            />
          )}
        {node.data._io_type !== 'output' &&
          node.data._io_type !== 'standalone' && (
            <Handle
              type="source"
              position="right"
              id={'out' + node.id}
              style={{ borderRadius: 0 }}
            />
          )}
      </div>
      <ExecutionEditModal
        execution={node.data.execution}
        open={isModalVisible}
        onCancel={closeModal}
        onClose={closeModal}
        destroyOnClose
      />
    </div>
  );
};

export default ExecutionNode;
