import { gql } from '@apollo/client';

export const modelByProjectAndName = gql`
  query (
      $project: String!,
      $model: String!
  ){
    model(where: {name: {_eq: $model}, project: {name: {_eq: $project}}}) {
      id
      created_at
      updated_at
      project_id
      gitlink
      always_on
      deployment_id
      flow_id
      project {
        name
      }
      deployment {
        name
        description
        parameter_openapi_schema
      }
      owner
    }
  }
`;

export default modelByProjectAndName;
