import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {theme} from "../../../../../../config/js/theme";

export const MyIcon = ({isActive}) => {
    if (isActive)
        return (
            <CaretUpOutlined
                style={{
                    fontSize: '20px',
                    color: theme['primary-color-green-light'],
                }}
            />
        );
    return (
        <CaretDownOutlined
            style={{fontSize: '20px', color: theme['primary-color-green-light']}}
        />
    );
};