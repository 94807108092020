import { toTimeZone } from '../../../../../utils/utils';

export const adjustTimestampToBucket = (timestamp, bucket) => {
  const lookup = {
    minutely: timestamp.setSeconds(0, 0),
    hourly: timestamp.setMinutes(0, 0, 0),
    daily: timestamp.setHours(0, 0, 0, 0),
    monthly: timestamp.setDate(1),
  };

  return new Date(lookup[bucket]);
};

export const bucketToMs = (bucket) => {
  const lookup = {
    minutely: 60000,
    hourly: 3600000,
    daily: 86400000,
    monthly: 2592000000,
  };

  return lookup[bucket];
};

// TODO export to utils
export const setAggregateData = (dataSource, bucket) => {
  // I suppose that the data in task_run_interface is ordered by timestamp
  // Thus, I look at the last element of the acc array
  // If the timestamp of the current array acc element could be contained
  // in the range of the last array acc element plus the bucket value
  // then add its value to the last array acc element
  // else create a new array acc element, with timestamp to the nearest whole datetime
  // This is correlated to the bucket value

  // TODO remove unnecessary properties from the destructuring
  const data = dataSource.task_run_interface.reduce(
    (acc, { request_type, task_run: { created } }) => {
      const createdDate = new Date(created);
      const dataVolume = Math.ceil(Math.random() * 191) + 10; // generated randomly between values of 10 and 200

      const adjustedTimestamp = adjustTimestampToBucket(createdDate, bucket);
      const bucketInMs = bucketToMs(bucket);

      // TODO refactor the following logic
      if (request_type.toLowerCase() === 'load') {
        if (
          acc.loadTimestamps.length > 0 &&
          acc.loadTimestamps[acc.loadTimestamps.length - 1].getTime() +
            bucketInMs >
            adjustedTimestamp.getTime()
        ) {
          acc.loadDataVolume[acc.loadDataVolume.length - 1] += dataVolume;
          acc.loadRequestCount[acc.loadRequestCount.length - 1] += 1;
        } else {
          acc.loadTimestamps.push(adjustedTimestamp);
          acc.loadDataVolume.push(dataVolume);
          acc.loadRequestCount.push(1);
        }
      } else if (request_type.toLowerCase() === 'store') {
        if (
          acc.storeTimestamps.length > 0 &&
          acc.storeTimestamps[acc.storeTimestamps.length - 1].getTime() +
            bucketInMs >
            adjustedTimestamp.getTime()
        ) {
          acc.storeDataVolume[acc.storeDataVolume.length - 1] += dataVolume;
          acc.storeRequestCount[acc.storeRequestCount.length - 1] += 1;
        } else {
          acc.storeTimestamps.push(adjustedTimestamp);
          acc.storeDataVolume.push(dataVolume);
          acc.storeRequestCount.push(1);
        }
      }

      return acc;
    },
    {
      loadTimestamps: [],
      loadDataVolume: [],
      loadRequestCount: [],
      storeTimestamps: [],
      storeDataVolume: [],
      storeRequestCount: [],
    },
  );
  data.loadTimestamps = data.loadTimestamps.map((timestamp) =>
    toTimeZone(timestamp.toISOString()),
  );
  data.storeTimestamps = data.storeTimestamps.map((timestamp) =>
    toTimeZone(timestamp.toISOString()),
  );

  return data;
};