import { Breadcrumb, Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { AddDataInterfaceModal } from '../AddDataInterface/AddDataInterfaceModal';

export const DataInterfacePageHeader = ({
                                          interfaceName,
                                          url,
                                          setOpenDataInterfaceForm,
                                          openDataInterfaceForm,
                                        }) => {
  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: 'Data Interface: ' + interfaceName,
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Space
        style={{
          marginBottom: '10px',
          width: '100%',
          flexDirection: 'row-reverse',
        }}
      >
        {/*TODO: currently not properly implemented,
        hide button until then to not confuse customers*/}
        {/*
        <Button
          type="primary"
          style={{ marginLeft: '10px' }}
          onClick={() => setOpenDataInterfaceForm(true)}
        >
          Add Data Interface
        </Button>
        */}

        {url?.isInternal ? (
          <Button type="primary">
            <Link to={url.value}>Switch to Data Management</Link>
          </Button>
        ) : (
          <>
            <Button type="primary" href={url?.value} target="_blank">
              Switch to Data Management
            </Button>
          </>
        )}
        <AddDataInterfaceModal
          {...{ openDataInterfaceForm, setOpenDataInterfaceForm }}
        />
      </Space>
    </div>
  );
};