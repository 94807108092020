import { Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  RedoOutlined,
} from '@ant-design/icons';

export const FlowRunTag = (props) => {
  const tag = () => {
    if (props.tag === 'auto-scheduled')
      return (
        <Tag color={props.color ? "green" : "default"}>
          Auto
        </Tag>
      );
    if (props.tag === 'adhoc-run')
      return (
        <Tag color={props.color ? "blue" : "default"}>
          Adhoc
        </Tag>
      );
    if (props.tag === 'execution')
      return (
        <Tag color={props.color ? "orange" : "default"}>
          Execution
        </Tag>
      );
    return <Tag color="default">{props.tag}</Tag>;
  };

  if (props.message) {
    return (
      <Tooltip placement="top" title={props.message}>
        {tag()}
      </Tooltip>
    );
  }
  return tag();
};

export default FlowRunTag;
