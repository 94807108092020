import { RunsPanelContainer } from './RunsPanelContainer';
import {activeStates, failedStates} from "../../../../utils/flowRunStateTypes";



export const RunsContainer = ({
  flowRuns,
  loading,
  onRunClick,
  path,
  history,
}) => {
  const activeRuns = flowRuns
    ? flowRuns.filter((flowRun) => activeStates.includes(flowRun.state_type)).toReversed()
    : [];
  const failedRuns = flowRuns
    ? flowRuns.filter((flowRun) => failedStates.includes(flowRun.state_type))
    : [];

  const exceptStates = activeStates.concat(failedStates);
  const otherRuns = flowRuns
    ? flowRuns.filter((flowRun) => !exceptStates.includes(flowRun.state_type))
    : [];

  return [
    {
      type: 'active-runs',
      title: 'Active Runs',
      runs: activeRuns,
    },
    {
      type: 'failed-runs',
      title: 'Failed Runs',
      runs: failedRuns,
    },
    {
      type: 'loading-runs',
      title: 'Successfull Runs',
      runs: otherRuns,
    },
  ].map(({ type, runs, title }) => (
    <RunsPanelContainer
      key={type}
      {...{
        type,
        runs,
        loading,
        title,
        onRunClick,
        path,
        history,
      }}
    />
  ));
};
