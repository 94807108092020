import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Space, Breadcrumb } from 'antd';
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';

import secretsAll from 'common/graphql/querys/secretsAll';
import secretDelete from 'common/graphql/mutations/secretDelete';

import BasicTooltipButton from 'components/shared/BasicTooltipButton';
import SafeDeleteIdMutationButton from 'components/shared/SafeDeleteIdMutationButton';
import SecretEditModal from './SecretEditModal';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';

import { copyToClipboard } from 'utils/utils';

export const SecretPage = () => {
  const { loading, error, data, refetch } = useQuery(secretsAll);
  const [showModal, setShowModal] = useState(false);
  const [hideValue, setHideValue] = useState(true);
  const [modalData, setModalData] = useState(null);

  const handleChange = () => {
    refetch();
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      sort: true,
      search: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (state, record, index) => {
        if (hideValue) return '* * * * * * * *';
        return record.value;
      },
    },
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      render: (state, record, index) => {
        return (
          <Space>
            <BasicTooltipButton
              tooltip={'Copy to Clipboard'}
              icon={<CopyOutlined />}
              size={'medium'}
              shape="circle"
              type="primary"
              onClick={() => {
                copyToClipboard(record.value);
              }}
            />
            <BasicTooltipButton
              tooltip={'Edit Secret'}
              icon={<EditOutlined />}
              size={'medium'}
              shape="circle"
              type="primary"
              onClick={() => openModal(record)}
            />
            <SafeDeleteIdMutationButton
              id={record.id}
              mutation={secretDelete}
              onDataChange={handleChange}
              tooltip={'Delete Secret'}
              icon={<DeleteOutlined />}
              size={'medium'}
              shape="circle"
              type="secondary"
            />
          </Space>
        );
      },
    },
  ];

  let transformed_data = [];
  if (data && data.secret) {
    transformed_data = data.secret;
  }

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  if (error) {
    console.log(error);
    return <p>Error</p>;
  }

  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: 'Secrets',
    },
  ];

  return (
    <div id="secret-page" style={{ backgroundColor: '#F1F0EB' }}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Space style={{ marginBottom: '10px', width: '100%' }}>
        <BasicTooltipButton type="primary" onClick={() => openModal(null)}>
          Add Secret
        </BasicTooltipButton>
        <BasicTooltipButton
          onClick={() => {
            setHideValue(!hideValue);
          }}
        >
          {hideValue ? 'Reveal Values' : 'Hide Values'}
        </BasicTooltipButton>
      </Space>
      <SecretEditModal
        open={showModal}
        secret={modalData}
        onDataChange={handleChange}
        onClose={closeModal}
        onCancel={closeModal}
      />

      <AdvancedTable
        dataSource={transformed_data}
        columns={columns}
        loading={loading}
        type="secretPage"
      />
    </div>
  );
};

export default SecretPage;
