import {useApolloClient} from "@apollo/client";
import {useState} from "react";
import modelAlwaysOnEdit from "../../../../common/graphql/mutations/modelAlwaysOnEdit";
import {message, Switch} from "antd";

export const ModelAlwaysOnSwitch = (props) => {
    const client = useApolloClient();
    const { model_id } = props;
    const [loading, setLoading] = useState(false);

    const onChange = (checked, e) => {
        e.stopPropagation();
        setLoading(true);
        console.log(`${model_id} -> switch to ${checked}`);
        client
            .mutate({
                mutation: modelAlwaysOnEdit,
                variables: {
                    id: model_id,
                    value: checked,
                },
            })
            .then((result) => {
                message.success({ content: 'Succesfully updated Secret.' });
                setLoading(false);
            })
            .catch((error) => {
                message.error({
                    content: 'Error while trying to update Secret: ' + error,
                });
                setLoading(false);
            });
    };
    return <Switch {...props} loading={loading} onChange={onChange} />;
};