import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import ModelEditModal from 'components/shared/ModelEditModal/ModelEditModal';
import IframeResizer from 'iframe-resizer-react';
import { useLazyQuery } from '@apollo/client';
import modelByProjectAndName from '@/common/graphql/querys/modelByProjectAndName';
import { toTimeZone } from '../../../utils/utils';
import { FormButtonRefProvider } from '../ModelsPage/FormButtonRefProvider';
import { FormHasValidationErrorProvider } from '../ModelsPage/FormHasValidationErrorProvider';

export const DashboardPage = (props) => {
  const { slug } = useParams();
  const url = 'https://' + window._env_.REACT_APP_API_HOST + '/' + slug + '/';

  const [modelDialogData, setModelDialogData] = useState(false);
  const [showModelModal, setShowModelModal] = useState(false);
  const [getModel, { loading, error, data }] = useLazyQuery(
    modelByProjectAndName,
    {
      variables: { model: '', project: '' },
    },
  );

  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (
        event.origin === 'https://' + window._env_.REACT_APP_API_HOST &&
        event.data?.source === 'exeqt-dashboard'
      ) {
        switch (event.data.action) {
          case 'model-run':
            getModel({
              variables: {
                model: event.data.data.modelName,
                project: event.data.data.projectName,
              },
            });
            setShowModelModal(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log('useEffect data:', data);
    if (data?.model) {
      const transformed_data = data?.model.map((model) => {
        return {
          ...model,
          created: toTimeZone(model.created_at),
          updated: toTimeZone(model.updated_at),
          project_name: model.project.name,
          name: model.deployment?.name,
          description: model.deployment?.description,
          has_parameter: model.deployment?.parameter_openapi_schema,
          key: model.id,
          slug: encodeURIComponent(
            model.deployment?.name.replaceAll(' ', '_').toLocaleLowerCase(),
          ),
        };
      });
      setModelDialogData(transformed_data[0]);
    } else {
      setModelDialogData(false);
    }
  }, [data]);

  const closeModelModal = () => {
    setShowModelModal(false);
    getModel();
  };

  const items = [
        {
            title: <Link to="/">Home</Link>
        },
        {
                title: 'Dashboard'
        }
  ]

  return (
    <div id="dashboards-page">
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <IframeResizer
        key={slug}
        log={false}
        autoResize={true}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation"
        src={url}
        style={{ width: '1px', minWidth: '100%' }}
        frameBorder="0"
        heightCalculationMethod="lowestElement"
      />
      {modelDialogData && (
        <FormButtonRefProvider>
          <FormHasValidationErrorProvider>
            <ModelEditModal
              open={showModelModal}
              adhocRunMode={true}
              destroyOnClose
              model={{ ...modelDialogData }}
              onClose={closeModelModal}
            />
          </FormHasValidationErrorProvider>
        </FormButtonRefProvider>
      )}
    </div>
  );
};

export default DashboardPage;
