import {gql} from '@apollo/client';

const tasksByFlowId = gql`
  query TasksByFlowId($flowId: uuid!) {
    oe_flow(where: {id: {_eq: $flowId}}) {
      model {
        tasks {
          name
          id
          description
        }
      }
    }
  }
`;

export default tasksByFlowId;
