import { gql } from '@apollo/client';

const permissionEdit = gql`
  mutation (
    $id: Int!
    $name: String!
    $description: String
    $permission_type: String!
    $process_ids_added: [auth_m2m_permission__process_insert_input!]! = []
    $project_ids_added: [auth_m2m_permission__project_insert_input!]! = []
    $process_ids_removed: [Int!]! = []
    $project_ids_removed: [Int!]! = []
  ) {
    update_auth_permission_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        permission_type: $permission_type
      }
    ) {
      id
    }
    insert_auth_m2m_permission__process(objects: $process_ids_added) {
      affected_rows
    }
    insert_auth_m2m_permission__project(objects: $project_ids_added) {
      affected_rows
    }
    delete_auth_m2m_permission__process(
      where: {
        _and: {
          permission_id: { _eq: $id }
          process_id: { _in: $process_ids_removed }
        }
      }
    ) {
      affected_rows
    }
    delete_auth_m2m_permission__project(
      where: {
        _and: {
          permission_id: { _eq: $id }
          project_id: { _in: $project_ids_removed }
        }
      }
    ) {
      affected_rows
    }
  }
`;

export default permissionEdit;
