import { gql } from '@apollo/client';

export const secretExistsByKey = gql`
  query ($key: String!) {
    secret(where: { key: { _eq: $key } }) {
      id
      key
    }
  }
`;

export default secretExistsByKey;
