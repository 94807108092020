import { gql } from '@apollo/client';

const actionUDMDownloadBlobData = gql`
  mutation MyMutation($id: Int) {
    udm_download_blob_data(id: $id) {
      udm_sas_data
    }
  }
`;

export default actionUDMDownloadBlobData;
