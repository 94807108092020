export const ErrorMessage = ({ message }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        margin: '20px',
        padding: '20px',
        backgroundColor: '#ffdddd',
        border: '1px solid #ffcccc',
        borderRadius: '5px',
      }}
    >
      <h2>Oops! Something Went Wrong</h2>
      <p>
        {message || 'An unexpected error has occurred. Please try again later.'}
      </p>
    </div>
  );
};
