import { gql } from '@apollo/client';

export const alertsFromUser = gql`
  query ($user_id: uuid!) {
    alert(where: { user_id: { _eq: $user_id } }) {
      id
      execution_id
      last_fired
      type
    }
  }
`;

export default alertsFromUser;
