import React, { createRef } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  isNode,
} from 'react-flow-renderer/nocss';

import { Switch } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

import 'components/shared/DataFlow/custom_reactflow_theme.css';
import CustomEdge from './CustomEdge';
import dagre from 'dagre';

function _getNodeType(node, edges) {
  if (!edges) return 'standalone';
  const isUpstreamEdge =
    edges.findIndex((element) => element.upstream_node === node.id) >= 0;
  const isDowntreamEdge =
    edges.findIndex((element) => element.downstream_node === node.id) >= 0;
  if (isDowntreamEdge && isUpstreamEdge) return 'default';
  if (isDowntreamEdge) return 'output';
  if (isUpstreamEdge) return 'input';
  return 'standalone';
}

export class DataFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      maxUsedHeight: null,
      maxUsedWidth: null,
      selected: { id: null, name: null },
      flow: null,
      wrapperRef: createRef(),
      locked: true,
    };

    this.onLoad = this.onLoad.bind(this);
    this.updateFlow = this.updateFlow.bind(this);
    this.createElements = this.createElements.bind(this);
    this.layoutElements = this.layoutElements.bind(this);
    this.getNodeTypesFromProps = this.getNodeTypesFromProps.bind(this);
    this.getScale = this.getScale.bind(this);
    this.handleLockChange = this.handleLockChange.bind(this);
  }

  layoutElements(elements) {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'LR' });

    elements.forEach((el) => {
      let nodeWidth = 150;
      let nodeHeight = 50;
      if (
        this.props.customNodes &&
        this.props.customNodes.hasOwnProperty(el.type)
      ) {
        nodeWidth = this.props.customNodes[el.type].nodeWidth;
        nodeHeight = this.props.customNodes[el.type].nodeHeight;
      }
      if (isNode(el))
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      else dagreGraph.setEdge(el.source, el.target);
    });

    dagre.layout(dagreGraph);
    const maxUsedHeights = Object.values(dagreGraph._nodes).map(
      (_n) => _n.y + _n.height,
    );
    const maxUsedWidths = Object.values(dagreGraph._nodes).map(
      (_n) => _n.x + _n.width,
    );
    const maxUsedHeight = Math.max(...maxUsedHeights) + 40;
    const maxUsedWidth = Math.max(...maxUsedWidths) + 40;

    const layoutedElements = elements.map((el) => {
      if (!isNode(el)) return el;
      const node = dagreGraph.node(el.id);
      return {
        ...el,
        targetPosition: 'left',
        sourcePosition: 'right',
        position: {
          x: node.x - node.width / 2,
          y: node.y - node.height / 2,
        },
      };
    });
    this.setState({
      elements: layoutedElements,
      maxUsedHeight: maxUsedHeight,
      maxUsedWidth: maxUsedWidth,
    });
  }

  createElements() {
    const elements = [];
    if (!this.props.nodes) {
      return elements;
    }

    // this.props.nodes has to be an Object with { id, name }
    // this.props.edges has to be an Array with [{ upstream_node, downstream_node },...]
    this.props.nodes.forEach((node, index) => {
      const nodeType = _getNodeType(node, this.props.edges);
      elements.push({
        id: node.id,
        type: node.type,
        data: { ...node, _io_type: nodeType },
        position: { x: 0, y: 0 },
        selectable: true,
      });
    });

    if (this.props.edges) {
      this.props.edges.forEach((edge) => {
        const source_element = elements.find(
          (element) => element.id === edge.upstream_node,
        );
        const target_element = elements.find(
          (element) => element.id === edge.downstream_node,
        );
        if(!(source_element && target_element)) return
        elements.push({
          id: 'e' + source_element.id + '_' + target_element.id,
          type: 'custom',
          source: source_element.id,
          target: target_element.id,
          arrowHeadType: 'arrowclosed',
        });
      });
    }

    return elements;
  }

  componentDidMount() {
    this.layoutElements(this.createElements());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.nodes !== this.props.nodes ||
      prevProps.edges !== this.props.edges
    ) {
      this.layoutElements(this.createElements());
      this.updateFlow();
    }
  }

  updateFlow() {
    if (this.state.flow && this.state.elements.length > 0) {
      this.state.flow.fitView();
    }
  }

  onLoad = (reactFlowInstance) => {
    this.setState({ flow: reactFlowInstance });
    reactFlowInstance.fitView();
  };

  getNodeTypesFromProps() {
    const nodeTypes = {};
    if (!this.props.customNodes) return nodeTypes;

    Object.keys(this.props.customNodes).forEach((key) => {
      nodeTypes[key] = this.props.customNodes[key].nodeObj;
    });
    return nodeTypes;
  }

  getScale() {
    let scale = 1;
    if (
      this.state.wrapperRef?.current &&
      this.state.maxUsedWidth &&
      this.state.maxUsedHeight
    ) {
      scale = Math.min(
        1,
        Math.max(
          this.state.wrapperRef.current.offsetWidth / this.state.maxUsedWidth,
          this.state.wrapperRef.current.offsetHeight / this.state.maxUsedHeight,
        ),
      );
    }
    return scale;
  }

  handleLockChange(e) {
    this.setState({ locked: e });
  }

  render() {
    if (this.state.elements.length === 0)
      return null;

    const _paneMoveable = this.state.locked
      ? false
      : this.props.paneMoveable || true;
    const _zoomOnScroll = this.state.locked
      ? false
      : this.props.zoomOnScroll || true;

    return (
      <div
        ref={this.state.wrapperRef}
        style={{
          height: this.state.maxUsedHeight * this.getScale(),
          minHeight: this.props.minHeight || '100px',
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <Switch
          style={{ position: 'absolute', right: '30px', zIndex: '1000' }}
          checkedChildren={
            <>
              locked <LockOutlined />
            </>
          }
          unCheckedChildren={
            <>
              unlocked <UnlockOutlined />
            </>
          }
          defaultChecked
          checked={this.state.locked}
          onChange={this.handleLockChange}
        />

        <ReactFlowProvider>
          <ReactFlow
            minZoom={this.props.minZoom || 0.05}
            maxZoom={this.props.maxZoom || 1}
            elements={this.state.elements}
            edgeTypes={{ custom: CustomEdge }}
            nodeTypes={this.getNodeTypesFromProps()}
            onSelectionChange={this.props.onSelectionChange}
            onElementClick={this.props.onElementClick}
            elementsSelectable={this.props.elementsSelectable || false}
            nodesDraggable={false}
            nodesConnectable={false}
            paneMoveable={_paneMoveable}
            zoomOnScroll={_zoomOnScroll}
            preventScrolling={this.props.preventScrolling || false}
            onLoad={this.onLoad}
          />
        </ReactFlowProvider>
      </div>
    );
  }
}

export default DataFlow;
