import { gql } from '@apollo/client';

export const permissionTypesAll = gql`
  query {
    auth_permission_type {
      permission_type
    }
  }
`;

export default permissionTypesAll;
