import {Button, Modal} from "antd";
import {AddDataInterfaceForm} from "./AddDataInterfaceForm";

export const AddDataInterfaceModal = ({ openDataInterfaceForm, setOpenDataInterfaceForm }) => {
  return (
    (<Modal
      title="Add Data Interface"
      centered
      open={openDataInterfaceForm}
      onOk={() => setOpenDataInterfaceForm(false)}
      onCancel={() => setOpenDataInterfaceForm(false)}
      width={800}
      destroyOnClose
      footer={[
        <Button
          key="resetButton"
          // onClick={handleFormReset}
          // hidden={!formChanged}
        >
          Cancel
        </Button>,
        <Button
          key="submitButton"
          type="primary"
          // onClick={onSubmit}
          // loading={loading}
        >
          Create
        </Button>,
      ]}
    >
      <AddDataInterfaceForm />
    </Modal>)
  );
};