import {useEffect, useState} from 'react';
import {Form, Input, Select} from 'antd';
import {useQuery} from '@apollo/client';

import processExecutionsSimple from 'common/graphql/querys/processExecutionsSimple';

import CronExpressionInput from '../AdvancedForm/InputComponents/CronExpressionInput';
import TimeZoneSelector from '../AdvancedForm/InputComponents/TimeZoneSelector';
//import CalendarSelector from '../AdvancedForm/InputComponents/CalendarSelector';
import ChainSettings from './ChainSettings';

const {Option} = Select;

const ExecutionSettingsForm = ({
                                   handleFormChange,
                                   form,
                                   onFinish,
                                   execution,
                               }) => {
    const edit = execution !== false;

    const [triggerSelect, setTriggerSelect] = useState(null);
    const [chainOptions, setChainOptions] = useState(null);
    const [processesSelect, setProcessesSelect] = useState(() => {
        if (edit && execution.process_id) return execution.process_id;
        return null;
    });

    const processesQuery = useQuery(processExecutionsSimple);
    if (processesQuery.error) console.log(processesQuery.error);

    const isChain = triggerSelect === 'CHAIN';
    const isCron = triggerSelect === 'CRON';

    useEffect(() => {
        if (execution) {
            setTriggerSelect(execution.trigger_type);
            setChainOptions(getChainOptions(execution.process_id));
        }
    }, [execution]);

    const getChainOptions = (selectedProcess) => {
        console.log('getChainOptions', selectedProcess);
        if (!processesQuery?.data?.process) return [];
        const process = processesQuery.data.process.find(
            (process) => process.id === selectedProcess,
        );
        if (!process) return [];

        return process.executions.filter((e) => {
            if (
                form.getFieldsValue()?.chain__upstream_executions &&
                form.getFieldsValue().chain__upstream_executions.includes(e.name)
            )
                return false;
            return !(execution && execution.name === e.name);
        });
    };

    const handleTriggerSelectChange = (value) => {
        setTriggerSelect(value);
        if (value === 'CHAIN') setChainOptions(getChainOptions(processesSelect));
    };

    const handleProcessChange = (value, option) => {
        console.log('new process', value);
        setProcessesSelect(value);
        form.setFieldsValue({
            ...form.getFieldsValue(),
            process: value,
            chain__upstream_executions: [],
        });
        processesQuery
            .refetch()
            .then(() => setChainOptions(getChainOptions(value)));
    };

    const initialValues = () => {
        if (!edit) return {};
        return {
            executionName: execution.name,
            description: execution.description,
            process: execution.process_id,
            trigger_type: execution.trigger_type,
            cron__expression: execution?.cron?.expression,
            cron__timezone: execution?.cron?.timezone,
            //cron__calendar_id: execution?.cron?.calendar_id,
            chain__upstream_executions: execution?.upstream_chains.map(
                (chain) => chain.upstream_execution.name,
            ),
        };
    };

    // const chainOptions = getChainOptions()
    return (
        <Form.Provider onFormChange={handleFormChange}>
            <Form
                name="executionForm"
                form={form}
                labelCol={{span: 4}}
                wrapperCol={{span: 20}}
                initialValues={initialValues()}
                onFinish={onFinish}
                labelAlign={'left'}
            >

                <Form.Item
                    label="Name"
                    name="executionName"
                    rules={[{required: true, message: 'Please input execution name'}]}>
                    <Input/>
                </Form.Item>


                <Form.Item label="Description" name="description">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Process"
                    rules={[{required: true, message: 'Please choose a Process.'}]}
                >
                    {processesQuery.error && <>error</>}
                    {processesQuery.loading && <>loading</>}
                    {processesQuery.data && (
                        <Form.Item name="process" noStyle>
                            <Select
                                value={processesSelect}
                                placeholder="Select a Process"
                                onChange={handleProcessChange}
                                disabled={false}
                            >
                                {processesQuery.data.process.map((process) => (
                                    <Option key={process.id} value={process.id}>
                                        {process.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form.Item>

                <Form.Item
                    label="Trigger Type"
                    name="trigger_type"
                    hidden={!processesSelect}
                    rules={[{required: true, message: 'Please choose a trigger type.'}]}
                >
                    <Select
                        placeholder="Select a trigger type"
                        onChange={handleTriggerSelectChange}
                    >
                        <Option value="CRON">CRON</Option>
                        <Option value="CHAIN">CHAIN</Option>
                        <Option value="API">API</Option>
                        {/*TODO: display again, once properly implemented*/}
                        {/*<Option value="EVENT">EVENT</Option>*/}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="CRON Expression"
                    name="cron__expression"
                    hidden={!processesSelect || triggerSelect !== 'CRON'}
                >
                    <CronExpressionInput/>
                </Form.Item>
                <Form.Item
                    label="CRON Timezone"
                    name="cron__timezone"
                    hidden={!processesSelect || !isCron}
                >
                    <TimeZoneSelector/>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    label="CRON Calendar"*/}
                {/*    name="cron__calendar_id"*/}
                {/*    hidden={!processesSelect || !isCron}*/}
                {/*>*/}
                {/*    <CalendarSelector/>*/}
                {/*</Form.Item>*/}

                <ChainSettings {...{processesSelect, isChain, chainOptions, form}} />
            </Form>
        </Form.Provider>
    );
};

export default ExecutionSettingsForm;
