import { gql } from '@apollo/client';

const actionUDMUploadBlobData = gql`
  mutation MyMutation(
    $filename: String!
    $identifier: String!
    $timestamp: timestamptz!
  ) {
    udm_upload_blob_data(
      filename: $filename
      identifier: $identifier
      timestamp: $timestamp
    ) {
      udm_sas_data
    }
  }
`;

export default actionUDMUploadBlobData;
