// TODO this should not be a util function. It should be a normal React component.
import { toTimeZone } from '../../../../utils/utils';
import { Tag } from 'antd';
import dayjs from "dayjs";

export const setDataInterfaceInfo = (dataInterfaceInfo, infoKey) => {
  if (Array.isArray(dataInterfaceInfo[infoKey])) {
    return dataInterfaceInfo[infoKey].map((item) => {
      return (
        <Tag color="green" style={{fontSize:'1.1em'}}
          key={item}>
           {item}
        </Tag>
      );
    });
  }
  return (
    <Tag color="green" style={{fontSize:'1.1em'}}>
      {dataInterfaceInfo[infoKey]}{' '}
    </Tag>
  );
};

export const getCurrentAndPastDates = (minusHours) => {
  const now = dayjs().format(); // ISO 8601 string in local time
  const fourHoursAgo = dayjs().subtract(minusHours, 'hours').format();

  return { to: now, from: fourHoursAgo };
};


export const transformData = (data) => {

  const { tableData, timeTakenPlotData } = data?.task_run_interface.reduce(
    (
      acc,
      {
        id,
        task_run_id,
        request_type,
        task_run: {
          name,
          created,
          updated,
          // run: { execution },
        },
      },
    ) => {
      // TODO implement after finding a solution for the executio
      // const { name: executionName = '-' } = execution || {};
      const createdDate = new Date(created);
      const updatedDate = new Date(updated);
      const timeTaken = (updatedDate - createdDate) / 1000;
      const timestamp = toTimeZone(created);
      const dataVolume = Math.ceil(Math.random() * 191) + 10; // generated randomly between values of 10 and 200

      if (request_type.toLowerCase() === 'load') {
        acc.timeTakenPlotData.loadTimestamps.push(timestamp);
        acc.timeTakenPlotData.loadTimesTaken.push(timeTaken);
        acc.timeTakenPlotData.loadText.push(name);
      } else if (request_type.toLowerCase() === 'store') {
        acc.timeTakenPlotData.storeTimestamps.push(timestamp);
        acc.timeTakenPlotData.storeTimesTaken.push(timeTaken);
        acc.timeTakenPlotData.storeText.push(name);
      }

      acc.tableData.push({
        id,
        task_run_id,
        name,
        timestamp,
        // execution: executionName,
        timeTaken: `${timeTaken}s`,
        dataVolume: `${dataVolume}kb`,
        request_type,
      });

      return acc;
    },
    {
      tableData: [],
      timeTakenPlotData: {
        loadTimestamps: [],
        loadTimesTaken: [],
        loadText: [],
        storeTimestamps: [],
        storeTimesTaken: [],
        storeText: [],
      },
    },
  );

  return {
    tableData: tableData.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
    ),
    timeTakenPlotData,
  };
};
