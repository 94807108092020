import { Button, message, Popconfirm, Space, Typography } from 'antd';
import { createFlowRun } from '../../../common/graphql/mutations/createFlowRun';
import { CaretRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { generatePath } from 'react-router';
import { runStop } from '../../../common/graphql/mutations/runStop';
import { runCreate } from '../../../common/graphql/mutations/runCreate';
import { actionExecutionCreate } from '../../../common/graphql/mutations/actionExecutionCreate';
import { createFlowRunState } from '../../../common/graphql/mutations/createFlowRunState';
import { updateFlowRunWithStateId } from '../../../common/graphql/mutations/updateFlowRunWithStateId';
import { setFormDefaults } from '../AdvancedForm/utils';


const executionTriggerSpecificLookup = {
  CRON: (execution) => {
    return {
      cron: execution.cron__expression,
      timezone: execution.cron__timezone,
      //calendar_id: execution?.cron__calendar_id,
    };
  },
  CHAIN: (execution, process) => {
    const chains = execution.chain__upstream_executions.map((executionName) => {
      const _execution = process.executions.find(
        (e) => e.name === executionName,
      );
      return { upstream_id: _execution.id, downstream_id: null }; // needs to be generated on the backend side
    });
    return {chains: chains}
  },
  API: (execution) => {
    return {};
  },
  EVENT: (execution) => {
    return {};
  },
  // TODO Check if we will use the INTERVAL case
  INTERVAL: (execution) => {
    return {
      interval: execution.interval,
      anchor_date: execution.anchor_date,
      timezone: execution.timezone,
    };
  },
};

export const handleCancelRun = async ({ client, flowRunId }) => {
  try {
    const result = await client.mutate({ mutation: runStop, variables: { flow_run_id: flowRunId } });

    if (result?.data?.cancel_run?.status !== 'ACCEPT') {
      throw new Error('Error while trying to cancel run: ' + result?.data?.cancel_run?.message);
    }


  } catch (error) {
    return { error: error, message: error.message };
  }
};

const createExecutionRequest = async ({
                                    client,
                                    flowId,
                                    modelName,
                                    modelId,
                                    executionName,
                                    schedule,
                                    parameters,
                                    accessToken,
                                    projectName,
                                    processId,
                                    description,
                                    triggerType,
                                    deploymentId,
                                  }) => {
  try {
    const result = await client.mutate({
      mutation: actionExecutionCreate, variables: {
        flow_id: flowId,
        parameters,
        model_name: modelName,
        model_id: modelId,
        execution_name: executionName,
        project_name: projectName, schedule,
        process_id: `${processId}`,
        description,
        trigger_type: triggerType,
        deployment_id_initial: deploymentId,
      },
    });

    if (result?.data?.execution_create?.status !== '200') {
      throw new Error('Error while trying to create new execution: ' + result?.data?.execution_create?.details?.message);
    }

    return result?.data?.execution_create?.deployment_id;
  } catch (error) {
    return { error: error, message: error.message };
  }
};


export const handleCreateExecution = async ({
                                              execution,
                                              processesQuery,
                                              flowId,
                                              model,
                                              parameters,
                                              setIsBtnSaveExecutionLoading,
                                              client,
                                              onClose,
                                              handleBack,
                                              accessToken,
                                              modelName,
                                              modelId,
                                              executionName,
                                              projectName,
                                              processId,
                                              deploymentId,
                                            }) => {
  const processesSelect = execution.process;
  const description = execution.description;
  const triggerType = execution.trigger_type;
  const process = processesQuery.data.process.find(
    (process) => process.id === processesSelect,
  );
  if (!process) {
    console.log('ERROR');
    return;
  }

  if (Object.keys(parameters).length === 0 && model?.has_parameter) {
    console.log('setting parameter defaults');
    parameters = setFormDefaults(model.has_parameter, model.has_parameter.definitions);
    if (parameters === undefined) parameters = {};
  }

  const schedule = executionTriggerSpecificLookup[triggerType]
    ? executionTriggerSpecificLookup[triggerType](execution, process)
    : undefined;

  if (!schedule) {
    message.error({
      content: 'Error while trying to create execution: ' + 'Invalid trigger type',
    });
    return;
  }

  setIsBtnSaveExecutionLoading(true);
  try {
    const result = await createExecutionRequest({
      client,
      flowId,
      modelName,
      modelId,
      executionName,
      schedule,
      parameters,
      accessToken,
      projectName,
      processId,
      description,
      triggerType,
      deploymentId,
    });

    if (result?.error) {
      message.error({
        content: 'Error while trying to create execution: ' + result.message,
      });
      setIsBtnSaveExecutionLoading(false);
      return;
    }

    message.success(
      <Space>
        <Typography.Text>Successfully scheduled execution</Typography.Text>
      </Space>,
      6,
    );

  } catch (error) {

    message.error({
      content: 'Error while trying to create Execution: ' + error,
    });
    return { error: error, message: error.message };
  } finally {
    setIsBtnSaveExecutionLoading(false);
  }
};


const handleFlowRun = async ({
                               client,
                               deploymentId,
                               parameters,
                               accessToken,
                               modelName,
                               projectName,
                             }) => {
  try {
    const result = await client.mutate({
      mutation: runCreate, variables: {
        deployment_id: deploymentId,
        run_parameters: parameters,
        model_name: modelName,
        project_name: projectName,
      },
    });

    if (result?.data?.create_run?.status === 'OK') {
      return result?.data?.create_run?.run_id;
    } else {
      return { error: result?.data?.create_run?.status, message: result?.data?.create_run?.details.message };
    }
  } catch (error) {
    return { error: error, message: error.message };
  }
};


export const handleRunNow = async ({
                                     setIsRunNowLoading,
                                     client,
                                     model,
                                     parameters,
                                     handleRedirect,
                                     adhocRunMode,
                                     onClose,
                                     setCreatedRunId,
                                     flowId,
                                     accessToken,
                                   }) => {
  setIsRunNowLoading(true);
  const now = new Date();
  const nowTimestamptz = now.toISOString();


  if (Object.keys(parameters).length === 0  && model?.has_parameter) {
    console.log('setting parameter defaults');
    parameters = setFormDefaults(model.has_parameter, model.has_parameter.definitions)
    if (parameters === undefined) parameters = {};
  }

  try {

    const result = await handleFlowRun({
      client,
      deploymentId: model.deployment_id,
      accessToken,
      parameters,
      modelName: model.name,
      projectName: model.project.name,
    });
    if (result?.error) {
      message.error({
        content: 'Error while trying to schedule direct run: ' + result.message,
      });
      setIsRunNowLoading(false);
      return;
    }

    message.success(
      <Space>
        <Typography.Text>Successfully scheduled direct run</Typography.Text>
        <Button
          icon={<CaretRightOutlined style={{ color: 'white' }} />}
          type="primary"
          size="small"
          style={{ marginLeft: '10px' }}
          onClick={() => handleRedirect(result)}
        >
          Go To Run
        </Button>
      </Space>,
      6,
    );

    setIsRunNowLoading(false);
    adhocRunMode
      ? onClose()
      : setCreatedRunId(result);


  } catch (error) {
    message.error({
      content: 'Error while trying to schedule direct run: ' + error,
    });
    setIsRunNowLoading(false);
  }
};


export const handleParameterChange = (setParameters) => (newParameters) => {
  setParameters(newParameters);
};


export const handleBack =
  (setIsCreateRun, setIsCreateExecution, setCreatedRunId) => () => {
    setIsCreateRun(false);
    setIsCreateExecution(false);
    setCreatedRunId(null);
  };


export const handleCreateRun = (setIsCreateRun, setIsCreateExecution) => () => {
  setIsCreateRun(true);
  setIsCreateExecution(false);
};


export const handleSetExecution = (setIsCreateRun, setIsCreateExecution) => () => {
  setIsCreateRun(false);
  setIsCreateExecution(true);
};

export const handleRedirect = (history) => (id) => {
  const path = generatePath('/ops/runs/:id?', { id: id });
  history.push(path);
};
