import {Carousel, Card, Spin} from 'antd';
import '../override.css';
import {RunTimeElapsedSlide} from "./components/RunTimeElapsedSlide";
import {RunStateStatisticSlide} from "./components/RunStateStatisticSlide";


export const StatisticsRunsPanel = ({flowRuns, onRunClick}) => {

    if (!flowRuns)
        return (
            <Card title="Run Statistics" style={{height: '100%'}}>
                <Spin size="large"/>
            </Card>
        );

    return (
        <Card title="Run Statistics" style={{height: '100%'}}>
            <Carousel
                autoplay={false}
                dotPosition="bottom"
                style={{height: '100%', paddingBottom: 20}}
            >
                <RunStateStatisticSlide flowRuns={flowRuns}/>
                <RunTimeElapsedSlide flowRuns={flowRuns} onRunClick={onRunClick}/>
            </Carousel>
        </Card>
    );
};

export default StatisticsRunsPanel;
