import { gql } from '@apollo/client';

const udmDataInsert = gql`
  mutation insertUDMData($data: udm_data_insert_input!) {
    insert_udm_data_one(object: $data) {
      id
      created_at
    }
  }
`;

export default udmDataInsert;
