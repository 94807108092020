import BasicFieldLayout from '../Layout/BasicFieldLayout';
import MultiSelectEdit from 'components/shared/AdvancedForm/InputComponents/MultiSelectEdit';

const ExeqtMultiSelectEditField = (props) => {
  return (
    <BasicFieldLayout {...props}>
      <MultiSelectEdit {...props}></MultiSelectEdit>
    </BasicFieldLayout>
  );
};

export default ExeqtMultiSelectEditField;
