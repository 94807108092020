import { gql } from '@apollo/client';

export const userAll = gql`
  query {
    auth_user {
      name
      id
    }
  }
`;

export default userAll;
