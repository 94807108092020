import { gql } from '@apollo/client';

const actionTriggerDashboardPipeline = gql`
  mutation ($ref: String!) {
    trigger_exeqt_dashboard_builder(ref: $ref) {
      data
    }
  }
`;

export default actionTriggerDashboardPipeline;
