import { Row, Col} from 'antd';

import BasicFieldLayout from '../Layout/BasicFieldLayout';
import ButtonAdd from './ButtonAdd';
import ButtonRemove from './ButtonRemove';

const DefaultArrayItem = (props) => {
  return (
    <Row
      wrap={false}
      key={props.key}
      align="middle"
      style={{ marginBottom: '5px' }}
    >
      <Col flex="auto">{props.children}</Col>
      <Col flex="40px" style={{ textAlign: 'center' }}>
        <ButtonRemove
          style={{ marginLeft: '0px', marginRight: '0px' }}
          disabled={props.disabled || props.readonly}
          onClick={props.onDropIndexClick(props.index)}
        />
      </Col>
    </Row>
  );
};

const DefaultNormalArrayFieldTemplate = (props) => {

  const addButton = (
    <>
      {props.canAdd && (
        <Row justify="end" align="middle">
          <ButtonAdd
            className="array-item-add"
            onClick={props.onAddClick}
            disabled={props.disabled || props.readonly}
          />
        </Row>
      )}
    </>
  );

  const compact = Array.isArray(props.items) && props.items.length === 0;

  return (
    <>
      <BasicFieldLayout {...props} label={props.title}>
        {props.items && props.items.map((p) => DefaultArrayItem(p))}
        {compact && addButton}
      </BasicFieldLayout>

      {!compact && addButton}
    </>
  );
};

export default DefaultNormalArrayFieldTemplate;
