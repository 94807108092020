import {useContext} from 'react';

import {withTheme} from '@rjsf/core';
import {Theme as AntDTheme} from '@rjsf/antd';

import uiSchemaBuilder from './uiSchemaBuilder';
import {ParameterDefinitionsContext} from "../ExecutionEditModal/provider/ModelParameterDefinitionsProvider";
import {validator, customizeValidator} from '@rjsf/validator-ajv8';
import {useErrors} from "../ModelEditModal/FormErrorContext";
import {setTitleInAnyOfAllOfOneOf, transformValuesFromNulltoUndefined} from "./utils";
import {FormButtonRefContext} from "../../pages/ModelsPage/FormButtonRefProvider";
import {
    FormHasValidationErrorContext,
} from "../../pages/ModelsPage/FormHasValidationErrorProvider";

const RJSFForm = withTheme(AntDTheme);

// In the schema under properties, there is a property called description
// If I dont "hide" it it will always be displayed
const DescriptionFieldTemplate = (props) => {
    const {description, id} = props;
    return null;
}

export const AdvancedForm = (props) => {
    const {schema, uiSchema, readOnly, formData, onChange, onSubmit, onError, cleanErrors} =
        props;
    const {definitions} = useContext(ParameterDefinitionsContext);
    const {formButtonRef} = useContext(FormButtonRefContext) || {};
    const {setHasValidationError} = useContext(FormHasValidationErrorContext) || {};
    const {setErrors} = useErrors();


    const newReadOnly = readOnly || false;

    const customFormats = {
        'data-url': /.*/,
    };

    const validator = customizeValidator({customFormats: customFormats});

    const newUiSchema = uiSchema || uiSchemaBuilder(schema, newReadOnly, definitions);

    const handleChange = (e) => {
        e.formData = transformValuesFromNulltoUndefined(e.formData);
        if (onChange) onChange(e);
    };

    const handleSubmit = (d) => {
        if (onSubmit) onSubmit(d);
        if (d.errors.length === 0) cleanErrors?.();
        setErrors([]);
        setHasValidationError?.(false);
    };

    const handleError = (e) => {
        if (onError) onError(e);
        setErrors(e);
        setHasValidationError(true);
    };

    // Setting titles so that the dropdowns do not have generic titles
    // ! mutating the object
    setTitleInAnyOfAllOfOneOf(schema);

    // only for validation testing purposes
    // schema.properties = {'simulation_parameters': schema.properties.simulation_parameters}


    return (
        <RJSFForm
            liveValidate={props.liveValidate || false}
            uiSchema={newUiSchema}
            schema={schema}
            formData={formData}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onError={handleError}
            showErrorList={props.showErrorList || false}
            validator={validator}
            templates={{DescriptionFieldTemplate}}
        >
            <button hidden={true} ref={formButtonRef}/>
            {props.children}
        </RJSFForm>
    );
};

export default AdvancedForm;
