import { useState } from 'react';
import { useQuery } from '@apollo/client';

import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import userGroupsAll from 'common/graphql/querys/userGroupsAll';
import { Space, Tag } from 'antd';
import BasicTooltipButton from 'components/shared/BasicTooltipButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import SafeDeleteIdMutationButton from 'components/shared/SafeDeleteIdMutationButton';
import userGroupDelete from 'common/graphql/mutations/userGroupDelete';
import UserGroupEditModal from 'components/pages/UserManagementPage/UserGroupEditModal';

export const UserGroupsTable = (props) => {
  const { loading, error, data, refetch } = useQuery(userGroupsAll);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  if (error) console.error(error);

  const handleChange = () => {
    refetch();
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };
  let transformed_data = [];
  if (data && data.auth_user_groups) {
    transformed_data = data.auth_user_groups;
  }

  let userList = [];
  let permisionList = [];
  if (transformed_data) {
    transformed_data.forEach((r) => {
      userList = userList.concat(r.users?.map((u) => u.user.name));
      permisionList = permisionList.concat(
        r.permissions?.map((p) => p.permission.name),
      );
    });
    userList = Array.from(new Set(userList)).sort();
    permisionList = Array.from(new Set(permisionList)).sort();
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'permission_name',
      sort: true,
      search: true,
      sortOrder: 'ascend',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'permission_desc',
      sort: true,
      search: true,
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      filter: !!userList,
      filters: userList.map((u) => {
        return { text: u, value: u };
      }),
      onFilter: (value, record) =>
        record.users?.map((u) => u.user.name).includes(value),
      render: (state, record, index) => {
        return (
          record?.users && (
            <>
              {' '}
              {record.users.map((u) => (
                <Tag
                  key={`${index}-${u.user.name}`}
                  color={'green'}
                  style={{ marginBottom: '5px', marginRight: '5px' }}
                >
                  {u.user.name}
                </Tag>
              ))}
            </>
          )
        );
      },
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      filter: !!permisionList,
      filters: permisionList.map((u) => {
        return { text: u, value: u };
      }),
      onFilter: (value, record) =>
        record.permissions?.map((p) => p.permission.name).includes(value),
      render: (state, record, index) => {
        return (
          record?.permissions && (
            <>
              {' '}
              {record.permissions.map((p) => (
                <Tag
                  key={`${index}-${p.permission.name}`}
                  color={'orange'}
                  style={{ marginBottom: '5px', marginRight: '5px' }}
                >
                  {p.permission.name}
                </Tag>
              ))}
            </>
          )
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (state, record, index) => {
        return (
          <Space>
            <BasicTooltipButton
              tooltip={'Edit User Group'}
              icon={<EditOutlined />}
              size={'small'}
              shape="circle"
              type="primary"
              onClick={() => openModal(record)}
            />
            <SafeDeleteIdMutationButton
              id={record.id}
              mutation={userGroupDelete}
              onDataChange={handleChange}
              tooltip={'Delete User Group'}
              icon={<DeleteOutlined />}
              size={'small'}
              shape="circle"
              type="secondary"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div id="user-admin-usergroups">
      <Space style={{ marginBottom: '10px', width: '100%' }}>
        <BasicTooltipButton type="primary" onClick={() => openModal(null)}>
          Add User Group
        </BasicTooltipButton>
      </Space>

      <UserGroupEditModal
        open={showModal}
        userGroup={modalData}
        onDataChange={handleChange}
        onClose={closeModal}
        onCancel={closeModal}
      />

      <AdvancedTable
        rowKey={'id'}
        dataSource={transformed_data}
        columns={columns}
        loading={loading}
        type="userGroupsTable"
      />
    </div>
  );
};

export default UserGroupsTable;
