import { gql } from '@apollo/client';

const secretCreate = gql`
  mutation ($key: String!, $value: String!) {
    insert_secret_one(object: { key: $key, value: $value }) {
      id
      key
      value
    }
  }
`;

export default secretCreate;
