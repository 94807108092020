import { Link } from 'react-router-dom';
import { Typography, Breadcrumb, Spin } from 'antd';
import { useQuery } from '@apollo/client';

import environmentAllById from 'common/graphql/querys/environmentAllById';
import PackagesTable from './PackagesTable';

const { Title } = Typography;

export const PackagesOverview = (props) => {
  const envId = props?.match?.params?.envId;

  const { loading, error, data } = useQuery(environmentAllById, {
    variables: { id: envId },
  });

  if (error) console.log(error);

  const environment = data?.environment_by_pk;
  const packages = environment?.packages;

  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: <Link to="/environments">Packages</Link>,
    },
    {
      title: 'Environment',
    },
  ];

  return (
    <div id="packages-overview" style={{ backgroundColor: '#F1F0EB' }}>
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      {loading && <Spin size="large" />}
      {environment && <Title level={5}>Environment: {environment.image}</Title>}
      {packages && <PackagesTable packages={packages} />}
    </div>
  );
};

export default PackagesOverview;
