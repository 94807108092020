import { useEffect, useState } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import { getTokenState } from './token';
import AuthLoadingPage from 'components/pages/AuthLoadingPage';

import { useMsal } from '@azure/msal-react';
import { accessTokenRequest } from 'services/auth/authConfig';
import { useAuthStore } from '../../store/useAuthStore';

const XApolloProvider = ({ children }) => {
  const { authData, updateAuthData } = useAuthStore((state) => ({
    authData: state.authData,
    updateAuthData: state.updateAuthData,
  }));
  const [cachedToken, setCachedToken] = useState(null);
  const { instance } = useMsal();

  useEffect(() => {
    if (
      authData?.accessToken &&
      cachedToken !== authData.accessToken
    ) {
      const { valid } = getTokenState(authData.accessToken);
      const accessToken = authData.accessToken;
      if (valid) {
        setCachedToken(accessToken);
        console.log({
          message: 'switch Token',
          cached: cachedToken,
          state: accessToken,
        });

        console.log('setting jwt in cookie');
        // TODO set the  HttpOnly to avoid XSS attacks
        // document.cookie = `jwt=${accessToken}; Secure; SameSite=None; Path=/`;
        fetch('/api/rest/set_token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken, // Bearer token format
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data);
            if (parseInt(data?.set_token.status) < 400) {
              console.log('JWT set in cookie successfully.');
            } else {
              console.error('Failed to set JWT in cookie:', data);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      } else console.log('token NOT valid!');
      // if (valid) wsLink.subscriptionClient.connect()
    }
  }, [authData]);

  // HTTP Link
  const httpLink = createHttpLink({
    uri: 'https://' + window._env_.REACT_APP_API_HOST + '/v1/graphql',
  });

  // Websocket Link
  const wsLink = new WebSocketLink({
    uri: 'wss://' + window._env_.REACT_APP_API_HOST + '/v1/graphql',
    options: {
      reconnect: true,
      reconnectionAttempts: 10,
      lazy: true,
      connectionParams: () => {
        // console.log({message: "WS Connection Init", token: cachedToken})
        return {
          headers: {
            Authorization: `Bearer ${cachedToken}`,
          },
        };
      },
    },
  });

  const authLink = setContext((request, { headers }) => {
    const account = instance.getAllAccounts()[0];
    instance
      .acquireTokenSilent({ ...accessTokenRequest, account })
      .then((result) => {
        const { valid } = getTokenState(result.accessToken);
        if (valid && result.accessToken !== cachedToken) {
          setCachedToken(result.accessToken);
          updateAuthData(result);
        }
      });

    return { headers: { ...headers, Authorization: `Bearer ${cachedToken}` } };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          console.log(
            `[GraphQL error]: Code: ${error.extensions.code} Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
          );
          switch (error?.extensions?.code) {
            case 'invalid-jwt': {
              console.log('invalid-jwt');
              const { valid } = getTokenState(cachedToken);
              if (valid) {
                wsLink.subscriptionClient.close();
                wsLink.subscriptionClient.connect();
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      }
      if (networkError) {
        switch (networkError?.extensions?.code) {
          case 'start-failed': {
            console.log('start-failed');
            const { valid } = getTokenState(cachedToken);
            if (valid) {
              wsLink.subscriptionClient.close();
              wsLink.subscriptionClient.connect();
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    },
  );

  // Link Splitter
  const link = from([
    authLink,
    errorLink,
    split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    ),
  ]);

  // Client
  const client = new ApolloClient({
    link: link,
    connectToDevTools: true,
    cache: new InMemoryCache(),
  });

  if (!cachedToken) return <AuthLoadingPage />;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default XApolloProvider;
