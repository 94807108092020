import { Card } from 'antd';

import RunsTable from 'components/shared/Run/RunsTable';

export const RunsPanel = (props) => {
  const onRunClick = (runId) => {
    if (props.onRunClick) props.onRunClick(runId);
  };

  return (
    <Card title={props.title} style={{ height: '100%' }}>
      <RunsTable
        runs={props.runs}
        loading={props.loading}
        onRunClick={onRunClick}
        type={props.type}
      />
    </Card>
  );
};

export default RunsPanel;
