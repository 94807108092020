import {message, Button, Popconfirm} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";
import {handleRunNow} from "../utils";
import {useContext, useEffect, useState} from "react";
import {FormHasValidationErrorContext} from "../../../pages/ModelsPage/FormHasValidationErrorProvider";
import {FormButtonRefContext} from "../../../pages/ModelsPage/FormButtonRefProvider";
import {useApolloClient} from "@apollo/client";
import {useAuthStore} from "../../../../store/useAuthStore";


export const RunNowButton = ({
                                 createdRunId,
                                 isRunNowLoading,
                                 runNowProps,

                             }) => {
    const {hasValidationError} = useContext(FormHasValidationErrorContext);
    const {formButtonRef} = useContext(FormButtonRefContext)
    const client = useApolloClient();
    const [showPopconfirm, setShowPopconfirm] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const {authData: {accessToken}} = useAuthStore((state) => ({
        authData: state.authData,
    }));

    // this is needed so that the Popconfirm only opens when the submit button is clicked
    // avoiding the Popconfirm to open on initial render
    useEffect(() => {
        if (isButtonClicked) {
            if (hasValidationError) {
                setShowPopconfirm(false);
            } else {
                setShowPopconfirm(true);
            }
        }
    }, [hasValidationError, isButtonClicked]);

    const confirmAction = () => {

        if (!hasValidationError) {
            handleRunNow({...runNowProps, client, accessToken});
        }

    };

    const handleButtonClick = () => {
        formButtonRef?.current?.click()
        if (!isButtonClicked) setIsButtonClicked(true);
    };

    if (createdRunId) {
        return <Button
            key="runNow"
            icon={<CaretRightOutlined/>}
            type={'primary'}
            onClick={() => runNowProps.handleRedirect(createdRunId)}
        >
            Go to run
        </Button>
    }

    return <Popconfirm
        open={showPopconfirm}
        onOpenChange={(visible) => setShowPopconfirm(visible)}
        placement="top"
        title={'Do you really want to start a direct run?'}
        onConfirm={confirmAction}
        okText="Yes"
        cancelText="No"
    >
        <Button
            icon={<CaretRightOutlined/>}
            type={'primary'}
            loading={isRunNowLoading}
            key="runNow"
            onClick={handleButtonClick}
        >
            Run Now
        </Button>
    </Popconfirm>
}