import {gql} from '@apollo/client';

export const actionExecutionDelete = gql`
    mutation ExecutionDelete($deployment_id: uuid!, $model_name: String!, $project_name: String!) {
        execution_delete(deployment_id: $deployment_id, model_name: $model_name, project_name: $project_name) {
          deployment_id
          success
          status
          details
        }
    }
`;