import { Breadcrumb, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import userPermissionsAll from 'common/graphql/querys/userPermissionsAll';
import BasicTooltipButton from 'components/shared/BasicTooltipButton';

const { Title, Text } = Typography;

export const UserAuthorizationPage = (props) => {
  const { loading, error, data, refetch } = useQuery(userPermissionsAll);

  const columns_project = [
    {
      title: 'User name',
      dataIndex: 'user_name',
      key: 'user_name',
      sort: true,
      search: true,
    },
    {
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
      sort: true,
      filter: true,
    },
    {
      title: 'Permission',
      dataIndex: 'permission_type',
      key: 'permission_type',
      sort: true,
      filter: true,
    },
  ];

  const columns_processes = [
    {
      title: 'User name',
      dataIndex: 'user_name',
      key: 'user_name',
      sort: true,
      search: true,
    },
    {
      title: 'Process',
      dataIndex: 'process_name',
      key: 'process_name',
      sort: true,
      filter: true,
    },
    {
      title: 'Permission',
      dataIndex: 'permission_type',
      key: 'permission_type',
      sort: true,
      filter: true,
    },
  ];

  const columns_general = [
    {
      title: 'User name',
      dataIndex: 'user_name',
      key: 'user_name',
      sort: true,
      search: true,
    },
    {
      title: 'Permission',
      dataIndex: 'permission_type',
      key: 'permission_type',
      sort: true,
      filter: true,
    },
  ];

  const find_permission = function (a, p) {
    const p_json = JSON.stringify(p);
    return a.findIndex((element) => {
      return JSON.stringify(element) === p_json;
    });
  };
  const find_higest_permission = function (v, i, a) {
    // only show first occurrence of permissions
    const v_index = find_permission(a, v);
    if (i !== v_index) return false;

    // if first and ADMIN level the show
    if (v.permission_type === 'ADMIN') return true;

    // if EXECUTE level, show only if ADMIN rights do not exist for same project/process
    const adminClone = { ...v };
    adminClone.permission_type = 'ADMIN';
    const a_index = find_permission(a, adminClone);
    if (v.permission_type === 'EXECUTE' && a_index === -1) return true;

    // if READ level, show only if neither ADMIN rights and EXECUTE exist for same project/process
    const executeClone = { ...v };
    executeClone.permission_type = 'EXECUTE';
    const e_index = find_permission(a, executeClone);
    if (v.permission_type === 'READ' && e_index === -1 && a_index === -1)
      return true;

    // return first entry for all other rights (CREATE_DELETE_PROCESS, USER_ADMIN etc.
    return !['ADMIN', 'EXECUTE', 'READ'].includes(v.permission_type);
  };

  let transformed_data_projects = [];
  let transformed_data_processes = [];
  let transformed_data_general = [];
  if (data && data.auth_permissions) {
    data.auth_permissions.forEach((item) => {
      if (
        item.project &&
        ['ADMIN', 'EXECUTE', 'READ'].includes(item.permission_type)
      ) {
        const entry = {};
        // entry['key'] = transformed_data_projects.length
        entry.user_name = item.user?.name;
        entry.project_name = item.project.name;
        entry.permission_type = item.permission_type;
        transformed_data_projects.push(entry);
      }

      if (
        item.process &&
        ['ADMIN', 'EXECUTE', 'READ'].includes(item.permission_type)
      ) {
        const entry = {};
        // entry['key'] = transformed_data_processes.length
        entry.user_name = item.user?.name;
        entry.process_name = item.process.name;
        entry.permission_type = item.permission_type;
        transformed_data_processes.push(entry);
      }

      if (!['ADMIN', 'EXECUTE', 'READ'].includes(item.permission_type)) {
        const entry = {};
        // entry['key'] = transformed_data_general.length
        entry.user_name = item.user?.name;
        entry.permission_type = item.permission_type;
        transformed_data_general.push(entry);
      }
    });
    transformed_data_projects = transformed_data_projects
      .filter(find_higest_permission)
      .map((entry, index) => {
        return { key: index, ...entry };
      });
    transformed_data_processes = transformed_data_processes
      .filter(find_higest_permission)
      .map((entry, index) => {
        return { key: index, ...entry };
      });
    transformed_data_general = [
      ...new Set(
        transformed_data_general
          .filter(find_higest_permission)
          .map((entry, index) => {
            return { key: index, ...entry };
          }),
      ),
    ];
  }

  const items = [
    {
      title: <Link to="/">Home</Link>,
    },
    {
      title: 'Authorization',
    },
  ];

  return (
    <div id="user-admin-page">
      <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

      <Space style={{ marginBottom: '10px', width: '100%' }}>
        <BasicTooltipButton type="primary" onClick={() => refetch()}>
          Refresh
        </BasicTooltipButton>
      </Space>
      <Title level={4}>Projects Access Summary</Title>
      <AdvancedTable
        dataSource={transformed_data_projects}
        columns={columns_project}
        loading={loading}
        type="userAuthorizationPage_1"
      />
      <Title level={4}>Processes Access Summary</Title>
      <AdvancedTable
        dataSource={transformed_data_processes}
        columns={columns_processes}
        loading={loading}
        type="userAuthorizationPage_2"
      />
      <Title level={4}>Other Privileges</Title>
      <AdvancedTable
        dataSource={transformed_data_general}
        columns={columns_general}
        loading={loading}
        type="userAuthorizationPage_3"
      />
    </div>
  );
};

export default UserAuthorizationPage;
