import Plot from 'react-plotly.js';

export const DataInterfaceTimeTakenPlot = ({ timeTakenPlotData }) => {
  return (
    <Plot
      style={{ width: '100%', height: '250px' }}
      data={[
        {
          x: timeTakenPlotData.loadTimestamps,
          y: timeTakenPlotData.loadTimesTaken,
          type: 'scatter',
          mode: 'markers',
          marker: { color: '#00493b' },
          name: 'Load',
          color: timeTakenPlotData.name,
          text: timeTakenPlotData.loadText,
        },
        {
          type: 'scatter',
          mode: 'markers',
          x: timeTakenPlotData.storeTimestamps,
          y: timeTakenPlotData.storeTimesTaken,
          marker: { color: '#1bb470' },
          name: 'Store',
          text: timeTakenPlotData.storeText,
        },
      ]}
      layout={{
        yaxis: {
          title: 'Time Taken (sec)',
        },
        margin: {
          l: 40,
          r: 30,
          b: 30,
          t: 10,
          pad: 0,
        },
        legend: {
          // Add a legend property to the layout object
          x: 0.5, // Set the x position of the legend
          y: 1.2, // Set the y position of the legend
          xanchor: 'center', // Set the x-anchor of the legend
          yanchor: 'top', // Set the y-anchor of the legend
          orientation: 'h', // Set the orientation (vertical or horizontal)
        },
      }}
    />
  );
};