import { gql } from '@apollo/client';

const cdmReferenceEdit = gql`
  mutation cdmReferenceCreate(
    $identifier: String!
    $reference: cdm_reference_set_input!
  ) {
    update_cdm_reference_by_pk(
      pk_columns: { identifier: $identifier }
      _set: $reference
    ) {
      identifier
    }
  }
`;

export default cdmReferenceEdit;
