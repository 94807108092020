import { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export const TextViewButton = (props) => {
  const { text, title, subtitle, ...btnProps } = props;
  const [showModal, setShowModal] = useState(false);

  return (<>
    <Button
      icon={<EyeOutlined />}
      shape="circle"
      type={'primary'}
      {...btnProps}
      onClick={() => setShowModal(true)}
    />
    <Modal
      title={title}
      open={showModal}
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
    >
      {subtitle && { subtitle }}
      <TextArea rows={12} value={text} onChange={() => null} />
    </Modal>
  </>);
};

export default TextViewButton;
