import {Breadcrumb, Row} from "antd";
import {RunsHeader} from "./RunsHeader";

export const RunsOverviewHeader = ({items, updateConfig, runsDateStrings, flowRuns}) => {
    return (
        <Row>
            <Breadcrumb style={{ marginBottom: '20px' }} items={items} />

            <RunsHeader
                {...{
                    updateConfig,
                    runsDateStrings,
                    runs: flowRuns,
                }}
            />
        </Row>
    )
}
