import { gql } from '@apollo/client';

const userGroupDelete = gql`
  mutation ($id: Int!) {
    delete_auth_user_groups_by_pk(id: $id) {
      id
    }
  }
`;

export default userGroupDelete;
