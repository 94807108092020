import { gql } from '@apollo/client';

export const sideNavigationQueries = gql`
  query ($user_id: uuid!) {
    project(
      where: {
        permissions: {
          permission_type: { _in: ["ADMIN", "ADMIN_ALL_PROJECTS"] }
        }
      }
    ) {
      id
      name
    }
    dashboard(where: { link: { _is_null: false } }, order_by: [{project: {name: asc}}, {name: asc}]) {
      link
      name
      gitlink
      description
      project {
        name
        id
      }
    }
    auth_permissions(
      where: {
        user_id: { _eq: $user_id }
        permission_type: { _in: ["USER_ADMIN", "ENV_ADMIN"] }
      }
    ) {
      permission_type
    }
  }
`;

export default sideNavigationQueries;
