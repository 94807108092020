exports.theme = {
  // Primary colors
  'primary-color': '#1BB470',

  // Green
  'primary-color-green': '@primary-color',
  'primary-color-green-soft': '#DDF4EA',
  'primary-color-green-light': '#49C38D',
  'primary-color-green-dark': '#16905A',

  // Petrol
  'primary-color-petrol': '#00493B',
  'primary-color-petrol-soft': '#8BCBB7',
  'primary-color-petrol-light': '#336D62',
  'primary-color-petrol-dark': '#003A2F',

  // Beige
  'primary-color-beige': '#D5D2C3',
  'primary-color-beige-soft': '#F1F0EB',
  'primary-color-beige-light': '#DDDBCF',
  'primary-color-beige-dark': '#AAA89C',

  // Orange
  'primary-color-orange': '#EF771E',
  'primary-color-orange-soft': '#FDDEB9',
  'primary-color-orange-light': '#F2924B',
  'primary-color-orange-dark': '#BF5F18',

  // Yellow
  'primary-color-yellow': '#FCB928',
  'primary-color-yellow-soft': '#FFF6CF',
  'primary-color-yellow-light': '#FFD666',
  'primary-color-yellow-dark': '#F2A611',

  // Grayscale
  'grayscale-color-gray': '#D9D9D9',
  'grayscale-color-soft': '#FAFAFA',

  // Chart colors
  'chart-color-green': '@primary-color-green',
  'chart-color-petrol': '@primary-color-petrol',
  'chart-color-beige': '@primary-color-beige',
  'chart-color-orange': '@primary-color-orange',
  'chart-color-yellow': '@primary-color-yellow',
  'chart-color-lime': '#A0D911',
  'chart-color-turquoise': '#36CFC9',
  'chart-color-blue': '#2F54EB',
  'chart-color-purple': '#9254DE',
  'chart-color-pink': '#EB2F96',

  // Colors
  'info-color': '@primary-color',
  'success-color': '@primary-color',
  'processing-color': '@primary-color-petrol',
  'error-color': '@primary-color-orange',
  'highlight-color': '@error-color',
  'warning-color': '@primary-color-yellow',
  'normal-color': '#000',
  white: '#fff',
  black: '#000',

  'font-family': 'Lato, sans-serif',
  'text-color-secondary': '@primary-color-beige-dark',

  // // Disabled states
  'disabled-color': '@grayscale-color-gray',
  'disabled-bg': '@grayscale-color-soft',


  // Layout
  'layout-body-background': '@primary-color-beige-soft',
  'layout-header-background': '@white',
  'layout-trigger-background': '@primary-color-petrol',

  // // Menu
  // // ---
  'menu-inline-submenu-bg': '@white',

  // // dark theme
  'menu-dark-color': '@white',
  'menu-dark-bg': '@primary-color-petrol',
  'menu-dark-arrow-color': '@white',
  'menu-dark-highlight-color': '@white',

  'menu-dark-inline-submenu-bg': '@primary-color-petrol-light',
  'menu-dark-item-active-bg': '@primary-color-petrol-soft',
  'menu-dark-selected-item-icon-color': '@primary-color-petrol',
  'menu-dark-selected-item-text-color': '@white',

  // // Badge
  // // ---
  'badge-dot-size': '20px',
  'badge-color': '@error-color',


  // // Pagination
  // // ---
  'pagination-font-weight-active': '900',
  'pagination-item-bg-active': '@white',


  // // Collapse
  // // ---
  'collapse-header-padding': '6px 10px',
  'collapse-header-bg': '@grayscale-color-soft',
  'collapse-content-padding': '10px',

  // // Alert
  // // ---
  'alert-success-border-color': '@primary-color',
  'alert-success-bg-color': '@primary-color-green-soft',
  'alert-success-icon-color': '@primary-color',
  'alert-info-border-color': '@primary-color-beige',
  'alert-info-bg-color': '@primary-color-beige-soft',
  'alert-info-icon-color': '@primary-color-petrol-soft',
  'alert-warning-border-color': '@primary-color-yellow-light',
  'alert-warning-bg-color': '@primary-color-yellow-soft',
  'alert-warning-icon-color': '@primary-color-yellow',
  'alert-error-border-color': '@primary-color-orange-light',
  'alert-error-bg-color': '@primary-color-orange-soft',
  'alert-error-icon-color': '@primary-color-orange',


  // // Typography
  // // ---
  'typography-title-font-weight': '900',

};
