import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { message } from 'antd';

import BasicTooltipButton from 'components/shared/BasicTooltipButton';
import actionTriggerPipeline from 'common/graphql/mutations/actionTriggerPipeline';

export const TriggerPipelineButton = (props) => {
  const { pipelineRef } = props;
  const [loading, setLoading] = useState();
  const client = useApolloClient();

  const triggerPipeline = () => {
    setLoading(true);
    client
      .mutate({
        mutation: actionTriggerPipeline,
        variables: { ref: pipelineRef },
      })
      .then((result) => {
        message.success({
          content: 'Succesfully triggered Pipeline Parameter.',
        });
        setLoading(false);
      })
      .catch((error) => {
        message.error({
          content: 'Error while trying to update Parameter: ' + error,
        });
        setLoading(false);
      });
  };

  return (
    <BasicTooltipButton
      type="primary"
      loading={loading}
      onClick={triggerPipeline}
    >
      Start Pipeline
    </BasicTooltipButton>
  );
};

export default TriggerPipelineButton;
